.bloc-cartographie {
    position: relative;
    z-index: 10;

    .wrapper {
        height: 520px;
    }

    .maps {
        @extend %center;
        @include min-tablet_landscape {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                width: 440px;
                z-index: 1000;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+58,0.65+100 */
                background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, 0.50) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, 0.50) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, 0.50) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=1); /* IE6-9 */
            }
        }
    }

    // Listing Map - Page de contenu
    // ==========================================================================
    .listing-map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 1000;
        padding: 20px;
        width: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        @include tablet_portrait{
            display: flex;
            top: auto;
            left: auto;
            right: auto;
            padding: 20px 0;
            margin-left: -40px;
            margin-right: -40px;
            width: calc(100% + 80px);
        }
        @include mobile{
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
        > div {
            position: relative;
            display: block;
            white-space: normal;
            width: 400px;
            @include tablet_portrait{
                display: block;
                white-space: nowrap;
                width: 100%;
                overflow: scroll;
                overflow-y: hidden;
                scroll-snap-type: x mandatory;
                -webkit-overflow-scrolling: touch;
                padding-left: 60px;
                padding-right: 60px;
                scroll-padding: 60px;
            }
            @include mobile{
                padding-left: 40px;
                padding-right: 40px;
                scroll-padding: 40px;
            }
        }

        .card{
            @include cardHorinzontale();
            margin: 0 0 10px 0;
            @include tablet_portrait{
                margin: 0 20px 0 0;
                display: inline-flex;
                width: 320px;
                scroll-snap-align: start;
            }
            @include mobile{
                width: 280px;
            }
            @include mobile_small{
                width: 240px;
            }
            &:last-child{
                margin: 0;
            }
        }
    }

}

.bloc-cartographie-itineraire{
    .maps{
        display: block;
        width: 100%;
        height: 640px;

        .leaflet-popup-content{
            figure{
                &:before{
                    content:none;
                }
                img{
                    width: 100%;
                    height: auto;
                }
                figcaption{
                    display: none;
                }
            }
            .title-card{
                font-weight: 400;
                color: $c_txt;
                text-transform: uppercase;
                margin-bottom: 5px;
                font-size: 16px;
                line-height: 1;
            }
        }
    }
    .th-slider-wrapper{
        z-index: 1000;
        position: relative;
        margin-top: -160px;

        .th-slider{
            padding-top: 20px;
            .card{
                width: 460px;
                background-color: white;
                transform:translate3d(0,0,0);
                @include mobile{
                    width: 300px;
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                }

                &:hover{
                    body:not(.no-hove) & {
                        transform:translate3d(0,-20px,0);
                        &:before{
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}