.page-rate {
    position: relative;
    background: $c_grey;
    padding: 30px;
    text-align: center;
    margin-top: $content-spacing/2;
    @include clearfix();

    &.voted {
        .rate {
            .message-ok {
                display: block;
            }
        }
    }

    .message-ok {
        display: none;
    }

    > .txt {
        float: left;
        width: 50%;
        text-align: left;
        @include mobile {
            width: 100%;
            float: none;
            text-align: center;
        }

        > * {
            max-width: 250px;
        }

        span {
            @include ft($f_hx, 20px, 700, $c_main, 24px);
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }
        }

        p {
            font-size: calculateRem(16px);
            line-height: calculateRem(20px);
        }
    }

    .rate {
        float: left;
        width: 50%;
        text-align: right;
        @include mobile {
            float: none;
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }
    }

    form {
        font-size: 0;
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 2;
        opacity: 1;
        transition: opacity 0.2s ease;

        &:hover, &:focus {
            cursor: pointer;

            label {
                span {
                    &:before {
                        color: $c_main !important;
                        @include addTransition();

                        body.site_noel & {
                            color: $c_noel1 !important;
                        }
                        body.site_vins & {
                            color: $c_vins2 !important;
                        }
                        body.site_velo & {
                            color: $c_velo2 !important;
                        }
                    }
                }
            }
        }

        label {
            padding: 0 5px;
            margin: 0;
            display: inline-block;
            @include addTransition();
            &:first-child{
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }

            span {
                font-size: 24px;
                @include addTransition();

                &.icon-star {
                    &:before {
                        color: $c_main;
                    }
                }
            }

            &:hover, &:focus {
                cursor: pointer;

                span.icon-star {
                    &:before {
                        color: $c_main !important;
                    }
                }

                & ~ label {
                    span {
                        &:before {
                            color: #BDBDBD !important;
                            body.site_noel & {
                                color: #BDBDBD !important;
                            }
                            body.site_vins & {
                                color: #BDBDBD !important;
                            }
                            body.site_velo & {
                                color: #BDBDBD !important;
                            }
                        }
                    }

                }
            }
        }

        input {
            display: none;

            &:checked + label {
                span {
                    &:before {
                        color: $c_main !important;

                        body.site_noel & {
                            color: $c_noel1 !important;
                        }
                        body.site_vins & {
                            color: $c_vins2 !important;
                        }
                        body.site_velo & {
                            color: $c_velo2 !important;
                        }
                    }
                }

                & ~ label {
                    span {
                        &:before {
                            color: rgba($c_txt, 0.3);
                        }
                    }
                }
            }
        }
    }

    .global-rate{
        justify-content: flex-end;
        @include mobile{
            justify-content: center;
        }
        span{
            &.disabled{
                &:before{
                    color: #BDBDBD;
                }
            }
        }
        .txt{
            color: $c_txt !important;
        }
    }
}


// Notes avec coeur
// ==========================================================================
.global-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    > * {
        color: $c_main;
        font-size: 12px;
        line-height: 20px;
        padding: 0 2px;

        body.site_noel & {
            color:$c_noel1;
            &.txt{
                color: $c_white;
            }
        }
        body.site_vins & {
            color:$c_vins1;
            &.txt{
                color: $c_white;
            }
        }
        body.site_velo & {
            color:$c_velo1;
            &.txt{
                color: $c_white;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.disabled {
            &:before {
                color: rgba($c_white, 0.5);
                display: block;
            }
        }

        &.txt {
            @include ft($f_hx, 14px, 400, $c_white, 20px);
            margin-left: 4px;
            margin-top: -4px;
        }
    }
}