// Styles communes aux images + styles pour la classe image qui utilisé comme container pour les images afin de bien positionner les crédits en position absolute
// ==========================================================================

// Images
// ==========================================================================
.no-photo {
    height: 100%;
    background: #AAA;
}

figure {
    background: $c_dark;
}

.fit-cover {
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';

    img {
        object-fit: cover;
        font-family: 'object-fit: cover';
        width: 100%;
        height: 100%;
    }
}


figcaption, .figcaption{
    @include ft($f_txt, 13px, 400, $c_filet, 18px);
    position: relative;
    display: block;
    font-style: italic;
    margin-top: 8px;
}

.credits {
    @include ft($f_txt, 12px, 300, $c_white, 18px);
    box-sizing: border-box;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 20000;
    width: auto!important;
    height: 30px!important;
    border-radius: 15px;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 30px 5px 15px;
    font-size: 0;
    @include addTransition();
    &:before {
        content: "\e91f";
        @include font();
        font-size: 16px;
        position: absolute;
        right: 8px;
        top: 50%;
        margin-top: -8px;
    }
    &:after {
        content: '';
        @extend %center;
        background: $c_dark;
        z-index: -1;
        width: 0;
        transform-origin: left;
        @include addTransition();
    }
    &:hover {
        cursor: default;
        font-size: calculateRem(12px);
        &:after {
            width: 100%;
        }
    }
}


img {
    &.lazy {
        opacity: 0 !important;
        transition: opacity 0.3s ease;

        &.loaded {
            opacity: 1 !important;
        }
    }
}


// Opacity
// ==========================================================================
.o10 img {
    opacity: 0.1;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.1 !important;
        }
    }
}

.o20 img {
    opacity: 0.2;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.2 !important;
        }
    }
}

.o25 img {
    opacity: 0.25;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.25 !important;
        }
    }
}

.o30 img {
    opacity: 0.3;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.3 !important;
        }
    }
}

.o40 img {
    opacity: 0.4;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.4 !important;
        }
    }
}

.o50 img {
    opacity: 0.5;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.5 !important;
        }
    }
}

.o60 img {
    opacity: 0.6;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.6 !important;
        }
    }
}

.o70 img {
    opacity: 0.7;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.7 !important;
        }
    }
}

.o75 img {
    opacity: 0.75;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.75 !important;
        }
    }
}

.o80 img {
    opacity: 0.8;

    &.lazy {
        opacity: 0 !important;

        &.loaded, &.loading {
            opacity: 0.8 !important;
        }
    }
}

.o90 img {
    opacity: 0.9;

    &.lazy {
        opacity: 0 !important;

        &.loaded {
            opacity: 0.9 !important;
        }
    }
}

.o10, .o20, .o30, .o40, .o50, .o60, .o70, .o80, .o90 {
    img {
        &.lazy {
            visibility: hidden !important;
            transition: none;

            &.loaded, &.loading {
                visibility: visible !important;
            }
        }
    }
}


// Ratios
// ==========================================================================
.ratio-16-9 {
    @include aspect-ratio(16, 9);
}

.ratio-16-10 {
    @include aspect-ratio(16, 10);
}

.ratio-4-3 {
    @include aspect-ratio(4, 3);
}

.ratio-16-9 {
    > img {
        @extend %center;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}