.bloc-pagination {
	position: relative;
	z-index: 0;
	padding: 30px 0 0;
    @include mobile{
        padding: 20px 0 0;
    }
	.pagination {
		text-align: center;
		@include clearfix();

		a, strong, span {
			@include ft($f_hx, 14px, 500, $c_txt, 44px);
            text-transform: uppercase;
            letter-spacing: 1px;
			position: relative;
		}
		span{
			display: flex;
		}

		a.prev {
			position: relative;
			float: left;
			@include addTransition();
			&:hover, &:focus {
				body:not(.no-hover) & {
                    color: $c_main;
                    font-weight: 700;
					&:before{
						margin-right: 12px;
					}
				}
				body:not(.no-hover).site_noel & {
					color: $c_noel1;
				}
				body:not(.no-hover).site_vins & {
					color: $c_vins1;
				}
				body:not(.no-hover).site_velo & {
					color: $c_velo1;
				}
			}
			&:before {
                content: "\e95c";
				@include font();
                color: $c_main;
				font-size: 20px;
				position: relative;
				line-height: 44px;
				margin-right: 10px;
				float: left;
                transform: rotate(180deg);
				@include addTransition();

				body.site_noel & {
					color: $c_noel1;
				}
				body.site_vins & {
					color: $c_vins1;
				}
				body.site_velo & {
					color: $c_velo1;
				}
			}

			&.disabled {
				opacity: .45;
				pointer-events: none;
				color: black !important;

				&:before {
					color: black !important;
					margin-right: 10px !important;
				}
			}
		}

		.btn-next{
            @extend .btn;
			width: 100%;
            max-width: 386px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transform: translate3d(-50%,0,0);
            right: 0;
            @include tablet_portrait{
                position: relative;
                max-width: 100%;
                transform: none;
                left: 0;
                margin-bottom: 10px;
            }

			&.disabled {
				border-color: black !important;
				opacity: .45;
				pointer-events: none;
				background: white !important;
				color: black !important;
			}
		}

		.pagination-number {
			position: relative;
			z-index: 10;
			float: right;
            display: flex;
            align-items: center;
			a {
				margin: 0 5px;
				text-align: center;
				display: inline-block;
				vertical-align: middle;
				@include addTransition();
				&:hover, &:focus {
					body:not(.no-hover) & {
                        color: $c_main;
						font-weight: 700;
					}
					body:not(.no-hover).site_noel & {
						color: $c_noel1;
					}
					body:not(.no-hover).site_vins & {
						color: $c_vins1;
					}
					body:not(.no-hover).site_velo & {
						color: $c_velo1;
					}
				}

				&.active {
					color: $c_main;
					font-weight: 700;
					body.site_noel & {
						color: $c_noel1;
					}
					body.site_vins & {
						color: $c_vins1;
					}
					body.site_velo & {
						color: $c_velo1;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}