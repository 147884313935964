.info-live{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background-color: $c_sub;
	max-height: 60px;
	min-height: 60px;
	color: white;
	display: flex;
	z-index: 100;
	padding: 0 40px;
	@include addTransition();
	transition-property: max-height;
	@include mobile{
		display: block;
		overflow: hidden;
		padding: 0px 20px;
	}

	body.site_noel & {
		background-color: $c_noel2;
	}
	body.site_vins & {
		background-color: $c_vins2;
	}
	body.site_velo & {
		background-color: $c_velo2;
	}

	@include min-mobile{
		~#menu-toggle:not(:checked) ~#header{
			body:not(.is-scrolled) & {
				top: 100px;
				&.menu-page-lei, &.menu-no-header{
					top: 60px;
				}
				.menu-right{
					top: 0;
				}
				.bloc-form-algolia{
					top: 10px;
				}
				&:before{
					top: 60px;
				}
			}
		}
	}
	~#menu-toggle:not(:checked) ~.logo-alsace, ~#menu-toggle:not(:checked) ~.logo-old{
		body:not(.is-scrolled) & {
			top: 100px;
			&.stick-top, &.menu-no-header{
				top: 60px;
			}
			&.logo-70{
				top: 80px;
			}
			@include mobile{
				top: 80px;
				&.logo-70{
					top: 70px;
				}
			}
		}
	}

	.titre{
		color: white;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		margin: 20px 0 20px 0;
		padding: 0;
		text-transform: uppercase;
		flex-shrink: 0;
		@include ellipsis(1, 20px);
		@include mobile{
			line-height: 20px;
			padding: 0;
			margin: 10px 0 0 0;
			text-align: center;
		}
	}
	p{
		position: relative;
		color: white;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		margin: 20px auto 20px 30px;
		padding: 0 15px;
		cursor: pointer;
		@include ellipsis(1, 20px);
		@include addTransition();
		&:first-child{
			margin-left: 0;
		}
		@include tablet_portrait{
			margin: 20px auto 20px 15px;
		}
		@include mobile{
			padding: 0;
			margin: 0 0 10px 0;
			text-align: center;
		}

		span{
			display: inline-block;
		}
	}
	.btn{
		border: white 1px solid;
		margin:auto 0 auto auto;
		flex-shrink: 0;
		color: white;
		
		&:hover,&:focus{
			body:not(.no-hover) & {
				background-color: white;
				color: $c_sub;
				border-color: white;
			}
			body:not(.no-hover).site_noel & {
				color: $c_noel2;
			}
			body:not(.no-hover).site_vins & {
				color: $c_vins2;
			}
			body:not(.no-hover).site_velo & {
				color: $c_velo2;
			}
		}

		@include mobile{
			margin: 0 0 10px 0;
			width: 100%;
		}
	}


	&.to-expand{
		p{
			&:after{
				content: '';
				position: absolute;
				top: -20px;
				bottom: -20px;
				right: 15px;
				width: 60px;
				z-index: 5;
				@include addTransition();

				//Background
				background: rgb(227,25,54);
				background: linear-gradient(90deg, rgba(227,25,54,0) 0%, rgba(227,25,54,1) 100%);
				body.site_noel & {
					background: rgb(196,154,74);
					background: linear-gradient(90deg, rgba(196,154,74,0) 0%, rgba(196,154,74,1) 100%);
				}
				body.site_vins & {
					background: rgb(210,154,0);
					background: linear-gradient(90deg, rgba(210,154,0,0) 0%, rgba(210,154,0,1) 100%);
				}
				body.site_velo & {
					background: rgb(221,125,115);
					background: linear-gradient(90deg, rgba(221,125,115,0) 0%, rgba(221,125,115,1) 100%);
				}

				@include mobile{
					top: 0;
					bottom: 0;
					right: 0;
				}
			}
			&:before{
				content: '\e95c';
				font-family:'icomoon';
				position: absolute;
				top: -20px;
				bottom: -20px;
				right: 15px;
				width: 40px;
				transform-origin: center center;
				transform:translate3d(0,0,0) rotate(90deg);
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				font-weight: 700;
				font-size: 20px;
				z-index: 10;
				@include addTransition();

				@include mobile{
					top: 0;
					bottom: 0;
					right: 0;
				}
			}
		}

		&.expanded{
			z-index:501000;
			body.no-scroll & {
				z-index:9500;
			}
			p{
				&:after,&:before{
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}

	~#pwa-wrapper{
		margin-top: 100px;
		@include mobile{
			margin-top: 80px;
		}

		main header.header-lei .caption-date-event{
			top: 85px;
			@include mobile{
				top: 65px;
			}
		}
	}
	~ #header.menu-page-lei + #pwa-wrapper{
		margin-top: 60px;
	}
}