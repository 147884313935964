.custom-popup-thuria {

    .leaflet-popup-content {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        padding: 0;

        &:after {
            content: "\e955";
            @include font();
            float: left;
            position: absolute;
            bottom: -25px;
            left: calc(50% + 1px);
            z-index: 10;
            color: #fff;
            font-size: 64px;
            transform: rotate(180deg) translateX(50%);
            transform: rotate(180deg) translate3d(50%, 0, 0);
        }

        .card {
            z-index: 20;
            &:hover, &:focus {
                body:not(.no-hover) & {
                    transform: translate3d(0, 0, 0) scale(1);
                    .title-card{
                        color: $c_main;
                    }
                }
                body:not(.no-hover).site_noel & {
                    .title-card{
                        color: $c_noel1;
                    }
                }
                body:not(.no-hover).site_vins & {
                    .title-card{
                        color: $c_vins2;
                    }
                }
                body:not(.no-hover).site_velo & {
                    .title-card{
                        color: $c_velo2;
                    }
                }
            }

            .rare, .title-card{
                width: 100%;
            }

            .price{
                padding: 15px;
                span{
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                }
            }
        }

        //
        .entete{
            background-color: white;
            .titre{
                padding: 10px 20px;
                .title-card{
                    width: 100%;
                }
            }
        }
        .bot{
            background-color: white;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 15px;

            body.site_velo & {
                padding-top: 12px;
                padding-bottom: 12px;
            }

            .iti-data{
                position: relative;
                width: 50%;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 20;
                &:after{
                    content: none;
                    display: block;
                    width: 1px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -10px;
                    background-color: $c_sub;
                    body.site_noel & {
                        background-color: $c_noel2;
                    }
                    body.site_vin & {
                        background-color: $c_vins2;
                    }
                    body.site_velo & {
                        background-color: $c_velo2;
                    }
                }

                body.site_velo & {
                    &:nth-child(n+1):not(:last-child):after {
                        content: '';
                    }
                }

                &:nth-child(2n+1):not(:last-child){
                    &:after{
                        content:'';
                    }
                }

                span[class^="icon-"], .difficulte{
                    margin-right: 10px;
                    color: $c_main;
                    font-size: 16px;
                    height: 16px;
                    width: 16px;
                    body.site_noel & {
                        color: $c_noel1;
                    }
                    body.site_vin & {
                        color: $c_vins1;
                    }
                    body.site_velo & {
                        color: $c_velo1;
                    }
                }
                .difficulte{
                    background-color: $c_main;
                    border-radius:50px;
                    body.site_noel & {
                        background-color: $c_noel1;
                    }
                    body.site_vin & {
                        background-color: $c_vins1;
                    }
                    body.site_velo & {
                        background-color: $c_velo1;
                    }
                    &:before{
                        content:none;
                    }
                }
            }

            body.site_velo & {
                flex-wrap: nowrap;
            }
        }
    }

    figure, .no-photo {
        @include aspect-ratio(3, 2);
    }

    figure {
        &:after {
            content: '';
            @extend %center;
            background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(135deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
            z-index: 0;
            height: 100%;
            opacity: 1;
        }
    }

    .date-event {
        .surtitre {
            font-size: calculateRem(30px);
            line-height: calculateRem(30px);
        }

        time {
            span {
                font-size: calculateRem(12px);
                line-height: calculateRem(12px);
            }
        }

        .other-dates {
            display: none;
        }
    }

    .bottom, .caption-txt {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: left;
        z-index: 10000;
        border-bottom: none;
        background: $c_white;
        padding: 20px;

        .title-card{
            color: $c_dark;
        }

        .city {
            @extend .location;
        }
    }

    .bottom{
        > div{
            border: none;
            span.city{
                float: none;
                font-weight: 400;
                width: 100%;
                padding: 0;
            }
        }

    }

    .faitguest {
        display: none;
    }
}