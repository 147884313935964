.selectric-scroll {
	ul {
		padding-left: 0;
	}
}

.selectric-items{
	ul{
		li{
			padding: 8px 5px;
			margin-bottom: 0;
		}
	}
}