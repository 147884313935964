.top-destination {
    position: relative;
    z-index: 1000;
    background: $c_grey;
    padding: 40px;
    margin: 0 40px $content-spacing;
    @include tablet_portrait {
        padding: 20px 40px;
    }
    @include mobile {
        padding: 10px 20px;
        margin: 0 20px $content-spacing/2;
    }

    > figure {
        background: none;
        position: absolute;
        top: 0;
        margin-top: -160px;
        right: calc((100% - #{$grid-small}) / 2);
        transform: translateX(50%);
        transform: translate3d(50%, 0, 0);
        @include desktop {
            transform: none;
            right: 40px;
        }
        @include tablet_landscape {
            right: -20px;
        }
        @include tablet_portrait {
            margin-top: -120px;
            right: 0;
        }
        @include mobile {
            margin-top: -80px;
            right: -20px;
            max-width: 180px;
        }
        @include mobile_small {
            max-width: 120px;
        }

        img {
            height: auto;
        }

        figcaption {
            display: none;
        }
    }

    ul {
        position: relative;
        display: flex;
        align-items: flex-start;
        @include desktop {
            margin: 0;
        }
        @include tablet_portrait {
            display: block;
        }

        li {
            @include ft($f_txt, 13px, '', $c_txt, 15px);
            box-sizing: border-box;
            max-width: 250px;
            margin-bottom: 0;
            display: block;
            font-size: 14px;
            line-height: 17px;
            padding-left: 55px;
            padding-right: 60px;
            @include desktop {
                max-width: 220px;
                padding-right: 40px;
            }
            @include tablet_portrait {
                width: 100%;
                max-width: 300px;
                padding: 20px 0 20px 55px;
            }
            @include mobile {
                max-width: 180px;
                padding: 10px 0 10px 40px;
            }

            &:last-child {
                padding-right: 0;
            }

            &:before {
                content: '';
                @include font();
                font-size: 24px;
                width: 45px;
                height: 45px;
                line-height: 45px;
                background: $c_main;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                color: $c_white;
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    background: $c_noel2;
                }
                .site_vins & {
                    background: $c_vins2;
                }
                .site_velo & {
                    background: $c_velo2;
                }

                @include tablet_portrait {
                    top: 20px;
                }
                @include mobile {
                    top: 10px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 18px;
                }
            }

            &.aeroport {
                &:before {
                    content: "\e92d";
                    text-align: center;
                }
            }

            &.autoroute {
                &:before {
                    content: "\e92e";
                    text-align: center;
                }
            }

            &.train {
                &:before {
                    content: "\e92f";
                    font-size: 35px;
                    @include mobile {
                        font-size: 20px;
                    }
                }
            }

            strong {
                @include ft($f_hx, 14px, 700, $c_txt, 20px);
                text-transform: uppercase;
                display: block;
            }
        }
    }
}


.bloc-red {
    position: relative;
    z-index: 10;
    background: $c_grey;
    padding: 60px 40px 40px 40px;
    margin-top: $content-spacing;
    margin-bottom: $content-spacing;
    @include mobile{
        padding: 40px 20px 20px 20px;
        margin-top: $content-spacing/2;
        margin-bottom: $content-spacing/2;
    }

    .intro {
        h2.txt-styled {
            font-size: calculateRem(90px);
            line-height: calculateRem(60px);
            margin-bottom: 30px;
            @include mobile{
                font-size: calculateRem(70px);
                line-height: calculateRem(50px);
            }
        }
        p {
            max-width: 650px;
        }
    }

    .wrapper {
        position: relative;
        padding: 20px 0;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        @include mobile {
            display: block;
            margin-top: 0;
            padding-bottom: 0;
        }
        > div {
            position: relative;
            flex: 1;
            text-align: left;
            padding: 0 20px;
            @include mobile{
                padding: 20px 0;
                text-align: center;
            }
            &:first-child{
                &:before{
                    content: none;
                }
            }
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-left: 1px dashed rgba($c_dark,0.3);
                @include mobile{
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: auto;
                    border-left: none;
                    border-top: 1px dashed rgba($c_dark,0.3);
                }
            }
            h3 {
                @include ft($f_hx, 20px, 400, $c_txt, 25px);
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            p {
                @include ft('', 16px, '', '', 20px);
                margin-bottom: 0;
                @include mobile {
                    font-size: calculateRem(14px);
                    line-height: calculateRem(18px);
                }
            }
        }
    }
}