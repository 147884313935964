.wrapper-content-recette {
    position: relative;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $content-spacing/2;

    .left-content {
        order: 1;
        width: calc(100% - 375px);
        padding-right: 25px;
        @include tablet_landscape{
            width: calc(100% - 340px);
        }
        @include tablet_portrait{
            width: 100%;
            order: 2;
            padding-right: 0;
        }
    }

    .right-content {
        order: 2;
        width: 375px;
        margin-top: -86px;
        @include tablet_landscape{
            width: 340px;
        }
        @include tablet_portrait{
            width: 100%;
            margin-top: 0;
            margin-bottom: 40px;
            order: 1;
        }
        @include mobile{
            margin-bottom: 20px;
        }
    }
}


// Zone Droite
.top-recette {
    position: sticky;
    position: -webkit-sticky;
    top: $header-height+25px;
    @include tablet_portrait{
        position: relative;
        top: 0;
    }

    .infos {
        text-align: center;
        padding: 20px 40px;
        z-index: 10;
        background: $c_grey;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;

        .datas-recette {
            position: relative;
            display: block;
            width: 100%;
            padding: 0 10px;
            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1px;
                height: 30px;
                background: rgba($c_dark, 0.2);
                top: 50%;
                margin-top: -15px;
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 10px;
            display: block;
            color: $c_main;

            body.site_noel & {
                color: $c_noel1;
            }
            body.site_vins & {
                color: $c_vins1;
            }
            body.site_velo & {
                color: $c_velo1;
            }
        }

        .title-data {
            @include ft($f_hx, 12px, 700, $c_txt, 16px);
            display: block;
            text-transform: uppercase;
        }
    }

    .txt {
        position: relative;
        padding: 20px 40px 40px;
        @extend .background-alsace-2022;

        body.site_noel & {
            background: $c_noel3;
        }
        body.site_vins & {
            background: $c_vins1;
        }
        body.site_velo & {
            background: $c_velo1;
        }

        h2 {
            @include ft($f_hx, 20px, 700, $c_white, 30px);
            text-transform: uppercase;
            padding-bottom: 20px;
            margin: 0 0 20px 0;
            position: relative;
            display: block;
            border-bottom: 1px dashed $c_white;
        }

        ul {
            li {
                @include ft($f_txt, 18px, 400, $c_white, 20px);
                position: relative;
                margin: 8px 0;
                padding-left: 10px;
                &:before{
                    content: '•';
                    position: absolute;
                    top: 1px;
                    left: 0;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}



// CONSEIL DU CHEF
.bloc-conseil-du-chef {
    position: relative;
    margin: $content-spacing/2 0;
    padding: 55px 40px 55px 20px;
    background: $c_grey;
    display: flex;
    align-items: flex-start;
    @include mobile{
        display: block;
        padding: 20px 20px 40px;
    }

    figure {
        flex: none;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: none;
        border: 10px solid $c_white;
        overflow: hidden;
        margin-top: -35px;
        /* #CHANGE COLOR THEME */
        .site_noel & {
            border-color: $c_noel1;
        }
        .site_vins & {
            border-color: $c_vins1;
        }
        .site_velo & {
            border-color: $c_velo1;
        }
        @include tablet_landscape{
            width: 150px;
            height: 150px;
        }
        @include mobile{
            margin: 0 auto 25px auto;
        }

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }

        & + .txt{
            padding-left: 10px;
            padding-top: 0;
            @include mobile{
                padding-left: 0;
            }
        }
    }

    .txt {
        padding-left: 80px;
        @include mobile{
            padding-left: 0;
            padding-top: 30px;
            text-align: center;
        }
    }

    .title-citation {
        @include ft($f_hx, 40px, 400, $c_txt, 40px);
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        margin: 0 0 -10px -10px;
        display: block;
        @include mobile {
            font-size: calculateRem(30px);
            line-height: calculateRem(30px);
            margin: auto;
        }

        &:before {
            content: "\e91a";
            @include font();
            position: absolute;
            top: -25px;
            left: -50px;
            font-size: 120px;
            color: $c_white;
            z-index: -1;
            @include mobile {
                left: -20px;
            }
        }

        span {
            @include ft($f_spe, 140px, 400, $c_main, 100px);
            text-transform: none;
            display: block;
            margin-top: 5px;
            margin-left: 88px;
            @media screen and (max-width: 1240px) {
                margin-top: 0;
                margin-left: 40px;
                font-size: 100px;
                line-height: 70px;
            }
            @include mobile {
                font-size: 60px;
                line-height: 40px;
                margin-top: 0;
                margin-left: auto;
            }

            body.site_noel & {
                color: $c_noel1;
            }
            body.site_vins & {
                color: $c_vins1;
            }
            body.site_velo & {
                color: $c_velo1;
            }
        }

        +blockquote{
            margin-top: 20px;
        }
    }

    blockquote {
        @include ft($f_txt, 18px, 400, $c_txt, 22px);
        font-style: italic;
    }

    .name {
        @include ft($f_hx, 20px, 700, $c_main, 20px);
        display: block;
        text-transform: uppercase;
        margin: 15px 0 0 0;
        & + .btn{
            margin-top: 15px;
        }

        body.site_noel & {
            color: $c_noel1;
        }
        body.site_vins & {
            color: $c_vins1;
        }
        body.site_velo & {
            color: $c_velo1;
        }
    }
}