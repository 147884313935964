.bloc-discover-alsace {
	position: relative;
	z-index: 10;
	@include clearfix();
	/* Texte "On vous guide" */
	.txt {
        @extend %bloc-margin-inside-top-bottom;
        width: 60%;
        margin-bottom: 35px;
        @include tablet_landscape{
            width: 65%;
        }
        @include tablet_portrait{
            width: 100%;
        }
        .surtitre{
            text-transform: uppercase;
            @include tablet_portrait{
                margin: 0;
            }
        }
		h2 {
			@include ft($f_spe, 110px, 400, $c_main, 90px);
            margin-top: 0;
			margin-bottom: 15px;
            /* #CHANGE COLOR THEME */
            @include tablet_landscape{
                font-size: calculateRem(90px);
                line-height: calculateRem(80px);
            }
            @include tablet_portrait{
                font-size: calculateRem(80px);
                line-height: calculateRem(70px);
            }
            @include mobile{
                font-size: calculateRem(60px);
                line-height: calculateRem(50px);
            }
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins1;
            }
            .site_velo & {
                color: $c_velo1;
            }
		}
		p {
			max-width: 335px;
		}
	}



	/* ROUTE DES VINS */
	.map-svg.map-vins{


		> .link-figure{
			@extend .center;
			width: 100%;
			height: auto;
			z-index: 1000;
			figure{
				background: none;
				width: 100%;
				height: 100%;
			}
			img{
				width: 100%;
				height: 100%;
			}
		}

	}


	/* MAP NOEL */
	.map-svg.map-noel{
		position: relative;
		left: -50px;
		top: -20px;
		@include aspect-ratio(523, 782);
		overflow: visible;
		@media screen and (min-width: 550px) {
			width: 75%;
			left: auto;
		}
		@include min-tablet_portrait {
			position: absolute;
			top: 0;
			left: auto;
			right: 50px;
			width: 45%;
		}
		@include min-tablet_landscape {
			right: 50px;
			width: 40%;
		}

		> figure {
			@extend .center;
			width: 100%;
			height: auto;
			background: none;
			z-index: 1000;
			img{
				width: 100%;
				height: 100%;
			}
		}

		.wrapper-points {
			position: absolute;
			@extend %center;
			z-index: 2000;
			.pulse {
				position: absolute;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: $c_noel1;
				box-shadow: 0 0 0 0 rgba($c_noel1, 0.8), 0 0 0 0 rgba($c_noel1, 0.9);
				@include addTransition();

				&:active {
					animation: pulse 1s infinite cubic-bezier(0.66, 0.33, 0, 1);
				}
				&.show-pulse {
					animation: pulse 1s infinite cubic-bezier(0.66, 0.33, 0, 1);
				}

				&:hover {
					cursor: pointer;
					background: lighten($c_noel1, 15%);
				}
				&:before {
					@include ft($f_txt, 11px, 400, $c_txt, 14px);
					position: relative;
					text-transform: capitalize;
					@include min-desktop {
						font-size: 13px;
						line-height: 16px;
					}
				}
				&.city-haguenau {
					top: 21%;
					right: 33.5%;
					&:before {
						content: attr(data-city);
						top: -18px;
						left: -18px;
					}
				}
				&.city-strasbourg {
					top: 31.5%;
					right: 36%;
					&:before {
						content: attr(data-city);
						top: -18px;
						left: -18px;
					}
				}
				&.city-obernai {
					top: 38%;
					right: 46.5%;
					&:before {
						content: attr(data-city);
						top: -14px;
						left: -45px;
					}
				}
				&.city-ribeauville{
					top: 51%;
					right: 60%;
					width: 6px;
					height: 6px;
					background: $c_dark;
					box-shadow: 0 0 0 0 rgba($c_dark, 0.4), 0 0 0 0 rgba($c_dark, 0.3);
					&:before {
						content: 'Ribeauvillé';
						font-size: 12px;
						line-height: 14px;
						top: -13px;
						left: -54px;
					}
				}
				&.city-riquewihr {
					top: 52.9%;
					right: 60.2%;
					width: 6px;
					height: 6px;
					background: $c_dark;
					box-shadow: 0 0 0 0 rgba($c_dark, 0.4), 0 0 0 0 rgba($c_dark, 0.3);
					&:before {
						content: attr(data-city);
						font-size: 12px;
						line-height: 14px;
						top: -4px;
						left: 10px;
					}
				}
				&.city-kaysersberg {
					top: 54.5%;
					right: 61.8%;
					width: 6px;
					height: 6px;
					background: $c_dark;
					box-shadow: 0 0 0 0 rgba($c_dark, 0.4), 0 0 0 0 rgba($c_dark, 0.3);
					&:before {
						content: attr(data-city);
						font-size: 12px;
						line-height: 14px;
						top: 3px;
						left: -65px;
					}
				}
				&.city-eguisheim {
					top: 59%;
					right: 58%;
					width: 6px;
					height: 6px;
					background: $c_dark;
					box-shadow: 0 0 0 0 rgba($c_dark, 0.4), 0 0 0 0 rgba($c_dark, 0.3);
					&:before {
						content: attr(data-city);
						font-size: 12px;
						line-height: 14px;
						top: 2px;
						left: -53px;
					}
				}
				&.city-colmar {
					top: 56%;
					right: 54.8%;
					&:before {
						content: attr(data-city);
						top: -1px;
						left: 18px;
					}
				}
				&.city-mulhouse {
					right: 55.5%;
					bottom: 26%;
					&:before {
						content: attr(data-city);
						top: -2px;
						left: -59px;
					}
				}
				&.city-selestat {
					right: 50%;
					bottom: 51%;
					&:before {
						content: 'Sélestat';
						top: -17px;
						left: -15px;
					}
				}
			}
		}

		.wrapper-txt {
			position: absolute;
			right: -50px;
			top: 100px;
			width: auto;
			//z-index: 10000;
			transition: all 0.3s ease;
			@media screen and (min-width: 375px){
				top: 110px;
			}
			@include min-tablet_portrait {
				top: 130px;
			}
			@include min-desktop {
				right: -60px;
			}
			@media screen and (min-width: 1600px){
				top: 170px;
				right: -40px;
			}
		}
	}


	/* MAP INTERACTIVE */
	.map-svg {
        @include aspect-ratio(532, 841);
		position: absolute;
        z-index: 10;
        width: 40%;
        top: 0;
        right: 50px;
		overflow: visible;
        @include clearfix();
        @include tablet_portrait{
            position: relative;
            z-index: 10;
            margin: auto;
            margin-top: -50px;
            width: 60%;
            right: 0;
        }
        @include mobile{
            left: -50px;
            width: 100%;
            margin-top: -40px;
            margin-bottom: 20px;
        }

		> .wrapper-map-svg {
			@extend .center;
		}

		.wrapper-map-svg {
			display: block;
			@extend %center;
			background-image: url(../images/maps/map-svg-2x.png);
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: center;
			z-index: 150000;
			svg {
				position: absolute;
				top: 6.2%;
				left: 8.6%;
				right: 0;
				bottom: 0;
				opacity: 1;
				width: 100%;
				@include min-tablet_portrait {
					top: 6.4%;
				}
				@include min-desktop {
					top: 6.5%;
				}
				@include min-large-desktop {
					left: 8.7%;
				}
				> g {
					> g {
						polygon {
							@include addTransition();
							&:hover, &:active, &.active {
								cursor: pointer;
								fill: #e6e6e6 !important;
							}
						}

						&.active polygon {
							fill: #e6e6e6 !important;

						}
					}
				}
			}
			.wrapper-points {
				.pulse {
					position: absolute;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background: $c_main;
					box-shadow: 0 0 0 0 rgba($c_main, 0.8), 0 0 0 0 rgba($c_main, 0.9);
					@include addTransition();

					&:active {
						animation: pulse 1s infinite cubic-bezier(0.66, 0.33, 0, 1);
					}
					&.show-pulse {
						animation: pulse 1s infinite cubic-bezier(0.66, 0.33, 0, 1);
					}

					&:hover {
						cursor: pointer;
						background: lighten($c_main, 15%);
					}
					&:before {
						@include ft($f_txt, 11px, 400, $c_txt, 14px);
						position: relative;
						text-transform: capitalize;

						@include min-desktop {
							font-size: 13px;
							line-height: 16px;
						}
					}
					&.city-saverne {
						top: 16%;
						left: 40%;
					}
					&.city-haguenau {
						top: 16%;
						right: 28%;
					}
					&.city-strasbourg {
						top: 29%;
						right: 31%;
						&:before {
							content: attr(data-city);
							top: -23px;
							left: -15px;
						}
					}
					&.city-obernai {
						top: 38%;
						left: 48%;
					}
					&.city-selestat {
						top: 47%;
						right: 43%;
					}
					&.city-colmar {
						top: 55%;
						right: 52%;
						&:before {
							content: attr(data-city);
							top: -17px;
							left: 23px;
						}
					}
					&.city-munster {
						top: 57%;
						left: 27%;
					}
					&.city-mulhouse {
						right: 54%;
						bottom: 22%;
						&:before {
							content: attr(data-city);
							top: -23px;
							left: -30px;
						}
					}
					&.city-altkirch {
						bottom: 13%;
						left: 36%;
					}
					&.city-bale {
						right: 44%;
						bottom: 13%;
						&:before {
							content: attr(data-city);
							top: -23px;
							left: 0;
						}
					}
				}
			}
		}

		.wrapper-txt {
			position: absolute;
			top: 130px;
			width: auto;
            right: -60px;
			@include addTransition();
			@include tablet_landscape{
                top: 100px;
            }
            @include tablet_portrait{
                right: -50px;
            }
            @include mobile{
                top: 80px;
            }
            @include mobile_small{
                top: 60px;
            }
			figure {
				border-radius: 50%;
				overflow: hidden;
				margin: auto;
                width: 220px;
                height: 220px;
                margin-bottom: 25px;
                @include tablet_landscape{
                    width: 180px;
                    height: 180px;
                    border: 8px solid $c_white;
                }
                @include mobile{
                    width: 160px;
                    height: 160px;
                    margin-bottom: 15px;
                }
                @include mobile_small{
                    width: 140px;
                    height: 140px;
                    margin-bottom: 10px;
                }
			}
			> div {
				position: relative;
				z-index: 10000000;
				width: 220px;
				margin: auto;
				display: block;
                padding-left: 30px;
                @include mobile{
                    width: 160px;
                    padding: 0;
                }
                @include mobile_small{
                    width: 140px;
                }
			}
			.city-title {
				@include ft($f_hx, 20px, 700, $c_txt, 26px);
                text-transform: uppercase;
                display: block;
                margin-bottom: 10px;
			}
			p {
				@include ft('', 14px, '', '', 20px);
			}
			.btn {
                margin-top: 15px;
                width: 100%;
                display: block;
                @include mobile{
                    margin-top: 10px;
                }
			}

			&.show-out {
				transform: translate3d(-100px, 0, 0);
				opacity: 0;
			}
			&.hidedata {
				opacity: 0;
			}
		}
	}

	/* Bloc recommandation */
	.recommandation {
		position: relative;
        float: left;
		z-index: 50;
        width: 60%;
		@include clearfix();
        @include tablet_portrait{
            width: 100%;
            float: none;
        }
		.title-recommandation {
			@include ft($f_hx, 24px, 400, $c_txt, 24px);
            text-transform: uppercase;
			margin: 0;
            @include mobile{
                margin-bottom: 15px;
            }
			.site_vins & {
				color: $c_vins2;
			}
			.site_velo & {
				color: $c_velo2;
			}
		}
		> .basic-link {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
            @include mobile{
                position: relative;
                margin-bottom: 20px;
            }
		}

		.wrapper-recommandation {
			position: relative;
			@include clearfix();
			.item {
				position: relative;
                float: left;
                width: calc(50% - 10px);
                display: block;
				margin-top: 20px;
                margin-right: 20px;
                &:nth-child(even){
                    margin-right: 0;
                }
                @include mobile{
                    float: left;
                    width: 100%;
                    margin: 10px 0;
                    &:first-child{
                        margin-top: 0;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
				&:hover {
					body:not(.no-hover) & {
						cursor: pointer;
						.basic-link {
							color: $c_main;
							&:after {
								right: -5px;
							}
						}
					}
					/* #CHANGE COLOR THEME */
					body.site_noel:not(.no-hover) & {
						.basic-link{
							color: $c_noel2;
						}
					}
					body.site_vins:not(.no-hover) & {
						.basic-link{
							color: $c_vins2;
						}
					}
					body.site_velo:not(.no-hover) & {
						.basic-link{
							color: $c_velo2;
						}
					}
				}

				figure {
                    @include aspect-ratio(338,225);
                    background: none;
					&:after {
						content: '';
						@extend .gradient-bottom;
					}
					figcaption {
						display: none;
					}
					img {
						@extend %center;
					}
				}
				.content {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    top: 50%;
                    width: calc(100% - 40px);
                    transform: translateY(-50%);
                    transform: translate3d(0,-50%,0);
                    @extend %bloc-margin-inside-top-bottom;
					.title-item {
						@include ft($f_hx, 20px, 700, $c_white, 20px);
                        text-transform: uppercase;
						margin-bottom: 7px;
						/* #CHANGE COLOR THEME */
						.site_noel & {
							color: $c_noel2;
						}
						.site_vins & {
							color: $c_vins2;
						}
						.site_velo & {
							color: $c_velo2;
						}
						@include min-tablet_landscape {
							font-size: 24px;
							line-height: 24px;
							color: $c_white;
							/* #CHANGE COLOR THEME */
							.site_noel &, .site_vins &, .site_velo & {
								color: $c_white;
							}
						}
					}
					p {
						@include ft($f_txt, 14px, 400, $c_white, 20px);
						margin-bottom: 0;
					}
					.basic-link {
                        color: $c_white;
                        line-height: 25px;
                        &:after{
                            font-size: 25px;
                            line-height: 25px;
                            color: $c_main;
                        }
						/* #CHANGE COLOR THEME */
						.site_noel & {
							&:after{
								color: $c_noel2;
							}
						}
						.site_vins & {
							&:after{
								color: $c_vins2;
							}
						}
						.site_velo & {
							&:after{
								color: $c_velo2;
							}
						}
					}
				}
			}
		}
	}


	&.--velo{

		.recommandation .title-recommandation{
			color: $c_txt;
			margin-bottom: 20px;
		}
		.wrapper-bouton{
			position: relative;
			&:after{
				content: '';
				display: block;
				clear: both;
			}
			.bouton{
				position: relative;
				box-sizing: border-box;
				float: left;
				width: calc(50% - 10px);
				margin-bottom: 20px;
				padding: 20px 20px 17px 20px;
				border:1px solid #C3C3C3;
				display: flex;
				flex-flow: column;
				justify-content: flex-end;
				min-height: 90px;
				background-color: white;
				@include tablet_portrait{
					
				}
				&:hover{
					body:not(.no-hover) & {
						background-color: $c_velo2;
						border-color: $c_velo2;
						.titre, .decouvrir, .decouvrir span{
							color: white;
						}
					}
				}
				@include mobile{
					width: 100%;
					float: none;
					margin-bottom: 10px;
					min-height: 60px;
					padding: 15px 15px 12px 15px;
				}
				&:nth-child(odd){
					margin-right: 20px;
					clear: left;
					@include mobile{
						margin-right: 0;
					}
				}

				.titre{
					font-family: $f_hx;
					font-size: 20px;
					line-height: 21px;
					font-weight: 600;
					text-transform: uppercase;
					color: $c_txt;
					margin-top: 0;
					margin-bottom: auto;
					@include addTransition();
					@include mobile{
						font-size: 16px;
						line-height: 16px;
					}
				}
				.decouvrir{
					font-family: $f_hx;
					font-weight: 500;
					font-size: 14px;
					line-height: 25px;
					text-transform: uppercase;
					letter-spacing: 1px;
					color: $c_txt;
					margin-bottom: 0;
					margin-top: auto;
					@include addTransition();
					@include mobile{
						font-size: 12px;
						line-height: 20px;
					}
					span{
						display: inline-block;
						color: $c_velo2;
						font-size: 25px;
						line-height: 1;
						margin-left: 10px;
						vertical-align: middle;
						margin-top: -3px;
						@include addTransition();
						@include mobile{
							font-size: 20px;
						}
					}
				}
			}
		}
	}

}