// CLASS POUR GESTION DU SCROLLED
.menu-is-scrolled {
    .bloc-form-algolia {
        top: 10px;
        @include mobile {
            top: 0;
        }

        &:before {
            color: $c_txt;
        }

        input {
            border-color: $c_border;
            color: $c_txt;

            &:hover, &:focus {
                body:not(.no-hover) & {
                    border-color: $c_main;
                }
                body:not(.no-hover).site_noel & {
                    border-color: $c_noel1;
                }
                body:not(.no-hover).site_vins & {
                    border-color: $c_vins1;
                }
                body:not(.no-hover).site_velo & {
                    border-color: $c_velo1;
                }
            }

            &::placeholder {
                color: $c_txt;
            }
        }
    }

    .menu-right {
        top: 0;

        .circle {
            a {
                color: $c_txt;

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_main;
                    }
                    body:not(.no-hover).site_noel & {
                        color: $c_noel1;
                    }
                    body:not(.no-hover).site_vins & {
                        color: $c_vins1;
                    }
                    body:not(.no-hover).site_velo & {
                        color: $c_velo1;
                    }
                }

                @include mobile {
                    color: $c_white;
                }
            }
        }

        .lang {
            > a {
                color: $c_txt;

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_main;
                    }
                    body:not(.no-hover).site_noel & {
                        color: $c_noel1;
                    }
                    body:not(.no-hover).site_vins & {
                        color: $c_vins1;
                    }
                    body:not(.no-hover).site_velo & {
                        color: $c_velo1;
                    }
                }

                @include mobile {
                    color: $c_white;
                }
            }
        }

        .navTrigger-desktop {
            i {
                background: $c_main;
                @include mobile {
                    background: $c_white!important;
                }

                body.site_noel.is-scrolled & {
                    background: $c_noel1;
                }
                body.site_vins.is-scrolled & {
                    background: $c_vins1;
                }
                body.site_velo.is-scrolled & {
                    background: $c_velo1;
                }
                body.site_noel & {
                    background: $c_noel1;
                }
                body.site_vins & {
                    background: $c_vins1;
                }
                body.site_velo & {
                    background: $c_velo1;
                }
            }

            span {
                color: $c_main;
				@include mobile {
					color: $c_white!important;
				}

                body.site_noel.is-scrolled & {
                    color: $c_noel1;
                }
                body.site_vins.is-scrolled & {
                    color: $c_vins1;
                }
                body.site_velo.is-scrolled & {
                    color: $c_velo1;
                }
                body.site_noel & {
                    color: $c_noel1;
                }
                body.site_vins & {
                    color: $c_vins1;
                }
                body.site_velo & {
                    color: $c_velo1;
                }
                @include mobile {
                    color: $c_white;
                }
            }
        }
    }
}

// Logo du site
// ==========================================================================
.logo-alsace {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500000;
    margin-top: 10px;
    margin-left: 20px;
    @include mobile {
        position: absolute;
        z-index: 50000;
        margin-left: 10px;
    }

    //On affiche l'ancien logo sur noel et vins
    .site_noel &, .site_vins & {
        display: none !important;
    }

    figure {
        background: none;

        figcaption {
            display: none;
        }
    }

    &.white{
        display: none;
    }

    body.site_vins &, body.site_noel & , body.site_velo & {
        &.white{
            display: block;
            &.menu-no-header{
                display: none;
            }
        }
        &.classic{
            display: none;
            &.menu-no-header{
                display: block;
            }
        }
    }
    body.is-scrolled.site_vins &,body.is-scrolled.site_velo &, body.is-scrolled.site_noel &, body.no-scroll.site_vins &, body.no-scroll.site_velo &, body.no-scroll.site_noel & {
        &.white{
            display: none;
        }
        &.classic{
            display: block;
        }
    }
}

// Logo du site (vieux logo on le garde quand même parcequ'on l'a sur vins et noel)
// ==========================================================================
.logo-old {
    position: fixed;
    z-index: 500000;
    top: 40px;
    left: 60px;
    display: none !important;
    transform:translate3d(0,0,0);
    height:auto;
    width:auto;
    @include ft($f_txt, 20px, 700, $c_white, $header-height);
    @include addTransitionCurve();

    @include mobile{
        position: absolute;
        top: 20px;
        left: 40px;
    }

    &.noel{
        .site_noel &{
            display: block !important;
        }
    }
    &.vins{
        .site_vins & {
            display: block !important;
        }
    }
    &.velo{
        .site_velo & {
            display: block !important;
        }
    }
    .site_noel.lang-de &, .site_noel.lang-en &, .site_vins &, .site_velo &{
        font-size: 14px;
        @include min-tablet_portrait {
            font-size: 20px;
        }
        @include min-tablet_landscape {
            font-size: 18px;
        }
        @include min-desktop {
            font-size: 16px;
        }
        @include wide {
            font-size: 20px;
        }
    }

    &.stick-top{
        top: 0;
        color: $c_dark;
    }

    &:before {
        content: "\e907";
        @include font();
        color: $c_main;
        font-size: 28px;
        line-height: $header-height;
        margin-top: 2px;
        margin-right: 8px;
        float: left;
        transition: all 300ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
        .site_noel.lang-de &, .site_noel.lang-en & {
            font-size: 20px;
            @include min-tablet_portrait {
                font-size: 28px;
            }
        }
        .site_vins & {
            font-size: 16px;
            @media screen and (min-width: 360px){
                font-size: 22px;
            }
            @media screen and (min-width: 375px){
                font-size: 28px;
            }
        }
        .site_velo & {
            font-size: 16px;
            @media screen and (min-width: 360px){
                font-size: 22px;
            }
            @media screen and (min-width: 375px){
                font-size: 28px;
            }
        }
    }

    //#13637
    &.logo-70{
        left: 20px;
        top: 20px;
        @include mobile{
            top: 10px;
            left: 10px;
        }
        &:before{
            content:none;
        }
        background-color: transparent;
        figure{
            background-color: transparent;
            width: auto;
            height: 100%;
        }
        img{
            width: auto;
            height: 100%;
        }
    }

    .is-scrolled &, .no-scroll & {
        top: 0;
        color: $c_txt;

        &.logo-70{
            @include min-mobile{
                top: 5px;
                left: 40px;
                height: 60px;
            }
        }
    }


    #menu-toggle:checked ~ & {
        @include mobile{
            display: none!important;
        }
    }
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    padding: 0 40px;
    background: none;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include addTransitionCurve();
    @include mobile {
        justify-content: flex-start;
        top: auto;
        bottom: 0;
        height: 60px;
        background: $c_main;
        padding: 0 15px;

        body.site_noel & {
            background-color: $c_noel1;
        }
        body.site_vins & {
            background-color: $c_vins1;
        }
        body.site_velo & {
            background-color: $c_velo1;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &:before {
        content: '';
        @extend %center;
        opacity: 0;
        transform: translateY(-100%);
        transform: translate3d(0, -100%, 0);
        z-index: -1;
        background: $c_white;
        @include addTransitionCurve();
        @include mobile {
            content: none;
        }
    }

    #search-fake-btn {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
    }

    .is-scrolled & {
        box-shadow: 0 2px 15px rgba($c_dark, 0.15);

        &:before {
            transform: translateY(0);
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        &:after {
            opacity: 1;
        }

        @extend .menu-is-scrolled;
    }

    // HEADER SANS PHOTO
    &.menu-no-header {
        background: $c_white;
        box-shadow: none;
        @include mobile {
            background: $c_main;
        }

        &:before {
            content: none;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: $c_border;
            z-index: 1;
            @include mobile {
                content: none;
            }
        }

        @extend .menu-is-scrolled;

        & ~ #pwa-wrapper {
            header {
                &:before {
                    content: none;
                    @include mobile {
                        content: '';
                    }
                }
            }
        }
    }

    // Formulaire Algolia
    // ==========================================================================
    .bloc-form-algolia {
        display: block;
        position: absolute;
        left: 0;
        top: 50px;
        right: 0;
        width: 100%;
        max-width: 700px;
        margin: auto;
        @include addTransitionCurve();
        @media screen and (max-width: 1240px) {
            max-width: 500px;
        }
        @include tablet_portrait {
            left: 110px;
            right: auto;
            max-width: calc(100% - 345px);
            .site_noel &{
                left: 210px;
                max-width: calc(100% - 445px);
            }
            .site_vins &{
                left: 210px;
                max-width: calc(100% - 445px);
            }
            .site_velo &{
                left: 160px;
                max-width: calc(100% - 445px);
            }
        }
        @include mobile {
            display: none;
            .site_noel &{
                left: 0;
                max-width: none;
            }
            .site_vins &{
                left: 0;
                max-width: none;
            }
            .site_velo &{
                left: 0;
                max-width: none;
            }
        }

        &:before {
            content: "\e92b";
            @include font();
            position: absolute;
            color: $c_white;
            font-size: 24px;
            top: 12px;
            left: 20px;
        }

        input {
            @include ft($f_txt, 14px, 400, $c_white, 24px);
            @extend %appearance;
            box-sizing: border-box;
            height: 48px;
            border: 1px solid rgba($c_white, 0);
            background: rgba($c_white, 0.2);
            border-radius: 24px;
            -webkit-appearance: none;
            outline: none;
            width: 100%;
            padding: 11px 55px;
            @include addTransition();

            &::placeholder {
                color: $c_white;
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    border-color: $c_main;
                }

                /* #CHANGE COLOR THEME */
                body.site_noel:not(.no-hover) & {
                    border-color: darken($c_noel1, 5%);
                }
                body.site_vins:not(.no-hover) & {
                    border-color: darken($c_vins1, 5%);
                }
                body.site_velo:not(.no-hover) & {
                    border-color: darken($c_velo1, 5%);
                }

                .is-scrolled & {
                    border-color: $c_white;
                }
            }
        }
    }


    .form-search-mobile {
        pointer-events: none;
        z-index: 100;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        background: $c_white;
        @include addTransition();

        form {
            position: relative;
            display: block;
            width: calc(100% - 20px);
            margin: 6px 10px;
            max-width: 100%;
            left: 0;

            &:before {
                content: "\e92b";
                @include font();
                position: absolute;
                color: $c_white;
                font-size: 18px;
                top: 15px;
                left: 15px;
            }

            input {
                @include ft($f_txt, 14px, 400, $c_white, 24px);
                @extend %appearance;
                box-sizing: border-box;
                height: 48px;
                border: 1px solid rgba($c_white, 0);
                background: rgba($c_white, 0.2);
                border-radius: 24px;
                -webkit-appearance: none;
                outline: none;
                width: 100%;
                padding: 11px 20px 11px 45px;
                @include addTransition();
            }
        }
    }

    // Nav Right
    // ==========================================================================
    .menu-right {
        position: relative;
        top: 40px;
        display: flex;
        align-items: center;
        @include addTransitionCurve();
        @include mobile {
            top: 0;
        }

        .circle {
            position: relative;

            > * {
                display: block;
                padding: 15px 10px;
                font-size: 18px;
                color: $c_white;
                @include addTransition();
                @include mobile {
                    font-size: 16px;
                    padding: 22px 15px;
                }
                @media screen and (max-width: 420px){
                    body.site_velo & {
                        padding: 22px 12px;
                    }
                }
                @media screen and (max-width: 340px){
                    body.site_velo & {
                        padding: 22px 9px;
                    }
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;
                        color: $c_main;

                        small {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    body:not(.no-hover).site_noel & {
                        color: $c_noel1;
                    }
                    body:not(.no-hover).site_vins & {
                        color: $c_vins1;
                    }
                    body:not(.no-hover).site_velo & {
                        color: $c_velo1;
                    }
                }

                span {
                    display: block;
                }
            }
        }


        .geoloc-site-en {
            small {
                @include ft($f_txt, 12px, 700, $c_white, 14px);
                visibility: hidden;
                width: 100px;
                top: calc(100% + 15px);
                left: 50%;
                margin-left: -70px;
                background-color: #1A1A1A;
                text-align: center;
                padding: 8px 20px;
                border-radius: 10px;
                box-shadow: 0 10px 30px rgba($c_dark, 0.15);
                text-transform: none;
                position: absolute;
                white-space: normal;
                opacity: 0;
                z-index: 2000;
                @include addTransition();

                .no-hover & {
                    display: none;
                }

                &:after {
                    content: " ";
                    position: absolute;
                    bottom: calc(100% - 1px); /* At the top of the tooltip */
                    left: 50%;
                    margin-left: -8px;
                    border-width: 8px;
                    border-style: solid;
                    border-color: transparent transparent #1A1A1A transparent;
                }
            }
        }


        // LANG
        .lang {
            position: relative;

            > a {
                @include ft($f_hx, 13px, 700, $c_white, 18px);
                text-transform: uppercase;
                padding: 15px 10px;
                display: block;
                @include addTransition();

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;
                        color: $c_main;
                    }
                    body:not(.no-hover).site_noel & {
                        color: $c_noel1;
                    }
                    body:not(.no-hover).site_vins & {
                        color: $c_vins1;
                    }
                    body:not(.no-hover).site_velo & {
                        color: $c_velo1;
                    }
                }

                span {
                    vertical-align: middle;
                }
            }

            .sub-menu {
                position: absolute;
                background: $c_white;
                width: auto;
                overflow: hidden;
                transform: scaleY(0);
                transform-origin: top;
                opacity: 0;
                box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
                padding: 4px 0;
                top: $header-height;
                z-index: 10;
                display: block;
                margin-top: -20px;
                @include addTransition();

                &.sub-lang-mobile {
                    display: none !important;
                }

                &.is-focus-lang {
                    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
                    padding: 4px 0;
                    top: $header-height;
                    opacity: 1;
                    z-index: 10;
                    display: block;
                    transform: scaleY(1);
                }

                li {
                    display: block;
                    width: 100%;
                }

                a {
                    @include ft($f_hx, 13px, 700, $c_txt, 18px);
                    padding: 5px 10px;
                    text-align: left;
                    height: auto;
                    display: block;
                    text-transform: uppercase;

                    &:hover, &:focus {
                        body:not(.no-hover) & {
                            cursor: pointer;
                            color: $c_main;
                        }
                        body:not(.no-hover).site_noel & {
                            color: $c_noel1;
                        }
                        body:not(.no-hover).site_vins & {
                            color: $c_vins1;
                        }
                        body:not(.no-hover).site_velo & {
                            color: $c_velo1;
                        }
                    }
                }
            }

            select {
                display: none;

                &.is-display {
                    display: block;
                    opacity: 0;
                    @extend %center;
                    text-transform: uppercase;
                }

                option {
                    text-transform: uppercase;
                }
            }

            &.open {
                .sub-menu {
                    opacity: 1;
                    transform: scaleY(1);
                }
            }
        }
    }


}

// Menu Burger
// ==========================================================================
.navTrigger-desktop {
    cursor: pointer;
    position: relative;
    top: 3px;
    z-index: 100000;
    margin: 0 20px;
    @include mobile {
        top: 0;
    }
    @media screen and (max-width: 420px){
        margin: 0 5px;
    }

    &:hover {
        body:not(.no-hover) & {
            &:not(.active) {
                i:nth-child(2) {
                    transform: scaleX(1.5);
                    transform-origin: left;

                    .is-scrolled & {
                        background: $c_white;
                    }
                }
            }
        }
    }

    span {
        @include ft($f_hx, 14px, 500, $c_white, 14px);
        text-transform: lowercase;
        display: block;
        @include mobile {
            font-family: $f_txt;
            font-size: calculateRem(12px);
            line-height: calculateRem(12px);
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-top: 6px;
        }
    }

    i {
        background-color: $c_white;
        border-radius: 2px;
        content: '';
        display: block;
        height: 3px;
        position: relative;
        width: 35px;
        margin: auto;
        transform: translate3d(0, 0, 0) rotate(0deg);
        @include addTransition();
        @include mobile {
            width: 24px;
            height: 2px;
            left: 50%;
            margin-left: -12px;
        }

        &:first-child {
            @include mobile {
                display: none;
            }
        }
    }

    i:nth-child(2) {
        margin: 5px auto;
        width: 30px;
        left: -2.5px;
        transform-origin: left;
        @include mobile {
            width: 24px;
            left: 50%;
            margin-left: -12px;
        }
    }

    &.active {
        i:nth-child(1) {
            opacity: 0;
        }

        i:nth-child(2) {
            width: 35px;
            left: 0;
            transform-origin: center;
            transform: translate3d(0, 0, 0) rotate(45deg);
            @include mobile {
                width: 24px;
                left: 50%;
            }

        }

        i:nth-child(3) {
            transform: translate3d(0, -9px, 0) rotate(135deg);
            @include mobile {
                transform: translate3d(0, -8px, 0) rotate(135deg);
            }
        }
    }
}


// History Back
// ==========================================================================
#history-back {
    font-size: 19px;
    color: $c_main;
    float: left;
    padding: 23px 20px;
    margin-left: -20px;
    display: none;
    @include addTransition();
    @supports (-webkit-overflow-scrolling: touch) {
        display: block;
        @include min-tablet_portrait {
            display: none;
        }
    }

    .is-scrolled & {
        color: $c_white;
    }

    span {
        position: relative;
        top: 2px;
        display: block;
    }
}