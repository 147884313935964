// BLOC TEXTE - DEFAULT SETTINGS
// ==========================================================================
.bloc-texte {

    // Alignement
    &.aligncenter {
        margin-left: auto;
        margin-right: auto;

        > * {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.alignright {
        margin-left: auto;
        margin-right: 0;

        > * {
            margin-left: auto;
            margin-right: 0;
        }
    }

    // Gestion des alignements et du max-width des éléments
    > * {
        position: relative;
        max-width: $grid-small;

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;

            &:not(.btn) {
                padding-bottom: 0;
            }

            .btn {
                margin-bottom: 0;
            }
        }

        &.aligncenter, &[style*="text-align: center"] {
            margin-left: auto;
            margin-right: auto;
        }

        &.alignright, &[style*="text-align: right"] {
            margin-left: auto;
            margin-right: 0;
        }

        &[style*="text-align: justify"] {
            margin-left: 0;
            margin-right: 0;
            max-width: 100% !important;
        }
    }

    .surtitre {
        margin-top: 35px;
        @include mobile {
            margin-top: 25px;
        }

        & + h2, & + .title-h2, & + h3, & + .title-h3 {
            margin-top: -10px;
            @include mobile {
                margin-top: -5px;
            }
        }
    }

    li, blockquote p {
        max-width: $grid-small;

        &[style*="text-align: center"] {
            margin-left: auto;
            margin-right: auto;
        }

        &[style*="text-align: right"] {
            margin-left: auto;
            margin-right: 0;
        }
    }

    // Styles de textes
    h2, .title-h2 {
        @include ft($f_hx, $s_h2, 700, $c_txt, $s_h2);
        text-transform: uppercase;
        display: block;
        margin-top: 35px;
        margin-bottom: 15px;
        @include mobile {
            font-size: calculateRem(30px);
            line-height: calculateRem(30px);
            margin-top: 25px;
            margin-bottom: 10px;
        }

        & + .soustitre {
            margin-top: -5px;
            margin-bottom: 25px;
            @include mobile {
                margin-bottom: 20px;
            }
        }

        // STYLE
        &.txt-styled{
            @include ft($f_spe, 100px, 400, $c_main, 60px);
            text-transform: none;
            @include tablet_landscape{
                font-size: calculateRem(90px);
                line-height: calculateRem(80px);
            }
            @include mobile{
                font-size: calculateRem(70px);
                line-height: calculateRem(50px);
            }

            body.site_noel & {
                color: $c_noel2;
            }
            body.site_vins & {
                color: $c_vins2;
            }
            body.site_velo & {
                color: $c_velo2;
            }
        }

        &.txt-styled-bloc{
            @include ft($f_spe, 70px, 400, $c_main, 50px);
            text-transform: none;
            @include tablet_landscape{
                font-size: calculateRem(60px);
                line-height: calculateRem(40px);
            }
            @include mobile{
                font-size: calculateRem(50px);
                line-height: calculateRem(30px);
            }

            body.site_noel & {
                color: $c_noel2;
            }
            body.site_vins & {
                color: $c_vins2;
            }
            body.site_velo & {
                color: $c_velo2;
            }
        }
    }

    h3, .title-h3 {
        @include ft($f_hx, $s_h3, 400, $c_txt, $s_h3+5);
        text-transform: uppercase;
        display: block;
        margin-top: 25px;
        margin-bottom: 15px;
        @include mobile {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        & + .soustitre {
            margin-top: -5px;
            margin-bottom: 25px;
            @include mobile {
                margin-bottom: 20px;
            }
        }
    }

    p {
        @include ft($f_txt, $s_p, 400, $c_txt, $s_p*1.5);
        display: block;
        margin-bottom: 15px;
        @include mobile{
            font-size: calculateRem(14px);
            line-height: calculateRem(18px);
        }

        &.accroche{
            font-size: calculateRem(20px);
            line-height: calculateRem(24px);
            @include mobile{
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
            }
        }
    }

    ul {
        padding: 0;
        margin-bottom: 15px;
        list-style-type: none;
        @include clearfix();
        @include mobile {
            margin-left: 20px;
            margin-right: 20px;
        }

        li {
            @include ft($f_txt, $s_p, 400, $c_txt, $s_p*1.5);
            position: relative;
            padding: 6px 0 6px 20px;
            @include mobile{
                font-size: calculateRem(14px);
                line-height: calculateRem(18px);
            }

            &[style*="text-align: right"] {
                padding: 6px 20px 6px 0;

                &:before {
                    left: auto;
                    right: 0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 15px;
                width: 6px;
                height: 6px;
                background: $c_main;
                border-radius: 50%;

                body.site_noel & {
                    background-color: $c_noel1;
                }
                body.site_vins & {
                    background-color: $c_vins1;
                }
                body.site_velo & {
                    background-color: $c_velo1;
                }
            }
        }
    }

    ol {
        padding: 0;
        margin-bottom: 15px;
        list-style-type: decimal;
        list-style-position: inside;
        @include clearfix();
        @include mobile {
            margin-left: 15px;
            margin-right: 15px;
        }

        li {
            @include ft($f_txt, $s_p, 400, $c_txt, $s_p*1.5);
            position: relative;
            margin-left: 15px;
            padding: 5px 0;
            @include mobile{
                font-size: calculateRem(14px);
                line-height: calculateRem(18px);
            }
        }
    }

    a:not(.btn):not(.basic-link) {
        color: $c_main;
        text-decoration: underline;

        body.site_noel & {
            color: $c_noel1;
        }
        body.site_vins & {
            color: $c_vins1;
        }
        body.site_velo & {
            color: $c_velo1;
        }

        &:hover, &:focus {
            body:not(.no-hover) & {
                color: $c_txt;
            }
        }
    }

    blockquote {
        @include ft($f_txt, $s_p+4, 400, $c_txt, $s_p+13);
        position: relative;
        font-style: italic;
        margin: 35px 0 35px 40px;
        border-left: 0;
        @include mobile {
            margin: 30px 0;
            font-size: calculateRem(16px);
            line-height: calculateRem(22px);
        }

        p {
            @include ft($f_txt, $s_p, 400, $c_txt, $s_p*1.5);
            position: relative;
            font-style: italic;
            @include mobile {
                font-size: calculateRem(16px);
                line-height: calculateRem(22px);
            }

            &:before {
                content: "« ";
            }

            &:after {
                content: " »";
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .btn {
        margin-top: 15px;
        margin-bottom: 20px;
        @include mobile {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    // Gestion iFame
    iframe:not(.instagram-media) {
        width: 100%;
        height: 430px;
        @include mobile {
            height: 100%;
        }
    }

    figure {
        overflow: hidden;
        background: none;

        img {
            margin: 15px auto;
            height: auto;
            @include mobile {
                margin: 10px auto;
            }
        }
    }

}
