// Gestion de la tranche Etape
.nav-step {
    position: sticky;
    -ms-position: sticky;
    top: $header-height;
    background: $c_grey;
    margin-bottom: $content-spacing;
    z-index: 20000;
    display: flex;
    align-items: center;
    height: 80px;
    overflow: hidden;
    @include tablet_landscape {
        height: 60px;
    }
    @include mobile {
        height: 40px;
        top: 20px;
    }

    .title-step-nav {
        @include ft($f_hx, 14px, 500, $c_txt, 20px);
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
        flex: none;
        padding: 0 20px;
        @include mobile {
            font-size: calculateRem(12px);
            padding: 0 10px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -23px;
            width: 1px;
            height: 46px;
            background: $c_main;
            @include tablet_landscape {
                height: 32px;
                margin-top: -16px;
            }
            @include mobile {
                height: 20px;
                margin-top: -10px;
            }

            body.site_noel & {
                background: $c_noel1;
            }
            body.site_vins & {
                background: $c_vins1;
            }
            body.site_velo & {
                background: $c_velo1;
            }
        }
    }

    .wrapper-slider-steps {
        flex: 1;
    }

    ul {
        z-index: 100;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        font-size: 0;
        display: flex;
        align-items: center;

        li {
            width: 54px;
            position: relative;
            @include tablet_landscape {
                width: 45px;
            }
            @include mobile {
                width: 30px;
            }

            a {
                @include ft($f_hx, 35px, 400, $c_grey, 52px);
                -webkit-text-stroke: 1px rgba($c_txt, 0.5);
                display: block;
                text-align: center;
                letter-spacing: 5px;
                width: 54px;
                height: 54px;
                border-radius: 50%;
                padding-left: 2px;
                @include tablet_landscape {
                    width: 45px;
                    height: 45px;
                    font-size: calculateRem(30px);
                    line-height: calculateRem(40px);
                }
                @include mobile {
                    width: 30px;
                    height: 30px;
                    font-size: calculateRem(20px);
                    line-height: calculateRem(27px);
                }

                &:before {
                    content: '';
                    @extend %center;
                    background: $c_white;
                    z-index: -1;
                    border-radius: 50%;
                    opacity: 0;
                    @include addTransition();
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_main;
                        -webkit-text-stroke: 1px $c_main;

                        &:before {
                            opacity: 1;
                        }
                    }
                    body:not(.no-hover).site_noel & {
                        color: $c_noel1;
                        -webkit-text-stroke: 1px $c_noel1;
                    }
                    body:not(.no-hover).site_vins & {
                        color: $c_vins1;
                        -webkit-text-stroke: 1px $c_vins1;
                    }
                    body:not(.no-hover).site_velo & {
                        color: $c_velo1;
                        -webkit-text-stroke: 1px $c_velo1;
                    }
                }

                &.active {
                    color: $c_main;
                    -webkit-text-stroke: 1px $c_main;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        .slick-prev, .slick-next {
            display: none !important;
        }

        .slick-next {
            &:before {
                font-size: 12px;
            }
        }

    }

    .btn-map {
        position: absolute;
        z-index: 200;
        top: 50%;
        right: 20px;
        margin-top: -15px;
        display: none;
        @include tablet_portrait {
            display: block;
        }
        @include mobile {
            right: 10px;
        }
    }

    &:before{
        content: '';
    }
}


// STYLE POUR LES ETAPES ITINERAIRES
.step {
    > .bloc {
        position: relative;
        padding: $content-spacing/2 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: $content-spacing/2;
        }
    }


    .title {
        @include ft($f_spe, 60px, 400, $c_main, 40px);
        position: relative;
        display: block;
        margin: 40px 0 0 0;
        /* #CHANGE COLOR THEME */
        .site_noel & {
            color: $c_noel2;
        }

        .site_vins & {
            color: $c_vins2;
        }
        .site_velo & {
            color: $c_velo2;
        }

        @include mobile {
            font-size: calculateRem(50px);
            line-height: calculateRem(30px);
        }
    }

    > .content-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
        max-width: 100%;
    }

    .bloc-image {
        figcaption {
            display: none;
        }
    }

    .bloc-diaporama {
        margin: 0;
        @include min-desktop {
            margin: 0 30px 0 0;
        }

        figure {
            position: relative;
        }
    }

    .bloc-relation {
        .txt-intro {
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }

            .btn {
                position: relative;
                margin-top: 10px;
            }
        }

        .th-slider-wrapper {
            .no-hover & {
                margin-left: -40px;
                @include tablet_portrait{
                    margin-right: -40px;
                }
                @include mobile{
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }
        }
    }
}