.bloc-relation {
    .txt-intro{
        position: relative;
        margin-bottom: 30px;
        @include clearfix();
        @include tablet_portrait{
            margin-bottom: 20px;
        }
        .bloc-texte{
            max-width: calc(100% - 250px);
            @include tablet_portrait{
                max-width: 100%;
            }
        }
        .btn{
            position: absolute;
            right: 0;
            bottom: 0;
            @include tablet_portrait{
                position: relative;
                margin-top: 20px;
                float: right;
            }
            @include mobile{
                float: none;
                margin-top: 15px;
            }
        }
    }

}