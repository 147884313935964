// Fonts Barlow Semi Condensed
// ==========================================================================
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.woff2') format('woff2'),
    url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


// Fonts Arial Narrow
// ==========================================================================
@font-face {
    font-family: 'Arial Narrow';
    src: url('../fonts/ArialNarrow/ArialNarrow-BoldItalic.woff2') format('woff2'),
    url('../fonts/ArialNarrow/ArialNarrow-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Arial Narrow';
    src: url('../fonts/ArialNarrow/ArialNarrow-Bold.woff2') format('woff2'),
    url('../fonts/ArialNarrow/ArialNarrow-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Arial Narrow';
    src: url('../fonts/ArialNarrow/ArialNarrow-Italic.woff2') format('woff2'),
    url('../fonts/ArialNarrow/ArialNarrow-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Arial Narrow';
    src: url('../fonts/ArialNarrow/ArialNarrow.woff2') format('woff2'),
    url('../fonts/ArialNarrow/ArialNarrow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Fonts Just Lovely
// ==========================================================================
@font-face {
    font-family: 'JustLovely';
    src: url('../fonts/JustLovely/JustLovely.woff2') format('woff2'),
    url('../fonts/JustLovely/JustLovely.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


// Fonts
// ==========================================================================
$f_hx: 'Barlow Semi Condensed', Sans-Serif;
$f_txt: 'Arial Narrow', Sans-Serif;
$f_spe: 'JustLovely', Sans-Serif;

// Colors
// ==========================================================================
$c_main: #E31936;
$c_sub: #E31936;
$c_green: #4F9714;
$c_grey: #F1F1F1;
$c_grey-dark: #ADADAD;
$c_filet: #808080;
$c_txt: #000000;
$c_light: #F5F5F5;
$c_dark: #000000;
$c_white: #FFFFFF;
$c_border: #BABABA;

$c_noel1: #89155F; // Violet
$c_noel2: #C49A4A; // Or
$c_noel3: #491D39; // Violet foncé

$c_vins1: #73AD1C; // Vert
$c_vins2: #D29A00; // Or

$c_velo1: #D74537;
$c_velo2: #DD7D73;

// Sizes
// ==========================================================================
$s_h1: 50px;
$s_h2: 40px;
$s_h3: 20px;																		
$s_h4: 18px;																			
$s_p: 16px;
$s_link: 18px;


// Width / Height / Spacing
// ==========================================================================
$max-width: 1200px;
$border-gutter: 40px;
$content-spacing: 40px;
$content-gutter: 40px;
$header-height: 70px;
$copyright-height: 60px;
$grid-extra-small: 780px;
$grid-small: 960px;
$grid-medium: 1120px;
$max-width-large: 1600px - $border-gutter - $border-gutter;


// Breakpoints
// ==========================================================================
$breakpoint_wd: 1600px;
$breakpoint_desktop: 1280px;
$breakpoint_paysage: 1024px; // taille ipad
$breakpoint_portrait: 980px;
$breakpoint_mo: 599px;
$breakpoint_mo_small: 320px;