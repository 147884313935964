.page-lei{
    @include mobile{
        overflow-x: hidden;
    }
}
// CONTENU SIT
.wrapper-site-lei{
	position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
	z-index: 1000;
    margin-top: -$content-spacing/2;
    padding-bottom: $content-spacing;
    @include clearfix();
    @include mobile{
        padding-bottom: $content-spacing/2;
    }

    & ~ .bloc-relation{
        padding-top: $content-spacing/2;
        padding-bottom: $content-spacing;
        @include mobile{
            padding-bottom: $content-spacing/2;
        }
    }

    // Left
    .lei-content{
        order: 1;
        width: calc(100% - 375px);
        padding-right: 20px;
        @include tablet_landscape{
            width: calc(100% - 345px);
        }
        @include tablet_portrait{
            width: 100%;
            order: 2;
            padding: 0;
        }
    }

    // Right
    .bloc-aside{
        order: 2;
        width: 375px;
        margin-top: -65px;
        position: sticky;
        position: -webkit-sticky;
        top: $header-height+20px;
        @include tablet_landscape{
            width: 345px;
        }
        @include tablet_portrait{
            position: relative;
            top: 0;
            order: 1;
            width: 100%;
            margin: 0 0 20px 0;
        }
        @include mobile{
            margin: 20px 0;
        }
    }


	.wrapper-bloc-lei-events{
		.th-slider-wrapper{
            margin-top: 20px;
            .no-hover & {
                margin-left: -40px;
                @include tablet_portrait{
                    margin-right: -40px;
                }
                @include mobile{
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }
        }
	}
}

// Barre fixe avec Note de la page (sur mobile)
.fixed-bar{
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 80px;
	padding: 0 40px;
	z-index: 10000;
    background: $c_white;
	box-shadow: -5px 0 10px rgba($c_dark,0.2);
    display: none;
	@include tablet_portrait{
		display: flex;
        align-items: center;
	}
    @include mobile{
        padding: 0 20px;
        height: 60px;
        bottom: 60px;
    }
}

.menu-page-lei{
    ~#overlays{
        #overlay-map{
            .data-bottom{
                display: none;
            }
        }
    }
}