#footer {
    position: relative;
    clear: both;
    margin-top: $content-spacing;
    padding: 0 40px 40px 40px;
    @include mobile{
        padding: 0 20px 20px 20px;
        padding-bottom: 80px;
        margin-top: $content-spacing/2;
    }

    &:before {
        content: '';
        border-top: 1px dashed rgba($c_dark, 0.3);
        position: absolute;
        left: 40px;
        right: 40px;
        width: calc(100% - 80px);
        @include mobile{
            left: 20px;
            right: 20px;
            width: calc(100% - 40px);
        }
    }

    &.small-footer {
        &:before {
            content: none;
        }

        .wrapper-footer-bottom {
            padding: 20px 0;
            @include min-tablet_portrait {
                padding: 40px 0;
            }
        }

        .logo-france {
            display: none;
        }
    }

    .footer-top {
        position: relative;
        padding-top: $content-spacing/2;
        display: flex;
        @include desktop{
            margin-left: 0;
            margin-right: 0;
        }
        @include mobile{
            flex-wrap: wrap;
            padding-bottom: $content-spacing/2;
        }

        > * {
            flex: 1;
            @include mobile{
                flex: none;
                width: 100%;
            }
        }
    }

    .footer-instagram{
        @include desktop{
            margin-left: 0;
            margin-right: 0;
        }
    }

    .wrapper-footer {
        background: $c_grey;
    }

    .wrapper-footer-bottom {
        padding: 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tablet_portrait{
            flex-wrap: wrap;
        }
        @include mobile{
            padding: 20px 20px 40px 20px;
        }
        @include mobile_small{
            padding: 20px 0 40px 0;
        }
    }

    figure {
        background: none;
    }

    .credits, figcaption {
        display: none;
    }

    .footer-title {
        @include ft($f_hx, 30px, 400, $c_txt, 30px);
        text-transform: uppercase;
        display: block;
        margin-bottom: 20px;
        @include tablet_portrait{
            font-size: calculateRem(25px);
            line-height: calculateRem(25px);
        }
        @include mobile{
            font-size: calculateRem(30px);
            line-height: calculateRem(30px);
        }
    }

    p {
        @include ft($f_txt, $s_p, 400, $c_txt, 20px);
    }
}

// Newsletter
// ==========================================================================
.newsletter {
    position: relative;
    padding: 20px 0;
    order: 2;

    .bloc-form {
        margin-top: 20px;
        padding: 0;
        @include tablet_portrait{
            margin-left: 0;
            margin-right: 0;
        }
        .frm_forms {
            form {
                .frm_form_fields{
                    .frm_html_container{
                        padding-bottom: 0;
                    }
                    .frm_primary_label{
                        display: none;
                    }
                    .frm_top_container {
                        input:not([type="checkbox"]):not([type="radio"]) {
                            @include ft($f_txt, $s_p, 400, $c_txt, 24px);
                            @extend %appearance;
                            height: 48px;
                            padding: 11px 115px 11px 20px;
                            border: 1px solid $c_border;
                            background: none;
                            -webkit-appearance: none;
                            box-shadow: none;
                            text-overflow: ellipsis;
                            @include addTransition();
                            &::placeholder {
                                color: $c_txt;
                            }

                            &:hover, &:focus {
                                body:not(.no-hover) & {
                                    border-color: $c_main;
                                }

                                /* #CHANGE COLOR THEME */
                                body.site_noel:not(.no-hover) & {
                                    border-color: $c_noel2;
                                }
                                body.site_vins:not(.no-hover) & {
                                    border-color: $c_vins2;
                                }
                                body.site_velo:not(.no-hover) & {
                                    border-color: $c_velo2;
                                }
                            }
                        }
                    }

                    .frm_submit {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 105px;
                        margin: 0;
                        @include tablet_portrait{
                            right: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: 12px;
                            bottom: 12px;
                            left: 1px;
                            width: 1px;
                            background: $c_filet;
                        }

                        button {
                            @include ft($f_hx, 14px, 500, $c_main, 20px);
                            @extend %appearance;
                            text-transform: uppercase;
                            padding: 14px 20px;
                            background: none;
                            width: 100%;
                            min-width: 100px;
                            /* #CHANGE COLOR THEME */
                            .site_noel & {
                                color: $c_noel2;
                            }
                            .site_vins & {
                                color: $c_vins1;
                            }
                            .site_velo & {
                                color: $c_velo1;
                            }

                            @include addTransition();

                            &:hover, &:focus {
                                body:not(.no-hover) & {
                                    color: $c_txt;
                                    cursor: pointer;
                                    background: none;
                                }
                            }
                        }
                    }

                    .horizontal_radio{
                        display: block !important;
                    }
                    .frm_opt_container{
                        margin: 0;
                        .frm_checkbox {
                            position: relative;
                            text-align: left;
                            width: 100%;
                            label {
                                display: block !important;
                                @include ft($f_txt, 12px, 400, $c_txt, 20px);
                                padding-left: 22px;
                                padding-bottom: 0;
                                position: relative;
                                user-select: none;
                                margin-bottom: 0;
                                @include addTransition();
                                &:hover {
                                    cursor: pointer;
                                    color: $c_main;
                                    /* #CHANGE COLOR THEME */
                                    .site_noel & {
                                        color: $c_noel2;
                                    }
                                    .site_vins & {
                                        color: $c_vins2;
                                    }
                                    .site_velo & {
                                        color: $c_velo2;
                                    }
                                }

                                input {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;

                                    &:focus {
                                        + span {
                                            border-color: $c_main;
                                            /* #CHANGE COLOR THEME */
                                            .site_noel & {
                                                border-color: $c_noel2;
                                            }
                                            .site_vins & {
                                                border-color: $c_vins2;
                                            }
                                            .site_velo & {
                                                border-color: $c_velo2;
                                            }
                                        }

                                    }

                                    & + span {
                                        border: 1px solid $c_border;
                                        border-radius: 50%;
                                        background: none;
                                        position: absolute;
                                        top: 2px;
                                        left: 0;
                                        width: 14px;
                                        height: 14px;
                                    }
                                }

                                input[type="checkbox"] + span:before {
                                    content: none !important;
                                }

                                input[type="checkbox"]:not(:checked) + span:after {
                                    content: '';
                                    left: 2px;
                                    top: -2px;
                                    opacity: 0;
                                    transform: scale(0);
                                    transition: 0.2s ease;
                                }

                                input[type="checkbox"]:checked + span:after {
                                    content: "\e901";
                                    @include font();
                                    text-shadow: 1px 1px 0 $c_white, 0px -2px 0 $c_white;
                                    position: absolute;
                                    top: -2px;
                                    left: 3px;
                                    right: 0;
                                    font-size: 13px;
                                    cursor: pointer;
                                    color: $c_green;
                                    transition: all .2s;
                                    background: none;
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & + .social-network{
        padding-right: 40px;
        ul{
            position: relative;
            bottom: 0;
            margin-top: 40px;
            justify-content: flex-start;
            li{
                a{
                    padding-right: 35px;
                    padding-left: 0;
                }
            }
        }
    }
}

// BLoc - Réseaux sociaux
// ==========================================================================
.social-network {
    position: relative;
    padding: 20px 0;

    p {
        max-width: 600px;

        span {
            @include ft($f_spe, 40px, 400, $c_main, 20px);
            margin-left: 5px;

            body.site_noel & {
                color: $c_noel2;
            }
            body.site_vins & {
                color: $c_vins2;
            }
            body.site_velo & {
                color: $c_velo2;
            }
        }
    }

    ul {
        position: absolute;
        right: 0;
        bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include mobile{
            position: relative;
            bottom: 0;
            margin-top: 40px;
            justify-content: flex-start;
        }

        li {
            a {
                display: block;
                padding: 0 0 0 35px;
                color: $c_main;
                font-size: 20px;
                @include addTransition();
                @include mobile{
                    padding-right: 35px;
                    padding-left: 0;
                }
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    color: $c_noel2;
                }
                .site_vins & {
                    color: $c_vins1;
                }
                .site_velo & {
                    color: $c_velo1;
                }

                @include addTransition();

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_txt;
                    }
                }

                span {
                    display: block;
                }
            }
        }
    }
}

// Footer - Menu
// ==========================================================================
.footer-menu {
    position: relative;
    @include clearfix();
    @include mobile{
        padding: 20px;
    }
    @include mobile_small{
        padding: 20px 0;
    }

    > div {
        position: relative;
        padding: 40px 40px 25px 0;
        width: 20%;
        float: left;
        @include tablet_landscape {
            width: 25%;
        }
        @include tablet_portrait {
            width: 50%;
        }
        @include mobile{
            width: 100%;
            padding: 20px 0;
        }

        &:nth-child(3) {
            @include tablet_portrait {
                clear: both;
            }
        }

        &.open{
            .title-accordeon{
                &:after{
                    content: "\e95e";
                }
            }
        }

        .title-accordeon {
            @include ft($f_hx, 20px, 300, $c_main, 20px);
            @extend %appearance;
            background: none;
            padding: 0;
            position: relative;
            width: 100%;
            text-align: left;
            text-transform: uppercase;
            display: block;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }
            &:after{
                content: "\e95f";
                @include font();
                position: absolute;
                font-size: 20px;
                top: 50%;
                right: 0;
                margin-top: -10px;
                display: none;
                @include mobile{
                    display: block;
                }
            }
        }

        ul {
            display: block;
            @include mobile{
                max-height: 0;
                overflow: hidden;
                font-size: 0;
            }
            &.anim {
                @include addTransition();
            }


            li {
                &:first-child{
                    margin-top: 15px;
                }
                a {
                    @include ft($f_txt, 14px, 400, $c_txt, 20px);
                    padding: 7px 0;
                    display: block;
                    @include addTransition();

                    &:hover, &:focus {
                        body:not(.no-hover) & {
                            color: $c_main;
                        }

                        /* #CHANGE COLOR THEME */
                        body.site_noel:not(.no-hover) & {
                            color: $c_noel2;
                        }
                        body.site_vins:not(.no-hover) & {
                            color: $c_vins2;
                        }
                        body.site_velo:not(.no-hover) & {
                            color: $c_velo2;
                        }
                    }
                }
            }
        }
    }

    .footer-desktop-logos {
        position: relative;
        float: right;
        width: 20%;
        padding-left: 0;
        display: block;
        @include tablet_landscape{
            width: 100%;
            float: none;
            clear: both;
            padding: 0;
        }
        @include mobile{
            padding-top: 20px;
            margin-bottom: -20px;
        }

        &.has-logo-supp {
            display: block;
        }

        > a {
            display: block;
        }

        figure {
            display: block;
            img {
                height: auto;
                margin: auto;
                margin-bottom: 20px;
            }
        }

        .logos-repeat {
            font-size: 0;
            text-align: center;
            .item {
                display: inline-block;
                vertical-align: middle;
                margin: 10px;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }

                figure {
                    img {
                        height: auto;
                        width: auto;
                        margin: auto;
                        min-width: 40px;
                        max-height: 75px;
                        margin-bottom: 0;
                        @include wide {
                            min-width: 80px;
                            max-height: 100px;
                        }
                        @include mobile{
                            min-width: 40px;
                            max-height: 60px;
                        }
                    }
                }
            }
        }
    }
}

// Bloc Instagram
// ==========================================================================
.footer-instagram {
    position: relative;
    margin-bottom: 40px;
    @include mobile{
        margin-bottom: 0;
    }

    & + .wrapper-footer {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            width: 100%;
            height: 150px;
            background: $c_grey;
        }
    }

    .title {
        @include ft($f_hx, 14px, 700, $c_txt, 20px);
        position: relative;
        top: 35px;
        text-transform: uppercase;
        display: inline-block;
        @include tablet_landscape{
            top: 0;
        }
        @include mobile{
            margin-bottom: 15px;
        }

        &:before {
            content: "\e905";
            @include font();
            position: relative;
            top: -2px;
            float: left;
            color: $c_main;
            margin-right: 10px;
            font-size: 26px;
            line-height: 26px;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }
        }

        a {
            margin-left: 4px;
            color: $c_main;
            text-transform: none;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }

            @include addTransition();

            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: $c_txt;
                }
            }
        }
    }

    .wrapper-img {
        display: flex;
        align-items: flex-end;
        margin-left: -10px;
        margin-right: -10px;
        @include clearfix();
        @include mobile{
            flex-wrap: wrap;
            align-items: flex-start;
        }

        > * {
            position: relative;
            z-index: 10;
            overflow: hidden;
            display: block;
            float: left;
            width: 22%;
            border: 10px solid $c_white;
            @include addTransition();
            @include mobile{
                width: 35%;
            }
            @include mobile_small{
                border-width: 5px;
            }

            &:nth-child(n+7) {
                display: none;
            }

            //Small
            &:nth-child(2), &:nth-child(3), &:nth-child(5) {
                width: 17%;
                @include mobile{
                    width: 28%;
                }
                @include mobile_small{
                    width: 31%;
                }
            }

            &:nth-child(1){
                @include mobile{
                    margin-left: 10px;
                }
            }

            &:nth-child(2) {
                margin-bottom: calc(4% + 50px);
                margin-left: -40px;
                @include mobile{
                    margin-left: -20px;
                    margin-bottom: 0;
                    margin-top: -10px;
                }
            }

            &:nth-child(3), &:nth-child(5) {
                margin-left: -40px;
                margin-bottom: -40px;
            }

            &:nth-child(4), &:nth-child(6) {
                margin-left: -40px;
            }

            &:nth-child(3){
                @include mobile{
                    margin-left: -20px;
                    margin-bottom: 0;
                    margin-top: 30px;
                }
            }
            &:nth-child(4){
                @include mobile{
                    margin: 0;
                    margin-top: -10px;
                }
            }
            &:nth-child(5){
                @include mobile{
                    margin-left: -20px;
                    margin-bottom: 0;
                    margin-top: -40px;
                }
            }
            &:nth-child(6){
                @include mobile{
                    margin-top: -10px;
                    right: -10px;
                }
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    border-color: $c_main;
                }
                body:not(.no-hover).site_noel & {
                    border-color: $c_noel1;
                }
                body:not(.no-hover).site_vins & {
                    border-color: $c_vins1;
                }
                body:not(.no-hover).site_velo & {
                    border-color: $c_velo1;
                }
            }

            figure {
                @include aspect-ratio(1, 1);
                overflow: visible;
                background: none;

                img {
                    @extend %center;
                }
            }
        }
    }
}

// Footer Bottom - Mentions légales
// ==========================================================================
.footer-bottom {
    background-color: $c_grey;
    display: flex;
    align-items: center;
    @include tablet_portrait{
        width: 100%;
        order: 2;
        margin-top: 40px;
    }
    @include mobile{
        display: block;
        margin-top: 20px;
    }

    .img {
        flex: none;
        width: 44px;
        @include mobile{
            display: flex;
            align-items: center;
            width: 100%;
            margin: auto;
            margin-bottom: 10px;
        }

        > * {
            position: relative;
            @include mobile{
                padding: 0 30px;
                & + * {
                    &:before{
                        content: '';
                        width: 1px;
                        height: 20px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -10px;
                        background: $c_border;
                    }
                }
            }
        }
        a{
            display: block;
        }
        figure {
            img {
                height: auto;
                @include mobile{
                    width: 65px;
                }
            }
        }
    }

    .txt {
        margin-left: 15px;
        @include mobile{
            margin-left:0;
        }

        .copyright {
            @include ft($f_hx, 12px, 300, $c_txt, 20px);
            text-transform: uppercase;
            display: block;
            @include mobile{
                text-align: center;
                margin-bottom: 5px;
            }
        }
    }

    ul {
        font-size: 0;
        @include mobile{
            text-align: center;
        }

        li {
            display: inline-block;
            vertical-align: middle;

            &:last-child {
                a {
                    &:after {
                        content: none;
                    }
                }
            }

            a {
                @include ft($f_txt, 12px, 400, $c_filet, 20px);
                position: relative;
                display: block;
                @include mobile{
                    padding: 2px 0;
                }

                &:after {
                    content: '|';
                    margin: 0 5px;
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// Footer - Sélecteur de langues
// ==========================================================================
.footer-lang {
    position: relative;
    z-index: 10;
    flex: none;
    width: 335px;
    @include tablet_portrait{
        width: 100%;
    }

    > span {
        @include ft($f_txt, 14px, 700, $c_txt, 20px);
        display: block;
        margin-bottom: 10px;
    }

    .wrapper-select {
        position: relative;
        height: 48px;
        background: $c_white;

        &:after {
            content: "\e90f";
            @include font();
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -7px;
            color: rgba($c_txt, 0.5);
            font-size: 14px;
            z-index: 100000;
        }

        > select {
            @include ft($f_txt, 12px, 400, $c_txt, 20px);
            @extend %appearance;
            width: 100%;
            height: 48px;
            border: none;
            padding: 14px 25px;
        }
    }
}

// Footer - Bouton vers iti vélo
// ==========================================================================
.iti-global-btn{
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 66px;
    height: 66px;
    z-index: 500000;
    box-shadow: 0 10px 25px rgba(0,0,0,.4);
    border-radius:50px;
    cursor: pointer;
    @include addTransition();
    @include mobile{
        right: 20px;
    }
    background-color: $c_sub;
    body.site_noel & {
        background-color: $c_noel2;
    }
    body.site_vins & {
        background-color: $c_vins2;
    }
    body.site_velo & {
        background-color: $c_velo2;
    }

    body.has-wmbth & {
        bottom: 115px;
    }

    &:hover{
        body:not(.no-hover) & {
            background-color: $c_main;
        }
        body.site_vins:not(.no-hover) & {
            background-color: $c_noel1;
        }
        body.site_noel:not(.no-hover) & {
            background-color: $c_vins1;
        }
        body.site_velo:not(.no-hover) & {
            background-color: $c_velo1;
        }
    }

    a{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: white;
        font-size: 20px;
        line-height: 1;
    }
}


// Strate - Strate footer Itinéraire
// ==========================================================================
.strate-iti{
    padding: 40px 0;
    .wrapper{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 584px;
        @include tablet_portrait{
            display: block;
        }
        &:before{
            content: '';
            position: absolute;
            top: 50px;
            bottom: 50px;
            left: 0;
            right: 0;
            z-index: -1;
            background-color: $c_main;
            body.site_noel & {
                background-color: $c_noel1;
            }
            body.site_vins & {
                background-color: $c_vins1;
            }
            body.site_velo & {
                background-color: $c_velo1;
            }
        }

        .img{
            flex-shrink: 0;
            height: 100%;
            width: auto;
            padding-left: 80px;
            @include tablet_portrait{
                display: none;
            }
            figure, img{
                height: 100%;
                width: auto;
                background-color: transparent;
            }
        }
        .img_mob{
            position: relative;
            display: none;
            margin: 0 auto 20px;
            max-width: 400px;
            padding: 0 40px;
            @include tablet_portrait{
                display: block;
            }
            @include mobile{
                margin: 0 auto 10px;
                padding: 0 20px;
            }
            figure, img{
                background-color: transparent;
                height: auto;
                width: 100%;
            }
        }

        .content{
            padding: 70px 40px;
            @include tablet_portrait{
                padding: 0 40px 70px;
            }
            @include mobile{
                padding: 0 20px 70px;
            }
            &:first-child:last-child{
                max-width: 800px;
                margin: 0 auto;
                @include tablet_portrait{
                    padding: 70px 40px;
                }
            }

            .title{
                @include ft($f_spe, 50px, 400, white, 50px);
                color: white;
            }
            .surtitre{
                @include ft($f_hx, 40px, 700, white, 40px);
                text-transform: uppercase;
                color: white;
            }
            p{
                color: white;
                a{
                    text-decoration: underline;
                    color: white;
                    @include addTransition();
                    &:hover{
                        body:not(.no-hover) & {
                            opacity: .7;
                        }
                    }
                }
            }

            form{
                margin-top: 20px;
                label{
                    display: block;
                    width: 100%;
                    color: white;
                    margin-bottom: 4px;
                }
                input{
                    @extend %appearance;
                    box-sizing: border-box;
                    width: 100%;
                    @include ft($f_hx, 10px, 500, $c_txt, 12px);
                    text-transform: uppercase;
                    padding: 18px 20px;
                    border: 1px solid $c_filet;
                    &::placeholder{
                        @include ft($f_hx, 10px, 500, $c_txt, 12px);
                        text-transform: uppercase;
                    }
                    &:not(:last-of-type){
                        margin-bottom: 10px;
                    }
                }
                .btn.submit{
                    background-color: transparent;
                    width: 100%;
                    text-align: center;
                    margin-top: 30px;
                    cursor: pointer;
                    &:hover{
                        body:not(.no-hover) & {
                            border-color: $c_txt;
                        }
                    }
                }
            }
        }
    }
}