.bloc-thematiques {
    .bloc-texte{
        p{
            max-width: 600px;
        }
    }
	.wrapper-thematiques {
		padding: 35px 0 0;
		width: 100%;
        @include tablet_portrait{
            margin-left: -40px;
            margin-right: -40px;
            width: calc(100% + 80px);
        }
        @include mobile{
            padding: 20px 0 0;
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
		.listing-item {
			position: relative;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
            @include tablet_portrait{
                scroll-snap-type: both mandatory;
                scroll-padding: 40px;
                padding: 0 40px;
            }
			@include mobile {
                scroll-padding: 20px;
                padding: 0 20px;
				> div {
					> * {
						scroll-snap-align: start;
					}
				}
			}

			> div {
				position: relative;
				overflow: visible;
                display: flex;
                justify-content: space-between;
				flex-wrap: wrap;
                @include tablet_portrait{
                    white-space: nowrap;
                    flex-wrap: wrap;
                    display: block;
                }
				.item {
					position: relative;
                    white-space: normal;
					box-sizing: border-box;
					display: inline-block;
					vertical-align: top;
                    width: calc(25% - 15px);
					max-width: 100%;
					margin-right: 15px;
					margin-bottom: 15px;
					overflow: hidden;
					scroll-snap-align: start;
                    @include tablet_portrait{
                        width: 300px;
                        margin-right: 20px;
                        &:last-child{
                            margin-right: 40px;
                        }
                    }
                    @include mobile{
                        width: 280px;
                        &:last-child{
                            margin-right: 20px;
                        }
                    }
                    &:hover, &:focus {
                        body:not(.no-hover) & {
                            cursor: pointer;
                            figure {
                                cursor: pointer;
                                box-shadow: 0 5px 15px 0 rgba($c_dark, 0.2);
                            }
                            .btn {
                                background: $c_white;
                                color: $c_txt;
                            }
                        }
                    }
					h3 {
						@include ft($f_spe, 40px, 400, $c_white, 30px);
						margin-bottom: 10px;
                        @include wide{
                            font-size: calculateRem(60px);
                            line-height: calculateRem(50px);
                        }
					}
					p {
						@include ft('', 14px, '', $c_white, 20px);
						margin-bottom: 13px;
					}
                    .btn{
                        width: 100%;
                        margin-top: 10px;
                    }
					figure {
						position: relative;
                        background: none;
                        @include aspect-ratio(285,400);
						@include addTransition();
						&:after {
							content: '';
							border-radius: 6px;
							@extend .gradient-bottom;
							@include min-desktop {
								border-radius: 10px;
							}

						}
						img {
							@extend %center;
						}
						.credits{
							display: none;
						}
					}
					> div {
						position: absolute;
						left: 20px;
						right: 20px;
						bottom: 20px;
						width: calc(100% - 40px);
					}
				}
			}
		}
	}

	&.m_mosaique{
		.wrapper-thematiques{
			.listing-item{
				overflow: visible;
				>div{
					@include tablet_portrait{
						white-space: normal;
						flex-wrap: wrap;
						display: flex;
						margin-right: -15px;
					}

					.item{
						@include tablet_portrait{
							width: calc(33.3333% - 15px);
							margin-right: 15px;
						}
						@media screen and (max-width: 720px){
							width: calc(50% - 10px);
							margin-right: 10px;
							margin-bottom: 10px;
						}

						h3{
							@include mobile{
								font-size: 30px;
								line-height: 25px;
							}
						}
						p{
							@include mobile{
								display: none;
							}
						}
						.btn{
							@include mobile{
								padding: 11px;
							}
						}
					}
				}
			}
		}
	}
}