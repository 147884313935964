.bloc-incontournables {
    overflow: hidden;

    & + .bloc-red {
        margin-top: 0;
    }

    .bloc-texte {
        & + .wrapper-relative {
            margin-top: 20px;
        }
        margin-bottom: 40px;
        @include mobile{
            margin-bottom: 20px;
        }
    }

    .wrapper-relative {
        position: relative;
    }

    .slider-nav {
        display: none;
        @media screen and (min-width: 700px) {
            display: block;
        }

        .slick-arrow {
            position: absolute;
            top: calc(50% - 5px);
            font-size: 36px;
            color: $c_main;
            margin-top: -18px;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }
            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }

            &:hover, &:focus, &:active {
                body:not(.no-hover) & {
                    color: $c_txt;
                    /* #CHANGE COLOR THEME */
                    .site_noel & {
                        color: darken($c_noel2, 15%);
                    }
                    .site_vins & {
                        color: darken($c_vins2, 15%);
                    }
                    .site_velo & {
                        color: darken($c_velo2, 15%);
                    }
                }
            }

            &.prev {
                left: 0;

                &:before {
                    content: "\e912";
                    @include font();
                }
            }

            &.next {
                right: 0;

                &:before {
                    content: "\e913";
                    @include font();
                }
            }
        }
    }

    .slick-dots {
        position: relative;
        left: 0;
        bottom: 0;
        margin-top: 15px;
        justify-content: center;

        li {
            margin: 0 3px;
            &.slick-active {
                button {
                    background: $c_dark;
                }
            }

            button {
                background: rgba($c_dark, 0.3);
            }
        }
    }

    // Carousel
    .listing-item {
        @include mobile {
            margin-left: -5px;
            margin-right: -5px;
        }

        .slick-list {
            overflow: visible;
        }

        &:not(.slick-initialized) {
            .bloc-card-incontournable {
                float: left;
                display: none;
                @include mobile {
                    width: calc(50% - 10px);
                }

                &:first-child {
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        a {
            &:hover, &:focus {
                body:not(.no-hover) & {
                    div {
                        .btn {
                            color: $c_txt;
                            background: $c_white;
                        }
                    }
                }
            }
        }

        .bloc-card-incontournable {
            display: inline-block;
            vertical-align: top;
            width: 370px;
            box-sizing: border-box;
            transform: translate3d(0, 0, 0);
            position: relative;
            margin-right: 20px;
            @include addTransition();
            @include tablet_landscape {
                width: 300px;
            }
            @include mobile {
                width: 100%;
                height: 100%;
                padding: 5px;
                margin: 0;
                float: left;
            }

            figure, .no-photo {
                @include aspect-ratio(370, 500);
            }

            figure {
                background: none;

                &:after {
                    content: '';
                    @extend .gradient-bottom;
                }

                figcaption {
                    display: none;
                }

                img {
                    @extend %center;
                }
            }

            .credits {
                @include mobile {
                    display: none;
                }
            }

            .caption-txt {
                position: absolute;
                left: 40px;
                right: 40px;
                bottom: 40px;
                text-align: center;
                white-space: normal;
                width: calc(100% - 80px);
                @include mobile {
                    left: 20px;
                    right: 20px;
                    bottom: 20px;
                    width: calc(100% - 40px);
                }

                .title-incontournable {
                    @include ft($f_spe, 50px, 400, $c_white, 40px);
                    margin-bottom: 25px;
                    @include mobile {
                        font-size: calculateRem(30px);
                        line-height: calculateRem(24px);
                        margin-bottom: 10px;
                    }
                }

                .btn {
                    @include mobile {
                        font-size: calculateRem(12px);
                        line-height: calculateRem(18px);
                        padding: 6px 10px 10px 10px;
                    }
                }

            }
        }
    }
}