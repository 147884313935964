// STYLES FORMULAIRES - DEFAULT SETTINGS
// ==========================================================================
.bloc-form {

    // FORMULAIRE FORMIDABLE
    // ==========================================================================
    form {
        position: relative;
        height: 100%;
        .frm_error {
            position: relative;
            display: block;
            margin-top: 5px;
            font-size: 12px;
            color: $c_main;
        }
        .frm_error_style, legend, .frm_none_container label, .frm_form_title {
            display: none !important;
        }

        .frm_description {
            @include ft($f_txt, 12px, 400, rgba($c_txt, 0.7), 19px);
            margin-top: 10px;
            font-style: italic;
            a {
                text-decoration: underline;
            }
        }
        .frm_hidden_container {
            > .frm_primary_label {
                display: none;
            }
        }

        .frm_required {
            color: $c_main;
        }

        /* MISE EN PAGE */
        .form-field {
            position: relative;
            padding-bottom: 20px;
            &.frm_section_heading {
                margin-top: 30px;
                margin-bottom: 10px;
            }
        }

    }

    .frm_message {
        text-align: center;
        padding: 20px;
        background: $c_main;
        margin: 25px 0;
        @media screen and (max-width: 800px){
            margin: 20px 0;
        }
        p {
            color: $c_white;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    /* Gestion des labels */
    label, .frm_primary_label {
        @include ft($f_txt, 14px, 700, $c_txt, 20px);
        position: relative;
        box-sizing: border-box;
        display: block;
        clear: both;
        text-indent: 0;
        padding: 0 0 5px 0;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        & + .frm_opt_container{
            margin-top: 10px;
        }
        a {
            color: $c_main;
            text-decoration: underline;
            &:hover, &:focus {
                body:not(.no-hover) & {
                    color: $c_txt;
                }
            }

            body.site_noel & {
                color: $c_noel2;
            }
            body.site_vins & {
                color: $c_vins2;
            }
            body.site_velo & {
                color: $c_velo2;
            }
        }
    }

    /* Input, Textarea, Select */
    input, select, textarea {
        @include ft($f_txt, 14px, 400, $c_txt, 20px);
        @extend %appearance;
        box-sizing: border-box;
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 13px 20px;
        background: $c_white;
        border: 1px solid $c_border;
        text-overflow: ellipsis;
        @include addTransition();
        &::placeholder {
            color: $c_txt;
        }
        &:hover, &:focus {
            body:not(.no-hover) & {
                border-color: $c_main;
            }
            body:not(.no-hover).site_noel & {
                border-color: $c_noel1;
            }
            body:not(.no-hover).site_vins & {
                border-color: $c_vins1;
            }
            body:not(.no-hover).site_velo & {
                border-color: $c_velo1;
            }
        }
    }

    input{
        &[type="date"]::-webkit-inner-spin-button {
            display: none;
        }
        &[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: $c_txt;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 48px $c_white inset !important;
    }

    textarea {
        height: 175px;
    }

    /* Style for Submit Button */
    .frm_submit {
        text-align: center;
        @include clearfix();
        button {
            @extend .btn;
            @extend %appearance;
            background: none;
            position: relative;
            z-index: 10;
            user-select: none;
            padding: 10px 20px 12px;
            margin: 0;
            min-width: 140px;
            &:focus {
                outline: none;
            }

            &.frm_prev_page {
                float: left;
            }
            &.frm_final_submit {
                float: right;
            }
        }
    }


    /* Style for Radio & Checkbox */
    .vertical_radio{
        .frm_opt_container{
            label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch) {
                margin: 0;
                @include mobile{
                    padding-bottom: 5px;
                }
            }
        }
    }

    .frm_opt_container {
        font-size: 0;
        margin-bottom: -10px;
        @include mobile {
            display: block;
        }

        & + .frm_error{
            margin-top: 15px;
        }

        input {
            display: none !important;
        }

        label:not(.star-rating):not(.frm_hidden):not(.star-rating):not(.frm_switch) {
            @include ft($f_txt, 14px, 400, $c_txt, 20px);
            padding-left: 25px;
            margin: 0 0 15px 0;
            white-space: normal;
            overflow: visible;
            text-indent: initial;
            @include addTransition();
            @include mobile{
                display: block;
                margin: 0;
                padding-bottom: 5px;
            }
            &:hover {
                cursor: pointer;
                body:not(.no-hover) & {
                    color: $c_sub;
                    span {
                        &:before {
                            border-color: $c_sub;
                        }
                    }
                }

                body:not(.no-hover).site_noel & {
                    color: $c_noel1;
                    span {
                        &:before {
                            border-color: $c_noel1;
                        }
                    }
                }
                body:not(.no-hover).site_vins & {
                    color: $c_vins1;
                    span {
                        &:before {
                            border-color: $c_vins1;
                        }
                    }
                }
                body:not(.no-hover).site_velo & {
                    color: $c_velo1;
                    span {
                        &:before {
                            border-color: $c_velo1;
                        }
                    }
                }
            }
        }

        .frm_checkbox, .frm_radio {
            @include mobile {
                display: block;
            }
        }

        /* Style for Radio */
        .frm_radio {
            input[type="radio"]:checked + span:after {
                opacity: 1;
                transform: scale(1);
            }

            span {
                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 14px;
                    height: 14px;
                    border-radius: 50px;
                    border: 1px solid $c_border;
                    cursor: pointer;
                    @include addTransition();
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 4px;
                    width: 8px;
                    height: 8px;
                    background: $c_main;
                    border-radius: 50%;
                    opacity: 0;
                    transform: scale(0);
                    @include addTransition();

                    body.site_noel & {
                        background: $c_noel1;
                    }
                    body.site_vins & {
                        background: $c_vins1;
                    }
                    body.site_velo & {
                        background: $c_velo1;
                    }
                }
            }
        }

        /* Style for Checkbox */
        .frm_checkbox {
            input {
                display: none;
                &:checked {
                    ~ span:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
            label{
                > span {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        border: 1px solid $c_border;
                        cursor: pointer;
                        @include addTransition();
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 4px;
                        width: 8px;
                        height: 8px;
                        background: $c_main;
                        opacity: 0;
                        transform: scale(0);
                        @include addTransition();

                        body.site_noel & {
                            background: $c_noel1;
                        }
                        body.site_vins & {
                            background: $c_vins1;
                        }
                        body.site_velo & {
                            background: $c_velo1;
                        }
                    }
                }
            }
        }

        /* Style for Scale */
        .frm_scale {
            display: inline-block;
            width: 40px;
            @include mobile {
                margin-bottom: 10px;
            }
            label {
                padding: 0;
                text-align: center;
                &:hover {
                    body:not(.no-hover) & {
                        cursor: pointer;
                        input {
                            border-color: $c_sub;
                        }
                    }
                }
            }
            input {
                position: relative;
                display: block !important;
                @extend %appearance;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: $c_white;
                border: 1px solid $c_border;
                padding: 0;
                margin: 0 auto 4px auto;
                box-sizing: content-box;
                cursor: pointer;
                @include addTransition();
                &:after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    left: 3px;
                    background: $c_main;
                    border-radius: 50%;
                    opacity: 0;
                    transform: scale(0);
                    @include addTransition();
                }
                &:checked {
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        border-color: $c_dark;
                    }
                }
            }
        }
    }


    /* Switch */
    .frm_switch{
        width: 100%;
        height: calc(100% - 40px);
        position: absolute;
        left: 0;
        &:hover, &:focus{
            body:not(.no-hover) & {
                cursor: pointer;
                & + .frm_on_label{
                    color: $c_main;
                }
            }

            body.site_noel:not(.no-hover) & {
                & + .frm_on_label{
                    color: $c_noel1;
                }
            }
            body.site_vins:not(.no-hover) & {
                & + .frm_on_label{
                    color: $c_vins1;
                }
            }
            body.site_velo:not(.no-hover) & {
                & + .frm_on_label{
                    color: $c_velo1;
                }
            }
        }

        input:checked + .frm_slider {
            background-color: $c_main;
            body.site_noel & {
                background-color: $c_noel1;
            }
            body.site_vins & {
                background-color: $c_vins1;
            }
            body.site_velo & {
                background-color: $c_velo1;
            }
        }
        input:checked + .frm_slider:before {
            transform: translateX(26px);
            background: $c_white;
        }

        .frm_slider{
            width: 50px;
            height: 25px;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $c_grey;
            border-radius: 30px;
            @include addTransition();
            &:before{
                content: '';
                border-radius: 50%;
                position: absolute;
                height: 15px;
                width: 15px;
                left: 5px;
                top: 5px;
                background-color: #ADADAD;
                box-shadow: none;
                @include addTransition();
            }

        }
        & + .frm_on_label{
            @include ft($f_txt, 14px, 400, $c_txt, 20px);
            margin: 0;
            padding-left: 60px;
            display: block;
            white-space: normal;
            overflow: visible;
            text-indent: initial;
            @include addTransition();
            &:hover {
                cursor: pointer;
                body:not(.no-hover) & {
                    color: $c_sub;
                    span {
                        &:before {
                            border-color: $c_sub;
                        }
                    }
                }
            }
        }
    }

    /* Style for Hours */
    .frm_time_wrap {
        position: relative;
        @include clearfix();
        > * {
            float: left;
            box-sizing: border-box;
        }
        > span {
            position: relative;
            width: 70% !important;
            @include clearfix();
            padding-right: 2.5%;
            > * {
                float: left;
                width: calc(50% - 16px) !important;
            }
            .frm_time_sep {
                line-height: 60px;
                padding: 0 2px;
                width: 26px !important;
                text-align: center;
            }
        }

        > * {
            width: 30% !important;
            box-sizing: border-box;
        }
    }

    /* Style for Selectric */
    .selectric-wrapper {
        .selectric {
            border-color: $c_border;
            background: $c_white;
            @include addTransition();
            &:hover {
                body:not(.no-hover) & {
                    border-color: $c_main;
                }
                body:not(.no-hover).site_noel & {
                    border-color: $c_noel1;
                }
                body:not(.no-hover).site_vins & {
                    border-color: $c_vins1;
                }
                body:not(.no-hover).site_velo & {
                    border-color: $c_velo1;
                }
            }
            .label {
                @include ft($f_txt, 14px, 400, $c_txt, 20px);
                box-sizing: border-box;
                display: block;
                height: 100%;
                min-height: 48px;
                margin: 0;
                padding: 14px 20px;
                &:after {
                    content: "\e91d";
                    @include font();
                    font-size: 14px;
                    position: absolute;
                    color: $c_border;
                    top: 50%;
                    right: 20px;
                    margin-top: -7px;
                    transform: rotate(-90deg);
                }
            }
            .button {
                display: none;
            }
        }
        .selectric-items {
            border: 1px solid $c_border;
            background: $c_white;
            box-shadow: none;
            .selectric-scroll {
                ul {
                    padding: 0;
                    li {
                        @include ft($f_txt, 14px, 400, $c_txt, 20px);
                        display: block;
                        padding: 8px 15px;
                        margin-bottom: 0;
                        @include addTransition();
                        &:hover {
                            background: #F5F5F5;
                        }
                        &.selected {
                            background: #EEE;
                        }
                    }
                }
            }
        }
    }

    /* Style for Address */
    .frm_combo_inputs_container {
        .frm_form_field {
            .frm_hidden, .frm_screen_reader {
                //display: block !important;
                margin-bottom: 5px;
            }
            &:last-child {
                padding-bottom: 0;
            }
            &.form-field:not(.horizontal_radio):not(.vertical_radio):not(.label-bloc) {
                label {
                    padding: 0 !important;
                    margin: 0 0 5px 0;
                }
            }
        }
    }

    /* Style for Upload file */
    .input-type-file{
        &.has-content {
            .focus-jfilestyle{
                label{
                    margin-top: -16px;
                }
            }
        }
        > label{
            width: calc(100% - 80px);
            padding-right: 130px;
            z-index: 1000;
            pointer-events: none;
        }

        .fallback{
            width: 100%;
        }
        .jfilestyle{
            position: relative;
            > input{
                width: 100% !important;
                padding-right: 120px;
                &:hover, &:focus{
                    body:not(.no-hover) & {
                        box-shadow: none;
                    }
                }
            }
        }

        .focus-jfilestyle{
            label{
                @include ft($f_txt, 12px, 700, $c_dark, 22px);
                position: absolute;
                left: auto;
                top: 50%;
                right: 10px;
                margin-top: -16px;
                padding: 4px 20px;
                border: 1px solid #EBEBEB;
                border-radius: 16px;
                @include addTransition();
                &:hover{
                    body:not(.no-hover) &{
                        cursor: pointer;
                        color: $c_white;
                        background: $c_dark;
                        border-color: $c_dark;
                    }
                }
            }
        }
    }

    /* Honeypot display none */
    .frm_verify{
        display: none;
    }

    .grecaptcha-badge{
        display: none !important;
    }
}