.itinerire-lei {
	position: relative;
	margin-top: 70px;
	min-height: calc(100vh - 70px);

	&__map {
		position: sticky;
		top: 70px;
		left: 0;
		height: calc(100vh - 70px);
		width: calc(100% - 520px);
		float: left;
		@include tablet_portrait {
			position: fixed;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 1000000;
			transform: translate3d(-100%, 0, 0);
			@include addTransition();
			&.open {
				transform: translate3d(0, 0, 0);
			}
		}

		.maps {
			z-index: 20;
			height: 100%;
		}

		&__proximite {
			z-index: 40;
			position: absolute;
			bottom: 20px;
			left: 20px;
			cursor: pointer;
			@media screen and (max-width: 1400px) {
				bottom: 94px;
				left: auto;
				right: 20px;
			}
			@include tablet_portrait{
				bottom: auto;
				top: 20px;
				right: 86px;
			}

			.btn {
				background-color: $c_white;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				padding: 16px 30px;
				cursor: pointer;
				width: 200px;
				font-size: 10px;
				line-height: 20px;
				@include tablet_portrait{
					padding: 20px;
				}
				@include mobile {
					padding: 0;
					height: 46px;
					width: 46px;
					justify-content: center;
					min-width: 0;

					.texte-a-proxi {
						display: none;
					}
				}

				.icon-ico-position {
					padding-right: 30px;

					@include mobile {
						padding-right: 0;
					}
				}
			}

			ul {
				position: absolute;
				bottom: calc(100% + 20px);
				left: 0;
				width: 200px;
				box-sizing: border-box;
				background: white;
				padding: 20px;
				border: 1px solid $c_border;
				display: none;
				@include tablet_portrait{
					bottom: auto;
					top: calc(100% + 20px);
					left: 50%;
					margin-left: -100px;
					right: auto;
				}
				&:before, &:after{
					content: '';
					position: absolute;
					left: 50%;
					display : inline-block;
					height : 0;
					width : 0;
				}
				&:before{
					bottom: -12px;
					margin-left: -12px;
					border-top : 12px solid $c_border;
					border-right : 12px solid transparent;
					border-left : 12px solid transparent;
					@include tablet_portrait{
						bottom: auto;
						top: -12px;
						border-top: none;
						border-bottom : 12px solid $c_border;
					}
				}
				&:after{
					bottom: -11px;
					margin-left: -11px;
					border-top : 11px solid white;
					border-right : 11px solid transparent;
					border-left : 11px solid transparent;
					@include tablet_portrait{
						bottom: auto;
						top: -11px;
						border-top: none;
						border-bottom : 11px solid white;
					}
				}

				.theswitch {
					width: 100%;
					margin-left: 0;
					*{
						cursor: pointer;
					}
					&:hover{
						body:not(.no-hover) & {
							label .txt{
								color: $c_main;
							}
						}
						body:not(.no-hover).site_noel & {
							color: $c_noel1;
						}
						body:not(.no-hover).site_vins & {
							color: $c_vins1;
						}
						body:not(.no-hover).site_velo & {
							color: $c_velo1;
						}
					}

					input{
						display: none;
						&:checked{
							+label{
								&:before{
									background-color: $c_main;
									body.site_noel & {background-color: $c_noel1;}
									body.site_vins & {background-color: $c_vins1;}
									body.site_velo & {background-color: $c_velo1;}
								}
								&:after{
									right: 4px;
									background-color: white;
								}
							}
						}
					}

					label {
						display: flex;
						align-items: center;
						width: 100%;
						position: relative;
						.icon {
							position: relative;
							top: 0;
							left: 0;
							width: 20px;
							margin-right: 10px;
							flex-shrink: 0;
						}

						.txt {
							@include ft($f_hx, 10px, 700, $c_txt, 1);
							display: block;
							text-transform: uppercase;
							padding-right: 35px;
						}

						&:before{
							content: '';
							position: absolute;
							top: 5px;
							right: 0;
							width: 30px;
							height: 18px;
							border-radius: 12px;
							background-color: $c_grey;
							@include addTransition();
						}
						&:after{
							content: '';
							position: absolute;
							top: 9px;
							right: 15px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: $c_grey-dark;
							@include addTransition();
						}
					}

					&:not(:last-child) {
						margin-bottom: 15px;
						border-bottom: 2px solid $c_border;
						padding-bottom: 15px;
					}
				}
			}

			&.is-active {
				ul {
					display: block;
				}
			}

			&.active {
				.btn {
					background-color: $c_velo1;
					color: white;
				}
			}
		}

		&__legende {
			z-index: 40;
			position: absolute;
			bottom: 20px;
			right: 20px;
			background-color: white;
			padding: 20px 30px;
			display: flex;
			align-items: center;
			@include ft($f_txt, 14px, 700, $c_txt, 14px);
			text-transform: uppercase;
			@media screen and (max-width: 1100px) {
				right: auto;
				left: 20px;
			}
			@include mobile {
				flex-flow: column;
				align-items: flex-start;
				padding: 20px;
			}

			span {
				font-size: 12px;
				line-height: 14px;
				text-transform: none;
				font-weight: 400;
				display: flex;
				align-items: center;
				margin-left: 20px;
				@include mobile {
					margin-left: 0;
					margin-top: 5px;
				}

				&:before {
					content: '';
					position: relative;
					display: block;
					width: 20px;
					height: 3px;
					background-color: pink;
					margin-right: 10px;
					margin-right: 10px;
				}

				&.green:before {
					background-color: #4CAF50;
				}

				&.blue:before {
					background-color: #2196F3;
				}

				&.orange:before {
					background-color: #FF9800;
				}

				&.red:before {
					background-color: $c_velo1;
				}
			}
		}

		.close-map {
			@extend %appearance;
			display: none;
			box-sizing: border-box;
			position: absolute;
			top: 20px;
			right: 20px;
			align-items: center;
			justify-content: center;
			background-color: white;
			color: $c_txt;
			border: 1px solid $c_border;
			font-size: 16px;
			height: 46px;
			width: 46px;
			cursor: pointer;
			margin-right: 10px;
			z-index: 150;
			@include addTransition();
			@include tablet_portrait {
				display: flex;
			}

			&:hover {
				color: $c_sub;

				body.site_noel:not(.no-hover) & {
					color: $c_noel2;
				}

				body.site_vins:not(.no-hover) & {
					color: $c_vins2;
				}

				body.site_velo:not(.no-hover) & {
					color: $c_velo2;
				}
			}
		}

	}

	&__content {
		position: relative;
		width: 520px;
		margin-left: auto;
		margin-right: 0;
		float: right;

		@include tablet_portrait {
			position: relative;
			width: 100%;
			float: none;
		}
	}

	&:after {
		content: "";
		display: block;
		clear: both;
	}

	&.--not-lei {

		.itinerire-lei__map {
			@include tablet_portrait {
				position: sticky;
				top: 70px;
				height: 100vh;
				max-height: 60px;
				transform: translate3d(0, 0, 0);
				margin-bottom: 80px;

				&.open {
					max-height: 50vh;
				}
			}
			@include mobile {
				top: 0;
			}

			.maps {
				.leaflet-control-attribution {
					display: none;
				}
			}

			.open-close-map {
				display: none;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				height: 40px;
				background-color: white;
				border: 0;
				font-size: 16px;
				border-bottom: 1px solid $c_border;
				cursor: pointer;
				@include tablet_portrait {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				span {
					@include addTransition();
					transform-origin: center center;
				}
			}

			&.open {
				.open-close-map {
					span {
						transform: rotate(180deg);
					}
				}
			}
		}

		.maps {
			.blocpush-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				color: white;
				font-size: 16px;
				font-weight: 700;
				line-height: 1;
				background-color: $c_sub;

				body.site_noel & {
					background-color: $c_noel2;
				}

				body.site_vins & {
					background-color: $c_vins2;
				}

				body.site_velo & {
					background-color: $c_velo2;
				}
			}
		}

	}
}

.scrolly {
	&__header {
	}

	&__content {
		padding: 40px 40px 0;
		@include mobile {
			padding: 40px 20px 0;
		}

		.entete {
			margin-bottom: 35px;

			.surtitre {
				color: $c_main;

				body.site_noel & {
					color: $c_noel1;
				}

				body.site_vins & {
					color: $c_vins1;
				}

				body.site_velo & {
					color: $c_velo1;
					font-family: $f_spe;
				}
			}

			h1 {
				color: $c_txt;
			}

			.soustitre {
				font-size: 16px;
				line-height: 20px;
				font-family: $f_hx;
				text-transform: uppercase;
				margin-bottom: 20px;
				color: $c_main;

				body.site_noel & {
					color: $c_noel1;
				}

				body.site_vins & {
					color: $c_vins1;
				}

				body.site_velo & {
					color: $c_velo1;
				}
			}

			#breadcrumb {
				position: relative;
				padding: 0;
				z-index: 10;

				span, a {
					color: $c_txt;
				}
			}
		}

		.iti-data:not(.--card) {
			position: relative;
			padding: 20px 0 0;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 35px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: -40px;
				right: -40px;
				z-index: -1;
				background-color: $c_main;

				body.site_noel & {
					background-color: $c_noel1;
				}

				body.site_vins & {
					background-color: $c_vins1;
				}

				body.site_velo & {
					background-color: $c_velo1;
				}

				@include mobile {
					left: -20px;
					right: -20px;
				}
			}

			.iti-data__data {
				display: flex;
				align-items: center;
				width: 50%;
				margin-bottom: 20px;

				> span {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 46px;
					height: 46px;
					border-radius: 50%;
					background-color: white;
					color: $c_main;
					font-size: 22px;
					margin-right: 10px;
					flex-shrink: 0;

					body.site_noel & {
						color: $c_noel1;
					}

					body.site_vins & {
						color: $c_vins1;
					}

					body.site_velo & {
						color: $c_velo1;
					}
				}

				> div {
					.name {
						color: white;
						text-transform: uppercase;
						font-weight: bold;
						font-size: 12px;
						line-height: 20px;
						display: block;
					}

					.data {
						color: white;
						font-size: 12px;
						line-height: 14px;
						display: block;
					}
				}


				.difficulte {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						margin-top: -8px;
						margin-left: -8px;
						width: 16px;
						height: 16px;
						border-radius: 50%;
					}

					&.facile:after {
						background-color: #00AB59;
					}

					&.moyen:after {
						background-color: #2DA5CE;
					}

					&.difficile:after {
						background-color: #FC1926;
					}

					&.t-difficile:after {
						background-color: #000000;
					}
				}
			}

			+ .alerte {
				margin-top: -35px;
			}
		}

		.graph-wrapper {
			position: relative;

			.titre {
				@include ft($f_hx, 12px, 700, black, 14px);
				text-transform: uppercase;
				margin-bottom: 15px;
				width: 100%;
			}

			.graph {
				position: relative;
				margin-left: -30px;
				width: calc(100% + 30px);
				@include mobile {
					margin-left: -15px;
					width: calc(100% + 15px);
				}

				canvas {

				}
			}
		}

		.iti-data-sup {
			margin-bottom: 35px;
			margin-left: -10px;
			margin-right: -10px;

			&:after {
				content: '';
				display: block;
				clear: both;
			}

			> div {
				position: relative;
				width: calc(50% - 20px);
				margin: 10px;
				float: left;
				@include mobile {
					width: 100%;
				}

				&:nth-child(odd) {
					clear: left;
				}

				.data-title {
					@include ft($f_hx, 12px, 700, $c_txt, 14px);
					text-transform: uppercase;
					margin-bottom: 10px;
				}

				p {
					@include ft($f_txt, 12px, 400, $c_txt, 14px);
				}
			}
		}

		.bloc-texte {
			h2 {
				color: $c_main;

				body.site_noel & {
					color: $c_noel1;
				}

				body.site_vins & {
					color: $c_vins1;
				}

				body.site_velo & {
					color: $c_velo1;
				}
			}

			h3 {
				font-weight: 700;
			}
		}

		.downloads {
			z-index: 20;
			position: sticky;
			bottom: 20px;
			margin: 35px -20px 0;
			padding: 20px;
			background-color: $c_grey;
			display: flex;
			align-items: center;
			//margin-top: 400px;
			@include mobile {
				bottom: 60px;
			}

			.open-map {
				@extend %appearance;
				display: none;
				box-sizing: border-box;
				position: relative;
				align-items: center;
				justify-content: center;
				background-color: white;
				color: $c_txt;
				border: 1px solid $c_border;
				font-size: 16px;
				height: 46px;
				width: 46px;
				cursor: pointer;
				margin-right: 10px;
				@include addTransition();
				@include tablet_portrait {
					display: flex;
				}

				&:hover {
					color: $c_sub;

					body.site_noel:not(.no-hover) & {
						color: $c_noel2;
					}

					body.site_vins:not(.no-hover) & {
						color: $c_vins2;
					}

					body.site_velo:not(.no-hover) & {
						color: $c_velo2;
					}
				}
			}

			.btn {
				margin-right: 10px;
				display: inline-flex;
				align-items: center;
				min-width: 0;

				span {
					margin-left: 10px;
				}

				@include mobile {
					padding: 12px;
				}
			}
		}

		.galerie {
			margin-top: 35px;
			margin-left: -40px;
			margin-right: -40px;
			@include mobile {
				margin-left: -20px;
				margin-right: -20px;
			}

			.bloc-texte {
				margin: 0 40px 20px;
				@include mobile {
					margin: 0 20px 20px;
				}
			}

			.slider-wrap {
				position: relative;
				padding: 0 40px;

				&.scroll-mode-parent {
					padding: 0;
				}

				@include mobile {
					padding: 0 20px;
				}

				.th-slider {
					&.scroll-mode {
						padding-left: 40px;
						padding-right: 40px;
						@include mobile {
							padding-left: 20px;
							padding-right: 20px;
						}
					}

					.item {
						margin-right: 20px;
						@include mobile {
							max-width: 100%;
							margin-right: 10px;
						}

						figure {
							background-color: transparent;
						}
					}
				}

				.nav-buttons {
					margin-top: -52px;
					left: 20px;
					right: 0;
					width: auto;
					@include tablet_portrait {
						margin-top: -35px;
						left: 40px;
						right: 40px;
					}
				}
			}
		}

		.alerte {
			position: relative;
			padding: 20px 0;
			display: flex;
			margin-bottom: 35px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: -40px;
				right: -40px;
				z-index: -1;
				background-color: $c_sub;

				body.site_noel & {
					background-color: $c_noel2;
				}

				body.site_vins & {
					background-color: $c_vins2;
				}

				body.site_velo & {
					background-color: $c_velo2;
				}
			}

			.icon-attention {
				color: $c_txt;
				font-size: 20px;
				flex-shrink: 0;
				margin-right: 16px;
				margin-bottom: auto;
				margin-top: 5px;
			}

			&__wrapper {
				display: flex;
				width: 100%;
				@include mobile {
					display: block;
				}

				> div {
					width: 100%;

					+ .btn {
						margin-left: 20px;
						@include mobile {
							margin-left: 0;
							margin-top: 10px;
						}
					}
				}

				.title {
					font-family: $f_hx;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 700;
					color: white;
					display: block;
					margin-bottom: 8px;
				}

				.texte {
					p {
						font-size: 16px;
						line-height: 20px;
						color: white;
					}

					a {
						color: white;
						text-decoration: underline;
						@include addTransition();

						&:hover {
							opacity: .6;
						}
					}
				}

				.btn {
					flex-shrink: 0;
					margin-bottom: auto;
					min-width: 0;
				}
			}

			+ .alerte {
				margin-top: -35px;
			}
		}
	}


	&.--not-lei {
		.flexible-content {
			margin-top: 40px;
		}

		.etape {
			&:not(:last-child) {
				margin-bottom: 40px;
			}

			&__title {
				font-family: $f_spe;
				font-size: 60px;
				line-height: 60px;
				margin-bottom: 20px;
				color: $c_main;

				body.site_noel & {
					color: $c_noel1;
				}

				body.site_vins & {
					color: $c_vins1;
				}

				body.site_velo & {
					color: $c_velo1;
				}
			}

			.bloc {
				padding-top: 0;
			}
		}

		.scrolly-img {
			position: relative;
			margin-left: -40px;
			margin-right: -40px;
			width: calc(100% + 80px);
			@include mobile {
				margin-left: -20px;
				margin-right: -20px;
				width: calc(100% + 40px);
			}

			img {
				height: auto;
			}

			.credits {
				bottom: auto;
				top: 10px;
			}

			.btn-gallery {
				position: absolute;
				bottom: 20px;
				right: 20px;
				z-index: 20;
				width: 40px;
				height: 40px;
				background-color: rgba(0, 0, 0, .5);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				color: white;
			}

			.hide-gallery {
				display: none;
			}
		}


		//Blocs
		.bloc-relation-scrolly {
			.relation-card {
				position: relative;

				+ .relation-card {
					margin-top: 10px;
				}

				.number {
					position: absolute;
					top: 10px;
					left: -15px;
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					background-color: $c_sub;
					color: white;
					font-size: 16px;
					line-height: 1;
					font-weight: 700;
					z-index: 10;

					body.site_noel & {
						background-color: $c_noel2;
					}

					body.site_vins & {
						background-color: $c_vins2;
					}

					body.site_velo & {
						background-color: $c_velo2;
					}
				}
			}

			.card.bloc-card-sit {
				z-index: 5;

				.entete {
					margin-bottom: 0;
				}

				&:not(.bloc-card-lei-itineraire) {
					display: flex;

					figure {
						width: 140px;
						min-height: 140px;
						flex-shrink: 0;
					}

					.caption-txt {
						width: 100%;
						min-height: 140px;
						border-left: 0;
						border-top: 1px solid $c_border;
					}

					.title-card {
						width: 100%;
					}
				}
			}

			&__link {
				margin-top: 20px;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}