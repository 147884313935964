// Tooltip
// ==========================================================================
.tooltip {
    position: relative;
    display: inline-block;
    z-index: 1000000;
    @include addTransition(visibility, 0.3s, ease);
    color: $c_white;

    &:hover {
        .tooltiptext {
            visibility: visible;
            color: $c_white !important;
        }
    }

    .tooltiptext {
        visibility: hidden;
        width: 160px;
        top: calc(100% + 10px);
        left: 50%;
        margin-left: -100px;
        background-color: #7f7f7f;
        text-align: center;
        padding: 8px 20px;
        border-radius: 30px;
        box-shadow: 0 10px 30px rgba($c_dark,0.15);
        text-transform: none;
        @include ft($f_txt, 15px, 400, $c_white !important, 20px);
        /* Position the tooltip text - see examples below! */
        position: absolute;
        white-space: normal;
        @include addTransition(visibility, 0.1s, ease-in-out);

        .no-hover & {
            display: none;
        }

        &:after {
            content: " ";
            position: absolute;
            bottom: calc(100% - 1px);  /* At the top of the tooltip */
            left: 50%;
            margin-left: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent #7f7f7f transparent;
        }
    }
}