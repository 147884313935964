.bloc-aside{
	.wrapper-map{
		position: relative;
		.maps,.static-maps{
			position: relative;
			background: $c_light;
			height: 210px;
			width: 100%;
			z-index: 20;
			figure {
				width: 100%;
				height: 100%;
			}
		}


		.btn-map-aside{
            @include ft($f_hx, 12px, 500, $c_txt, 20px);
            padding: 5px 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: block;
            text-align: center;
            background: $c_white;
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: 25;
            @include addTransition();
            &:before {
                content: "\e92c";
                @include font();
                position: relative;
                top: -2px;
                color: $c_main;
                font-size: 14px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
                @include addTransition();

				body.site_noel & {
					color: $c_noel1;
				}
				body.site_vins & {
					color: $c_vins1;
				}
				body.site_velo & {
					color: $c_velo1;
				}
            }
            &:hover, &:focus {
				body:not(.no-hover) & {
					background: $c_main;
					border-color: $c_main;
					color: $c_white;
					&:before {
						color: $c_white;
					}
					span{
						color: $c_white;
					}
				}
				body:not(.no-hover).site_noel & {
					background: $c_noel1;
					border-color: $c_noel1;
				}
				body:not(.no-hover).site_vins & {
					background: $c_vins1;
					border-color: $c_vins1;
				}
				body:not(.no-hover).site_velo & {
					background: $c_velo1;
					border-color: $c_velo1;
				}
            }
		}
	}

	.wrapper-info{
		position: relative;
        @extend .background-alsace-2022;
        padding: 20px 40px;
        @include mobile{
            padding: 20px 30px;
        }
		.address{
            margin-bottom: 20px;
            > * {
                &:last-child{
                    margin-bottom: 0;
                }
            }
			.name{
				@include ft($f_hx, 20px, 700, $c_white, 24px);
                text-transform: uppercase;
				display: block;
				margin-bottom: 12px;
			}
			p{
				@include ft($f_txt, 14px, 400, $c_white, 18px);
			}
		}

		.cta{
            > * {
                &:last-child{
                    margin-bottom: 0;
                }
            }
			> a {
				@extend .btn;
                @extend .btn-white;
                width: 100%;
				margin-bottom: 10px;
				@include addTransition();
				&:hover, &:focus{

                    body:not(.no-hover) & {
                        color: $c_main;
                        border-color: $c_white;
                        background: $c_white;
                    }

					body.site_vins:not(.no-hover) & {
						color: $c_vins1;
						border-color: $c_white;
						background: $c_white;
					}

					body.site_velo:not(.no-hover) & {
						color: $c_velo1;
						border-color: $c_white;
						background: $c_white;
					}

					body.site_noel:not(.no-hover) & {
						color: $c_white;
						border-color: $c_noel2;
						background: $c_noel2;
					}
                }

				body.site_vins & {
					border-color: white;
				}
			}
		}

        .lei-social-networks{
            display: flex;
            align-items: flex-start;
            margin-top: 10px;
            li{
                margin-right: 10px;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    background: $c_white;
                    color: $c_main;
                    @include addTransition();
                    &:hover, &:focus{
                        body:not(.no-hover) & {
                            color: $c_white;
                            background: $c_dark;
                        }
                    }
                    span{
                        display: block;
                    }

					body.site_noel & {
						color: $c_noel1;
					}
					body.site_vins & {
						color: $c_vins1;
					}
					body.site_velo & {
						color: $c_velo1;
					}
                }
            }
        }

		.fair-guest{
			position: relative;
            display: flex;
            align-items: center;
            margin-top: 20px;
            border-top: 1px dashed $c_white;
            padding-top: 20px;
			.note{
				padding-right: 30px;
                flex: none;
                @include tablet_landscape{
                    padding-right: 20px;
                }
				span{
					@include ft($f_txt, 25px, 400, $c_white, 35px);
					span{
						font-size: calculateRem(35px);
                        line-height: calculateRem(35px);
                        display: inline-block;
					}
				}
			}
			.txt{
				span{
					@include ft($f_txt, 14px, 400, $c_white, 18px);
					display: block;
				}
			}
		}

		body.site_noel & {
			background: $c_noel3;
		}
		body.site_vins & {
			background: $c_vins1;
		}
		body.site_velo & {
			background: $c_velo1;
		}
	}
}