// Définition des paddings entre les blocs
// ==========================================================================
.bloc {
    position: relative;
    clear: both;
    padding: ($content-spacing) 0;

    @include mobile {
        padding: $content-spacing/2 0;
    }
}