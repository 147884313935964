main {
    header {
        position: relative;
        background: $c_grey;
        margin: $content-spacing;
        @include mobile {
            margin: $content-spacing/2;
        }

        .site_noel &, .site_vins &, .site_velo & {
            &:before{
                content: '';
                position: absolute;
                top: -$content-spacing;
                left: -$content-spacing;
                right: -$content-spacing;
                width: calc(100% + (#{$content-spacing*2}));
                height: calc(50% + (#{$content-spacing}));
                background: $c_noel1;
                z-index: -1;
                @include mobile{
                    top: -$content-spacing/2;
                    left: -$content-spacing/2;
                    right: -$content-spacing/2;
                    width: calc(100% + (#{$content-spacing}));
                    height: calc(50% + (#{$content-spacing/2}));
                }
            }
        }

        .site_vins & {
            &:before{
                background: $c_vins1;
            }
        }
        .site_velo & {
            &:before{
                background: $c_velo1;
            }
        }

        // Alignement de la zone caption à gauche
        &.caption-align-left {
            .caption {
                max-width: calc(100% - 80px);
                @include mobile {
                    max-width: calc(100% - 40px);
                }

                > * {
                    text-align: left;
                    margin-left: 0;
                }

                .global-rate {
                    justify-content: flex-start;
                }
            }
        }

        /* **********
        GENERAL STYLE HEADER
        ********** */
        .caption {
            position: absolute;
            transform: translate3d(0, 0, 0);
            top: auto;
            bottom: 90px;
            z-index: 1000;
            width: 100%;
            @include desktop {
                margin-left: 40px;
                margin-right: 40px;
                width: calc(100% - 80px);
            }
            @include mobile {
                margin-left: 20px;
                margin-right: 20px;
                width: calc(100% - 40px);
                bottom: 50px;
            }

            > * {
                max-width: 660px;
                margin-left: auto;
                margin-right: auto;
                color: $c_white;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .anniversaire{
                position: relative;
                width: 296px;
                height: 200px;
                margin: 0 auto;
                background-image: url('../images/design/70ans.svg');
                background-size: 100%;
                margin-bottom: 10px;


                @media screen and (max-height: 700px){
                    width: 148px;
                    height: 100px;
                }

                @include tablet_portrait{
                    width: 222px;
                    height: 150px;

                    ~h1{
                        font-size: 35px;
                        line-height: 35px;
                    }
                }
                @include mobile{
                    width: 74px;
                    height: 50px;

                    ~h1{
                        font-size: 23px;
                        line-height: 23px;
                    }
                }
            }

            .surtitre {
                @include ft($f_spe, 50px, 400, $c_white, 40px);
                display: block;
                margin-bottom: 0;
                @include mobile {
                    font-size: calculateRem(35px);
                    line-height: calculateRem(30px);
                }
            }

            .btn {
                margin-top: 15px;
            }
        }

        .credits {
            top: 100px;
            @include mobile {
                top: 20px;
            }
        }

        .bottom-direction-scroll {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 40px;
            z-index: 1000;
            width: 30px;
            margin: auto;
            text-align: center;
            @include mobile {
                bottom: 15px;
            }

            &:hover, &:focus {
                body:not(.no-hover) & {
                    cursor: pointer;

                    span {
                        color: $c_main;
                    }
                }
                /* #CHANGE COLOR THEME */
                body.site_noel:not(.no-hover) & {
                    span{
                        color: $c_noel1;
                    }
                }
                body.site_vins:not(.no-hover) & {
                    span{
                        color: $c_vins1;
                    }
                }
                body.site_velo:not(.no-hover) & {
                    span{
                        color: $c_velo1;
                    }
                }
            }


            span {
                display: block;
                color: $c_white;
                font-size: 20px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                margin: auto;
                @include addTransition();
            }
        }

        figure {
            &:before {
                content: '';
                @extend .gradient-top;
                z-index: 10;
            }

            &:after {
                content: '';
                @extend .gradient-bottom;
                z-index: 10;
                top: auto;
                height: 75%;
            }

            figcaption {
                display: none;
            }
        }

        // Page de Listing
        & + .bloc-filtres {
            margin-top: -20px;
            @include mobile {
                margin-top: 0;
            }
        }

        /* **********
        STYLE GENERAL HEADER SMALL
        ********** */
        &.small-header {
            height: 390px;

            figure {
                height: 100%;

                &:after {
                    content: '';
                    @extend .gradient-bottom;
                }
            }
        }

        /* **********
        STYLE GENERAL HEADER FULLPAGE
        ********** */
        &.fullpage-header {
            height: calc(100vh - #{$content-spacing*2});
            @include mobile {
                height: calc(100vh - 180px);
            }

            .caption {
                & ~ figure {
                    &:after {
                        content: '';
                        @extend .gradient-bottom;
                    }
                }
            }

            figure {
                height: 100%;

                img {
                    height: 100%;
                }
            }

            .no-photo {
                height: 100%;
            }

            .boutons{
                margin-top: 30px;
                max-width: none;
                @include tablet_portrait{
                    overflow: auto;
                    display: flex;
                    margin-left: -40px;
                    margin-right: -40px;
                    scroll-snap-type: x mandatory;
                    -webkit-overflow-scrolling: touch;
                    padding-left: 40px;
                    padding-right: 40px;
                    scroll-padding: 40px;
                }
                @include mobile{
                    margin-left: -20px;
                    margin-right: -20px;
                    padding-left: 20px;
                    padding-right: 20px;
                    scroll-padding: 20px;
                }
                .btn{
                    margin: 0 10px 10px 0;
                    @include tablet_portrait{
                        flex-shrink: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }


        /* **********
		STYLE HEADER - LIGHT - PAGE CONTACT
		********** */
        &.header-light {
            .caption {
                position: relative;
                bottom: 0;
                top: 0;
                transform: none;
                padding-top: 105px;
                padding-bottom: 105px;
                @include mobile {
                    padding: 80px 0 60px 0;
                }

                .surtitre {
                    color: $c_main;
                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins2;
                    }
                    body.site_velo & {
                        color: $c_velo2;
                    }
                }

                h1, p {
                    color: $c_txt;
                }
            }

            .bottom-direction-scroll {
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        span {
                            color: $c_dark;
                        }
                    }
                }

                span {
                    color: $c_main;
                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins2;
                    }
                    body.site_velo & {
                        color: $c_velo2;
                    }
                }
            }
        }

        /* **********
        STYLE HEADER - FICHE LEI
        ********** */
        &.header-lei {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(((100% - #{$max-width}) / 2) + 400px);
                height: 67px;
                background: $c_white;
                z-index: 100;
                @include desktop {
                    width: 400px;
                }
                @include tablet_landscape {
                    width: 365px;
                }
                @include tablet_portrait {
                    content: none;
                }
            }

            .caption {
                bottom: 100px;
                @include mobile {
                    bottom: 70px;
                }
            }

            #breadcrumb {
                width: 100%;
                max-width: calc(100% - 540px);
                @include tablet_landscape {
                    max-width: calc(100% - 510px);
                }
                @include tablet_portrait {
                    max-width: calc(100% - 40px);
                }
            }

            .info-supp {
                @include ft($f_hx, 25px, 500, $c_white, 24px);
                display: block;
                text-transform: uppercase;
                margin-top: 10px;
                @include mobile {
                    font-size: calculateRem(18px);
                    line-height: calculateRem(20px);
                }
            }

            .info-randonnee {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 15px;
                @include mobile {
                    flex-wrap: wrap;
                }

                li {
                    box-sizing: border-box;
                    padding: 10px 30px;
                    @include mobile {
                        padding: 10px 20px;
                        width: 50%;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        transform: translate3d(0, -50%, 0);
                        width: 1px;
                        height: 35px;
                        background: rgba($c_white, 0.5);
                    }

                    &:nth-child(2) {
                        @include mobile {
                            &:after {
                                content: none;
                            }
                        }
                    }

                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }

                    [class^="icon-"], [class*="icon-"] {
                        display: block;
                        color: $c_white;
                        font-size: 28px;
                        margin-bottom: 15px;
                        @include mobile {
                            font-size: 24px;
                            margin-bottom: 10px;
                        }
                    }

                    .value {
                        @include ft($f_txt, 15px, 700, $c_white, 18px);
                        text-transform: uppercase;
                        display: block;
                        text-align: center;
                        @include mobile {
                            font-size: calculateRem(12px);
                            line-height: calculateRem(16px);
                        }
                    }
                }
            }

            .caption-date-event {
                position: absolute;
                top: 45px;
                left: 40px;
                z-index: 10000;
                @include mobile{
                    top: 65px;
                    left: 20px;
                }

                span {
                    @include ft($f_hx, 50px, 400, $c_white, 50px);
                    display: block;
                    text-transform: uppercase;
                    @include tablet_landscape{
                        font-size: calculateRem(40px);
                        line-height: calculateRem(40px);
                    }
                    @include mobile{
                        font-size: calculateRem(30px);
                        line-height: calculateRem(30px);
                    }

                    &.month {
                        font-weight: 700;
                    }

                    &.year {
                        font-size: calculateRem(32px);
                        line-height: calculateRem(32px);
                        margin-top: 10px;
                        @include tablet_landscape{
                            font-size: calculateRem(26px);
                            line-height: calculateRem(26px);
                        }
                        @include mobile{
                            font-size: calculateRem(18px);
                            line-height: calculateRem(18px);
                        }
                    }
                }

                .dropdown {
                    position: relative;
                    display: inline-block;
                    margin-top: 15px;
                    @include clearfix();
                    @include mobile {
                        margin-top: 10px;
                    }

                    &.open {
                        > ul {
                            display: block;
                        }
                    }

                    > a {
                        @include ft($f_hx, 14px, 500, $c_white, 20px);
                        position: relative;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        display: inline-block;
                        @include addTransition();
                        @include mobile{
                            font-size: calculateRem(12px);
                        }

                        &:hover, &:focus {
                            body:not(.no-hover) & {
                                color: $c_main;
                            }
                        }

                        &:before {
                            content: "\e938";
                            @include font();
                            float: left;
                            font-size: 17px;
                            line-height: 20px;
                            margin-right: 10px;
                            position: relative;
                            top: 1px;
                            color: $c_white;
                            @include addTransition();
                        }
                    }

                    ul {
                        display: none;
                        position: absolute;
                        top: -15px;
                        left: 100%;
                        white-space: nowrap;
                        background: $c_dark;
                        padding: 15px 25px;
                        margin-left: 20px;
                        @include mobile{
                            top: 100%;
                            left: 0;
                            margin: 10px 0 0 0;
                        }

                        &:before {
                            content: " ";
                            position: absolute;
                            width: 17px;
                            height: 17px;
                            top: 17px;
                            right: auto;
                            left: -9px;
                            background: $c_dark;
                            border: 1px solid rgba(#f4f4f4, 0.3);
                            border-width: 0 0 1px 1px;
                            transform: rotate(45deg);
                            @include mobile {
                               content: none;
                            }
                        }

                        li {
                            @include ft($f_hx, 12px, 500, $c_white, 20px);
                            display: block;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }
                    }
                }
            }

            .rate {
                > * {
                    font-size: 20px;
                    margin: 0 1px;
                }
            }
        }

        /* **********
        STYLE HEADER - LANDING PAGE ITINERAIRE
        ********** */
        &.header-itineraire {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(((100% - #{$max-width}) / 2) + 620px);
                height: 240px;
                background: $c_white;
                z-index: 100;
                @include wide {
                    width: calc(((100% - #{$max-width-large}) / 2) + 620px);
                }
                @include desktop {
                    width: 520px;
                }
                @include tablet_landscape {
                    width: 420px;
                }
                @include tablet_portrait {
                    content: none;
                }
            }

            .caption {
                max-width: 600px;
                margin-left: 40px;
                @include tablet_landscape {
                    max-width: calc(100% - 500px);
                }
                @include tablet_portrait {
                    max-width: calc(100% - 80px);
                }
                @include mobile {
                    margin-left: 20px;
                    max-width: calc(100% - 40px);
                }
            }

            .bottom-direction-scroll {
                right: auto;
                left: 30px;
                bottom: 55px;
                @include mobile {
                    right: 0;
                    left: 0;
                    bottom: 15px;
                }
            }
        }

        /* **********
        STYLE HEADER - LANDING PAGE RECETTE
        ********** */
        &.header-recette {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(((100% - #{$max-width}) / 2) + 400px);
                height: 67px;
                background: $c_white;
                z-index: 100;
                @include desktop {
                    width: 400px;
                }
                @include tablet_landscape {
                    width: 365px;
                }
                @include tablet_portrait {
                    content: none;
                }
            }

            .caption {
                .surtitre {
                    @include ft($f_hx, 20px, 500, $c_white, 20px);
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                h1 {
                    @include ft($f_spe, 80px, 400, $c_white, 60px);
                    text-transform: none;
                    @include tablet_landscape {
                        font-size: calculateRem(70px);
                        line-height: calculateRem(50px);
                    }
                    @include mobile {
                        font-size: calculateRem(60px);
                        line-height: calculateRem(50px);
                    }
                    @include mobile_small {
                        font-size: calculateRem(50px);
                        line-height: calculateRem(40px);
                    }
                }

                .global-rate {
                    justify-content: flex-start;
                }
            }
        }


        &.no-photo {
            background: none;
            height: auto;
            background: $c_grey;

            .caption-date-event {
                position: relative;
                left: 40px;
                right: 40px;
                width: calc(100% - 80px);
                @include mobile{
                    left: 20px;
                    right: 20px;
                    width: calc(100% - 40px);
                }
                span {
                    color: $c_txt;
                }

                .dropdown {
                    > a {
                        color: $c_main;
                        &:before{
                            color: $c_main;
                            body.site_noel & {
                                color: $c_noel1;
                            }
                            body.site_vins & {
                                color: $c_vins1;
                            }
                            body.site_velo & {
                                color: $c_velo1;
                            }
                        }
                        &:hover, &:focus {
                            body:not(.no-hover) & {
                                color: $c_txt;

                                &:before {
                                    color: $c_txt;
                                }
                            }
                        }

                        body.site_noel & {
                            color: $c_noel1;
                        }
                        body.site_vins & {
                            color: $c_vins1;
                        }
                        body.site_velo & {
                            color: $c_velo1;
                        }
                    }
                }
            }

            .caption {
                position: relative;
                top: 0;
                bottom: 0;
                padding-top: 105px;
                padding-bottom: 105px;
                @include mobile {
                    padding: 80px 0 60px 0;
                }
                .surtitre{
                    color: $c_main;
                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins2;
                    }
                    body.site_velo & {
                        color: $c_velo2;
                    }
                }
                h1, .info-supp {
                    color: $c_txt;
                }

                .coeur {
                    color: $c_main;
                    border-color: $c_main;
                }

                .info-randonnee{
                    li {
                        &:after {
                            background: rgba($c_dark, 0.5);
                        }
                        [class^="icon-"], [class*="icon-"] {
                            color: $c_dark;
                        }
                        .value {
                           color: $c_dark;
                        }
                    }
                }
            }

            .bottom-direction-scroll{
                span{
                    color: $c_main;

                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins2;
                    }
                    body.site_velo & {
                        color: $c_velo2;
                    }
                }
            }

            #breadcrumb {
                @extend .breadcrumb-white;
            }
        }

    }

}