.marker-cluster-small {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

.marker-cluster-small div {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

.marker-cluster-medium {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

.marker-cluster-medium div {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

.marker-cluster-large {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

.marker-cluster-large div {
	background-color: rgba($c-main, 0.6);
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: rgba($c_noel1, 0.6);
	}
	.site_vins & {
		background-color: rgba($c_vins1, 0.6);
	}
	.site_velo & {
		background-color: rgba($c_velo1, 0.6);
	}
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.leaflet-oldie .marker-cluster-small div {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.leaflet-oldie .marker-cluster-medium {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.leaflet-oldie .marker-cluster-medium div {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.leaflet-oldie .marker-cluster-large {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.leaflet-oldie .marker-cluster-large div {
	background-color: $c-main;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		background-color: $c_noel1;
	}
	.site_vins & {
		background-color: $c_vins1;
	}
	.site_velo & {
		background-color: $c_velo1;
	}
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	//font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
	line-height: 30px;
}

