a {
	text-decoration: none;
}

a,
a:hover,
a:active,
a:focus,
input,
input:hover,
input:active,
input:focus {
	outline: none;
}

b, strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

img {
	position: relative;
	display: block;
	max-width: 100%;
}

// Basics
// ==========================================================================
h1, .h1 {
	@include ft($f_hx, $s_h1, 700, $c_white, $s_h1);
    text-transform: uppercase;
    position: relative;
    display: block;  
    margin: 0 0 15px 0;
    @include mobile{
        font-size: calculateRem(30px);
        line-height: calculateRem(30px);
    }
}

h2, .title-h2 {
    @include ft($f_hx, $s_h2, 700, $c_txt, $s_h2);
    display: block;
    margin-top: 35px;
    margin-bottom: 25px;
    @include mobile {
        font-size: calculateRem(30px);
        line-height: calculateRem(30px);
        margin-top: 25px;
        margin-bottom: 20px;
    }

    & + .soustitre {
        margin-top: -5px;
        margin-bottom: 25px;
        @include mobile {
            margin-bottom: 20px;
        }
    }
}

h3, .h3 {
    @include ft($f_hx, $s_h3, 700, $c_main, $s_h3+6);
	position: relative;
	display: block;
	margin: 0 0 15px 0;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		color: $c_noel2;
	}
	.site_vins & {
		color: $c_vins2;
	}
	.site_velo & {
		color: $c_velo2;
	}
}

h4, .h4 {
    @include ft($f_hx, 18px, 400, $c_main, 24px);
    text-transform: uppercase;
    position: relative;
	display: block;
	margin: 0 0 5px 0;
	/* #CHANGE COLOR THEME */
	.site_noel & {
		color: $c_noel2;
	}
	.site_vins & {
		color: $c_vins2;
	}
	.site_velo & {
		color: $c_velo2;
	}
}

p {
    @include ft($f_txt, $s_p, 400, $c_txt, $s_p*1.5);
    display: block;
    @include mobile{
        font-size: calculateRem(14px);
        line-height: calculateRem(18px);
    }

    &.accroche{
        font-size: calculateRem(20px);
        line-height: calculateRem(24px);
        @include mobile{
            font-size: calculateRem(16px);
            line-height: calculateRem(20px);
        }
    }
}

ul {
	position: relative;
	display: block;
	list-style-type: none;
	li {
		position: relative;
		padding: 0;
		@include ft($f_txt, $s_p, 400, $c_txt, $s_p+5);
		a {
			color: $c_sub;
		}
	}
}

ol {
	position: relative;
	display: block;
	list-style-type: decimal;
	li {
		position: relative;
		padding: 0;
		@include ft($f_txt, $s_p, 400, $c_txt, $s_p+5);
		a {
			color: $c_sub;
			/* #CHANGE COLOR THEME */
			.site_noel & {
				color: $c_noel2;
			}
			.site_vins & {
				color: $c_vins2;
			}
			.site_velo & {
				color: $c_velo2;
			}
		}
	}
}

// Additionals
// ==========================================================================
.coeur {
	@include ft($f_hx, 14px, 400, $c_white, 22px);
    display: inline-block;
	text-transform: uppercase;
	padding: 0 10px;
	border: 1px solid $c_white;
    margin-bottom: 15px;
	width: auto;
    height: 24px;
}

.basic-link {
	@include ft($f_hx, 14px, 500, $c_main, 20px);
    letter-spacing: 1px;
	text-transform: uppercase;
	display: inline-block;
	clear: both;
	margin: 10px 0;
	@include addTransition();
	@include mobile{
        margin: 0;
    }

	.site_vins & {
		color: $c_vins1;
	}
	.site_noel & {
		color: $c_noel1;
	}
	.site_velo & {
		color: $c_velo1;
	}

	&:hover, &:focus {
		body:not(.no-hover) & {
			color: $c_txt;
			&:after {
				color: $c_txt;
				right: -5px;
			}
		}
	}
	&:after {
		content: "\e95c";
		@include font();
		position: relative;
        vertical-align: top;
		font-size: 20px;
		line-height: 20px;
		color: $c_main;
        top: 1px;
		right: 0;
        margin-left: 10px;
		@include addTransition();
		.site_vins & {
			color: $c_vins1;
		}
		.site_velo & {
			color: $c_velo1;
		}
		.site_noel & {
			color: $c_noel1;
		}
	}
}

.btn {
    @include ft($f_hx, 14px, 500, $c_dark, 20px);
    box-sizing: border-box;
    text-transform: uppercase;
    letter-spacing: 1px;
	min-width: 170px;
	padding: 11px 20px 13px;
	display: inline-block;
	text-align: center;
    border: 1px solid $c_main;
    @include addTransition();
    @include mobile{
        min-width: 100px;
    }
	/* #CHANGE COLOR THEME */
	.site_noel & {
		border-color: $c_noel2;
	}
	.site_vins & {
		border-color: $c_vins1;
	}
	.site_velo & {
		border-color: $c_velo2;
	}
	&:hover, &:focus{
		body:not(.no-hover) & {
			background: $c_main;
			border-color: $c_main;
			color: $c_white;
		}
		/* #CHANGE COLOR THEME */
		body.site_noel:not(.no-hover) & {
            background: $c_noel2;
            border-color: $c_noel2;
		}
		body.site_vins:not(.no-hover) & {
            background: $c_vins1;
            border-color: $c_vins1;
		}
		body.site_velo:not(.no-hover) & {
            background: $c_velo2;
            border-color: $c_velo2;
		}
	}

    &.btn-large{
        padding: 9px 40px 13px;
    }

	&.btn-white{
		color: $c_white;
		border-color: $c_white;
		&:hover, &:focus{
			body:not(.no-hover) & {
				background: $c_white;
				border-color: $c_white;
				color: $c_dark;
			}
		}
	}

	&.btn-grey{
		background-color: white;
		color: $c_txt;
		border-color: $c_border;
		span{
			color: $c_sub;
			/* #CHANGE COLOR THEME */
			.site_noel & {
				color: $c_noel2;
			}
			.site_vins & {
				color: $c_vins1;
			}
			.site_velo & {
				color: $c_velo2;
			}
		}
		&:hover, &:focus{
			body:not(.no-hover) & {
				background-color: white;
				border-color: $c_border;
				color: $c_sub;
			}
			/* #CHANGE COLOR THEME */
			body.site_noel:not(.no-hover) & {
				color: $c_noel2;
			}
			body.site_vins:not(.no-hover) & {
				color: $c_vins1;
			}
			body.site_velo:not(.no-hover) & {
				color: $c_velo2;
			}
		}
	}
}


.btn-filtre, .btn-print {
    @include ft($f_hx, 12px, 500, $c_txt, 20px);
    position: relative;
    z-index: 10000;
    letter-spacing: 1px;
    box-sizing: border-box;
    border: 1px solid $c_border;
    text-transform: uppercase;
	display: inline-block;
    text-align: left;
    padding: 3px 20px 4px 70px;
    background: $c_white;
    min-width: 155px;
	@include addTransition();
	&:before {
		content: "\e92a";
		@include font();
		color: $c_main;
		position: absolute;
		left: 15px;
		font-size: 14px;
		top: 50%;
        margin-top: -8px;
		@include addTransition();
		/* #CHANGE COLOR THEME */
		.site_noel & {
			color: $c_noel2;
		}
		.site_vins & {
			color: $c_vins2;
		}
		.site_velo & {
			color: $c_velo2;
		}
	}
	&:hover, &:focus {
		body:not(.no-hover) & {
			background: $c_main;
			border-color: $c_main;
			color: $c_white;
			&:before {
				color: $c_white;
			}
			span{
				color: $c_white;
			}
		}
		/* #CHANGE COLOR THEME */
		body.site_noel:not(.no-hover) & {
			background: $c_noel1;
			border-color: $c_noel1;
		}
		body.site_vins:not(.no-hover) & {
			background: $c_vins2;
			border-color: $c_vins2;
		}
		body.site_velo:not(.no-hover) & {
			background: $c_velo1;
			border-color: $c_velo1;
		}
	}

    span{
        position: absolute;
        right: 15px;
        color: $c_main;
        /* #CHANGE COLOR THEME */
        .site_noel & {
            color: $c_noel2;
        }
        .site_vins & {
            color: $c_vins2;
        }
        .site_velo & {
            color: $c_velo2;
        }
        @include addTransition();
    }
}

.btn-map {
    @include ft($f_hx, 12px, 500, $c_txt, 20px);
    position: relative;
    box-sizing: border-box;
    padding: 3px 20px 5px 45px;
	border: 1px solid $c_border;
	display: block;
	text-align: center;
	background: $c_white;
	@include addTransition();
	&:before {
		content: "\e92c";
		@include font();
		color: $c_main;
		position: absolute;
		left: 15px;
		font-size: 14px;
		top: 50%;
        margin-top: -8px;
		@include addTransition();

		body.site_noel & {
			color: $c_noel1;
		}
		body.site_vins & {
			color: $c_vins1;
		}
		body.site_velo & {
			color: $c_velo2;
		}
	}
	&:hover, &:focus {
		body:not(.no-hover) & {
            background: $c_main;
            border-color: $c_main;
            color: $c_white;
            &:before {
                color: $c_white;
            }
            span{
                color: $c_white;
            }
		}

		body.site_velo:not(.no-hover) & {
			background: $c_velo1;
			border-color: $c_velo1;
		}
	}
}

.btn-gallery {
    position: absolute;
    bottom: 27px;
    right: calc(((100% - #{$max-width}) / 2) + 420px);
    z-index: 10000;
	width: 40px;
    height: 40px;
    background: rgba($c_dark,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c_white;
    @include addTransition();
    @include desktop{
        right: 420px;
    }
    @include tablet_landscape{
        right: 390px;
    }
    @include tablet_portrait{
        right: 20px;
        bottom: 20px;
    }
    &:hover, &:focus{
		body:not(.no-hover) & {
			background: $c_main;
		}
		body:not(.no-hover).site_noel & {
			background: $c_noel1;
		}
		body:not(.no-hover).site_vins & {
			background: $c_vins1;
		}
		body:not(.no-hover).site_velo & {
			background: $c_velo1;
		}
    }
    span{
        font-size: 18px;
        display: block;
    }
}

.accroche {
	@include ft($f_hx, 30px, 400, $c_txt, 40px);
}

.surtitre {
	@include ft($f_hx, 50px, 400, $c_white, 50px);
	display: block;
	margin-bottom: 0;
    @include tablet_landscape{
        font-size: calculateRem(38px);
        line-height: calculateRem(35px);
        margin-bottom: 10px;
    }

    &.bold-style{
        @include ft($f_hx, 20px, 700, $c_txt, 26px);
        @include mobile{
            font-size: calculateRem(16px);
            line-height: calculateRem(24px);
        }
    }
}

.location {
	@include ft($f_hx, 16px, 400, #4D4D4D, 19px);
    position: relative;
	display: inline-block;
    padding-left: 20px;
    margin-top: 5px;
	&:before {
		content: "\e926";
		@include font();
        color: #808080;
        position: absolute;
        top: 2px;
        left: 0;
		font-size: 16px;
	}
}