
// =============================================
// Arty ChatBot
// =============================================

body.has-arty{
    // Toggle Button
    .toggle {
        height: 40px;
        // Share Icon
        &:after {
            font-size: 16px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            left: -1px;
            color: $c_white;
        }
    }

    .network-list{
        bottom: 0;
        left: -12px;
    }

    // Bouton Arty
    #artybtn{
        width: 67px;
        height: 67px;
        border-radius: 67px;
        background: $c_main;
        text-align: center;
        font-size: 35px;
        color: $c_white;
        [class^="icon-"], [class*=" icon-"]{
            display: block;
            height: 67px;
            line-height: 67px;
        }

        & ~ .social-share{
            bottom: 105px;
            @include mobile{
                bottom: 100px;
            }
        }

        >img{
            display: block;
            height: 63px;
            width: 63px;
            margin-left: 2px;
            margin-top: 2px;
        }
    }

    #artybtn {
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 500000;
        opacity: 1;
        display: block;
        @include addTransition();
        @include mobile{
            right: 20px;
            bottom: 20px;
        }
        .sub-menu-open & {
            opacity: 0 !important;
        }

        +.iti-global-btn{
            bottom: 106px;
        }
    }

    #artybtn.stop-stick{
        position: absolute;
        right: 30px;
        bottom: auto;
        left: auto;
        margin-top: -100px;
    }

    /* #CHANGE COLOR THEME */
    &.site_noel{
        .toggle{
            background: $c_noel3;
            &:hover{
                background: $c_noel2;
            }
        }
        #artybtn{
            background: $c_noel2;
            &:hover{
                background: $c_noel1;
            }
        }
    }
    &.site_vins{
        .toggle{
            background: $c_vins1;
            &:hover{
                background: $c_vins2;
            }
        }
        #artybtn{
            background: $c_vins1;
            &:hover{
                background: $c_vins2;
            }
        }
    }
    &.site_velo{
        .toggle{
            background: $c_velo1;
            &:hover{
                background: $c_velo2;
            }
        }
        #artybtn{
            background: $c_velo1;
            &:hover{
                background: $c_velo2;
            }
        }
    }
}

body.has-arty.has-wmbth{
    #artybtn{
        bottom: 115px;

        +.iti-global-btn{
            bottom: 191px;
        }
    }

}