// Menu Pro - Presse - Groupes
// ==========================================================================
.menu-pro {
    background: $c_txt;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 100%;
    width: 100%;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    @include addTransition();

    @include min-desktop{
        padding: 0;
    }

    .sub-menu-open & {
        z-index: 100 !important;
    }

    > div {
        position: relative;
        height: 60px !important;
        font-size: 0;
        @media screen and (min-width: 1600px){
            max-width: 1520px;
        }
        > * {
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    .title-groupes {
        display: none;
        width: 20%;
        @include min-tablet_portrait {
            display: inline-block;
            padding-left: 27px;
        }
        @include min-desktop{
            padding: 0;
        }
        span {
            @include ft($f_txt, 18px, 700, $c_white, 60px);
            display: block;
        }
    }
    ul {
        width: 100%;
        padding: 0;
        text-align: left;
        @include min-tablet_portrait {
            width: 80%;
            text-align: right;
        }
        li {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            &:nth-child(1) {
                padding-left: 27px;
                @include min-desktop{
                    padding-left: 0;
                }
                a {
                    padding-left: 0;
                }
            }
            &:last-child {
                padding-right: 27px;
                @include min-desktop{
                    padding-right: 0;
                }
                a {
                    padding-right: 0;
                }
            }
            a {
                @include ft($f_txt, 14px, 700, $c_white, 60px);
                padding: 0 10px;
                &:hover, &:focus {
                    body:not(.no-hover) & {
                        color: $c_main;
                    }
                    /* #CHANGE COLOR THEME */
                    body.site_noel:not(.no-hover) & {
                        color: $c_noel2;
                    }
                    body.site_vins:not(.no-hover) & {
                        color: $c_vins2;
                    }
                    body.site_velo:not(.no-hover) & {
                        color: $c_velo2;
                    }
                }
            }
        }
    }
}