.bloc-colonnes + .bloc-colonnes {
    padding-top: 0;
}

.bloc-colonnes {
    padding: $content-spacing/2 0;

    .col-100 {
        margin-bottom: $content-spacing/2;
    }

    > a {
        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;

                > div {
                    .basic-link {
                        color: darken($c_main, 15%);

                        &:after {
                            color: darken($c_main, 15%);
                            right: -10px;
                        }
                    }
                }
            }
            body.site_noel:not(.no-hover) & {
                > div {
                    .basic-link {
                        color: darken($c_noel1, 15%);

                        &:after {
                            color: darken($c_noel1, 15%);
                        }
                    }
                }
            }
            body.site_vins:not(.no-hover) & {
                > div {
                    .basic-link {
                        color: darken($c_vins1, 15%);

                        &:after {
                            color: darken($c_vins1, 15%);
                        }
                    }
                }
            }
            body.site_velo:not(.no-hover) & {
                > div {
                    .basic-link {
                        color: darken($c_velo1, 15%);

                        &:after {
                            color: darken($c_velo1, 15%);
                        }
                    }
                }
            }
        }
    }

    .col {
        position: relative;
        display: block;
        box-sizing: border-box;
        float: left;
        width: 50%;
        margin: $content-spacing/2 0;
        @include mobile {
            float: none;
            width: 100%;
            clear: both;
            margin: $content-spacing/2 0;
            &:last-child {
                margin-bottom: 0;
            }
        }

        figure {
            margin-bottom: 20px;
            background: none;
            @include aspect-ratio(580, 380);
            @include mobile {
                margin-bottom: 10px;
            }

            img {
                @extend %center;
            }
        }

        .no-photo {
            @extend %center;
        }

        > div {
            position: relative;
        }

        > .bloc-texte {
        }
    }

    /*
STYLE POUR 3 COLONNES
*/
    &.bloc-3-colonnes {
        .col {
            width: 33.33%;
            @include mobile {
                width: 100%;
            }
        }
    }
}

/*
TITRE + LIEN SUR L'IMAGE
 */
.bloc-colonnes.bloc-2-colonnes, .bloc-colonnes.bloc-3-colonnes {
    .bloc-no-text {
        &.no-shadow {
            figure {
                &:after {
                    content: none;
                }
            }
        }

        figure {
            margin: 0;

            &:after {
                content: '';
                @extend .gradient-bottom;
            }
        }

        .bloc-texte {
            position: absolute;
            left: 40px;
            right: 40px;
            bottom: 20px;
            padding: 0;
            width: calc(100% - 80px);
            z-index: 10;
            @extend %bloc-texte-white;

            .basic-link {
                color: $c_white;
                margin: 0;
            }
        }
    }

    a.bloc-no-text {
        &:hover, &:focus {
            body:not(.no-hover) & {
                .basic-link {
                    color: $c_main;
                }
            }
            body.site_noel:not(.no-hover) & {
                .basic-link {
                    color: $c_noel1;
                }
            }
            body.site_vins:not(.no-hover) & {
                .basic-link {
                    color: $c_vins1;
                }
            }
            body.site_velo:not(.no-hover) & {
                .basic-link {
                    color: $c_velo1;
                }
            }
        }
    }
}