.bloc-sejour {
	position: relative;
	.intro {
		position: relative;
		z-index: 1;
        background: $c_grey;
        padding: 60px 40px 20px 40px;
        @include mobile{
            padding: 40px 20px 20px 20px;
        }
        h2{
            margin-bottom: 30px;
        }
		p{
			max-width: 650px;
		}
	}
	.wrapper{
		position: relative;
        background: $c_grey;
        padding: 20px 0;
        display: flex;
        justify-content: space-around;
		z-index: 0;
        @include mobile{
            flex-wrap: wrap;
        }
		> div {
			position: relative;
			text-align: center;
			padding: 20px;
            max-width: 350px;
            @include mobile{
                width: 100%;
            }
            figure{
                background: none;
                margin-bottom: 25px;
                img{
                    height: auto;
                    margin: auto;
                }
            }
			> span{
				font-size: 80px;
				margin-bottom: 40px;
				display: block;
				color: $c_white;
			}
			h3{
				@include ft($f_hx, 20px, 400, $c_txt, 25px);
				text-transform: uppercase;
				margin-bottom: 15px;
			}
			p{
                @include ft('', 16px, '', '', 20px);
				margin-bottom: 15px;
                @include mobile{
                    font-size: calculateRem(14px);
                    line-height: calculateRem(18px);
                }
			}
            .btn{
                margin-top: 15px;
                @include mobile{
                    margin-top: 5px;
                }
            }
		}
	}
}