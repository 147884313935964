@keyframes slowZoom {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.1);
	}
}

@keyframes pulse {
	to {
		box-shadow: 0 0 0 6px transparent, 0 0 0 12px rgba($c_main, 0);
	}
}


@-webkit-keyframes moveToLeft {
	from { }
	to { -webkit-transform: translateX(-100%); }
}
@keyframes moveToLeft {
	from { }
	to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}
@-webkit-keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); }
}
@keyframes moveFromRight {
	from { -webkit-transform: translateX(100%); transform: translateX(100%); }
}


@keyframes inM {
	50% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,0,0) rotate(45deg);
	}
}

@keyframes outM {
	50% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,0,0) rotate(45deg);
	}
}

@keyframes inT {
	0% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	50% {
		transform: translate3d(0,9px,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,9px,0) rotate(135deg);
	}
}

@keyframes outT {
	0% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	50% {
		transform: translate3d(0,9px,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,9px,0) rotate(135deg);
	}
}

@keyframes inBtm {
	0% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	50% {
		transform: translate3d(0,-9px,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,-9px,0) rotate(135deg);
	}
}

@keyframes outBtm {
	0% {
		transform: translate3d(0,0,0) rotate(0deg);
	}
	50% {
		transform: translate3d(0,-9px,0) rotate(0deg);
	}
	100% {
		transform: translate3d(0,-9px,0) rotate(135deg);
	}
}