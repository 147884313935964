.bloc-iframe {
	@include aspect-ratio(16,9);
	iframe {
		@extend %center;
		height: 100% !important;
	}
}


// Listing Map - Géoloc
// ==========================================================================
.listing-geoloc {
	position: absolute;
	bottom: 0;
	display: flex;
	padding: 0 27px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	z-index: 1000;
	@include min-tablet_landscape {
		top: 0;
		right: 0;
		bottom: 0;
		display: block;
		width: auto;
		padding: 10px 0;
	}
	&.cards-mini {
		position: absolute;
		z-index: 1000;
	}
	> div {
		position: relative;
		display: flex;
		font-size: 0;
		white-space: nowrap;
		overflow: visible;
		@include min-tablet_landscape {
			display: block;
			white-space: normal;
			overflow: auto;
		}
		> div {
			display: inline-block;
			vertical-align: top;
			max-width: 348px;
			margin-right: 15px;
			width: calc(100vw - 54px);
			&:last-child {
				margin-right: 27px;
				@media screen and (min-width: 530px) and (max-width: 992px) {
					margin-right: calc(100vw - 460px);
				}
			}
			@include min-tablet_landscape {
				display: block;
				margin: 15px 0;
				width: 400px;
				max-width: 400px;
			}
		}
	}
}

// Listing Map Mobile - Ex: Page Listing LEI
// ==========================================================================
.listing-map-mobile {
	position: relative;
	display: flex;
	padding: 0 20px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	z-index: 1000;
    scroll-snap-type: both mandatory;
    scroll-padding: 20px;
	@include min-tablet_landscape {
		position: absolute;
		top: 55px;
		right: 27px;
		bottom: 25px;
		left: auto;
		width: 400px;
		padding: 0;
	}
	@include min-desktop {
		right: 20px;
	}
	> div {
		position: relative;
		flex-direction: row;
		font-size: 0;
		white-space: nowrap;
		@include min-tablet_landscape {
			white-space: normal;
			overflow: auto;
		}
		&.maps-pagination {
			margin-right: 27px;
			flex-direction: column;
			display: flex;
			a {
				position: relative;
				display: block;
				height: 100%;
				width: 130px;
				border-radius: 4px;
				text-align: center;
				background: $c_main;
				margin-right: 27px;
				box-shadow: 0 0 8px rgba($c_dark, 0.2);
				&:last-child {
					margin-right: 0;
				}
				&.prev {
					height: 40px;
					@include ft($f_txt, 13px, 700, $c_txt, 40px);
					background: $c_white;
					span {
						font-size: 14px;
						line-height: 30px;
						position: relative;
						top: 1.5px;
						margin-right: 2px;
					}

				}
				&.next {
					@include ft($f_txt, 14px, 700, $c_white, 73px);
					text-transform: uppercase;
					height: 73px;
					margin-bottom: 5px;
				}
			}
		}
        .card{
            @include cardHorinzontale();
            width: 300px;
            display: inline-flex;
            margin-right: 20px;
            scroll-snap-align: start;
			@include mobile_small{
				width: 260px;
			}
			.duree {
				position: relative;
				top: auto;
				left: auto;
			}
			.opening {
				top: -15px;
			}
			&.bloc-card-lei-itineraire{
				.entete{
					height: 100%;
					width: 100%;
					.titre{
						padding: 0 10px;
						white-space: normal;
					}
				}
				.bot{
					display: none;
				}
			}
        }

		.btn-next{
			@include ft($f_hx, 14px, 500, $c_white, 20px);
            letter-spacing: 1px;
            text-transform: uppercase;
			position: relative;
			overflow: hidden;
			display: inline-flex;
			align-items: center;
			box-sizing: border-box;
			width: 106px;
			height: 106px;
			padding: 20px;
			@extend .gradient-main-site;
			white-space: normal;
			text-align: center;
            scroll-snap-align: start;
			&:before{
				content: "\e911";
				@include font();
				font-size: 108px;
				position: absolute;
				color: rgba($c_white,0.2);
			}
			
			&.hidden {
				display: none;
			}

			body.site_noel & {
				background: $c_noel1;
			}
			body.site_vins & {
				background: $c_vins1;
			}
			body.site_velo & {
				background: $c_velo1;
			}
		}
	}
}