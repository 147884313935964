#pwa-content {
	//background: #fff;
}

.pt-page {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
}

.pt-page-current,
.no-js .pt-page {
	visibility: visible;
}

.pt-page-moveToLeft {
	-webkit-animation: moveToLeft .25s ease both;
	animation: moveToLeft .25s ease both;
}

.pt-page-moveFromRight {
	-webkit-animation: moveFromRight .25s ease both;
	animation: moveFromRight .25s ease both;
}
