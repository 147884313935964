.bloc-txt-img {
    display: flex;
    flex-wrap: wrap;

    & + .bloc-txt-img {
        padding-top: 0;
        @include mobile{
            padding-top: $content-spacing/2;
        }
    }

    &.bloc-align-left {
        .bloc-texte {
            padding: 20px 80px 0 0;
            @include tablet_landscape {
                padding: 20px 0 0 0;
            }
        }
    }

    &.bloc-align-right {
        .col-50 {
            &:first-child {
                order: 2;
                @include mobile{
                    order: 1;
                }
            }

            &:last-child {
                order: 1;
                @include mobile{
                    order: 2;
                }
            }
        }

        .bloc-texte {
            padding: 20px 0 0 80px;
            @include tablet_landscape {
                padding: 20px 0 0 0;
            }
        }
    }

    figure {
        background: none;
        position: relative;
    }

    .no-photo {
        width: 100%;
        @include aspect-ratio(1, 1);
    }

    .col-50 {
        position: relative;
        @include clearfix();
    }
}