// STYLE POUR L'INSERTION D'UNE VIDEO EN BACKGROUND
// ==========================================================================
.cover {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 1140px) {
        height: 100%;
    }
    @media (orientation: landscape) and (max-width: 730px) {
        height: 95vh;
    }

    > * {
        z-index: 20;
    }

    &.cover-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:after {
            content: '';
            @extend %center;
            z-index: 20;
            width: 100%;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
        }
    }

    .container-video {
        @include aspect-ratio(16, 9);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background: $c_dark;
        //height: 100%;
        @media screen and (max-aspect-ratio: 165/101) {
            width: 130%;
        }
        @media screen and (max-aspect-ratio: 138/101) {
            width: 140%;
        }
        @media screen and (max-aspect-ratio: 126/72) {
            width: 120%;
        }
        @media screen and (max-aspect-ratio: 128/80) {
            width: 115%;
        }
        @media screen and (max-aspect-ratio: 128/60) {
            width: 115%;
        }
        @media screen and (max-aspect-ratio: 1223/960) {
            width: 110%;
        }
        @media screen and (max-aspect-ratio: 1485/960) {
            width: 155%;
        }
        @media screen and (max-aspect-ratio: 118/101) {
            display: none;
        }
        @include tablet_landscape {
            display: none;
        }

        > video {
            @extend %center;
            z-index: 50;
            @include tablet_landscape {
                display: none;
            }

            &.o10 {
                opacity: 0.9;
            }

            &.o20 {
                opacity: 0.8;
            }

            &.o30 {
                opacity: 0.7;
            }

            &.o40 {
                opacity: 0.6;
            }

            &.o50 {
                opacity: 0.5;
            }

            &.o60 {
                opacity: 0.4;
            }

            &.o70 {
                opacity: 0.3;
            }

            &.o80 {
                opacity: 0.2;
            }
        }

    }

    .container-img {
        figure {
            @extend %center;
            z-index: 10;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}


// BLOC VIDEO
// ==========================================================================
.bloc-video {
    position: relative;

    .mask-video {
        @extend %center;
        cursor: pointer;
        z-index: 10;
        opacity: 1;
        transition: all 0.7s ease-in-out;

        &:before {
            content: '';
            @extend %center;
            background: rgba($c_dark, 0.5);
            z-index: 1;
        }

        &.hide-mask {
            opacity: 0;

            & + .embed-container {
                opacity: 1;
                z-index: 100;
                pointer-events: auto;
            }
        }

        figure {
            height: 100%;
            figcaption {
                display: none;
            }
        }

        .btn-ytbe {
            @extend %center;
            z-index: 10;

            &:hover, &:focus {
                .caption {
                    &:after {
                        background: $c_main;
                        body.site_noel & {
                            background: $c_noel1;
                        }
                        body.site_vins & {
                            background: $c_vins1;
                        }
                        body.site_velo & {
                            background: $c_velo1;
                        }
                    }
                    .basic-link{
                        color: $c_main;
                        &:after{
                            color: $c_main;
                            right: -5px;
                        }

                        body.site_noel & {
                            color: $c_noel1;
                            &:after{
                                color: $c_noel1;
                            }
                        }
                        body.site_vins & {
                            color: $c_vins1;
                            &:after{
                                color: $c_vins1;
                            }
                        }
                        body.site_velo & {
                            color: $c_velo1;
                            &:after{
                                color: $c_velo1;
                            }
                        }
                    }
                }
            }

            .caption {
                right: auto;
                left: 50%;
                transform: translate(-50%, -50%);
                transform: translate3d(-50%,-50%,0);
                text-align: left;
                padding-left: 100px;
                @include mobile{
                    padding-left: 0;
                    &.with-txt{
                        top: auto;
                        left: 20px;
                        bottom: 20px;
                        transform: none;
                    }
                }
                p {
                    @include ft($f_hx, 20px, 700, $c_white, 20px);
                    text-transform: uppercase;
                    @include mobile{
                        font-size: calculateRem(18px);
                        line-height: calculateRem(18px);
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                .basic-link{
                    color: $c_white;
                    margin-bottom: 0;
                }

                &:before {
                    content: "\e906";
                    @include font();
                    z-index: 10;
                    text-align: center;
                    color: $c_white;
                    position: absolute;
                    left: 2px;
                    top: 50%;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    font-size: 40px;
                    margin-top: -40px;
                    @include mobile{
                        position: relative;
                        display: block;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 20px;
                        margin: 0;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    background: rgba($c_dark, 0.6);
                    border-radius: 50%;
                    margin-top: -40px;
                    @include addTransition();
                    @include mobile{
                        top: 0;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 20px;
                        margin-top: -25px;
                        margin: 0;
                    }
                }
            }
        }
    }

    .embed-container {
        @include aspect-ratio(16, 9);
        transition: all 0.7s ease-in-out;

        iframe, video {
            @extend %center;
        }
    }
}

.legend-video {
    @extend .figcaption;
}