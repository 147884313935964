#menu-toggle {
    display: none;

    &:checked {

        & ~ label[for="menu-toggle"] {
            z-index: 10000000000;

            i:nth-child(1) {
                -webkit-animation: inT 0.3s forwards;
                animation: inT 0.3s forwards;
                opacity: 0;
            }

            i:nth-child(2) {
                -webkit-animation: inM 0.3s forwards;
                animation: inM 0.3s forwards;
            }

            i:nth-child(3) {
                -webkit-animation: inBtm 0.3s forwards;
                animation: inBtm 0.3s forwards;
            }
        }

        & ~ nav {
            left: 0;

            > .first-level {
                opacity: 1;

                > li {
                    transform: translate(0, 0);
                    opacity: 1;
                }
            }
        }

        & ~ #header {
            @extend .menu-is-scrolled;
            background: $c_white;
            box-shadow: none;
            @include mobile {
                background: $c_main;

                body.site_noel & {
                    background-color: $c_noel1;
                }
                body.site_vins & {
                    background-color: $c_vins1;
                }
                body.site_velo & {
                    background-color: $c_velo1;
                }
            }

            &:before {
                content: none;
            }

            &:after {
                content: none;
            }

            .menu-pro {
                display: none;
            }

            .logo-alsace {
                @include mobile {
                    display: none;
                }
            }

            .lang {
                li {
                    a {
                        &:hover, &:focus {
                            body:not(.no-hover) & {
                                cursor: pointer;
                                color: $c_main;
                            }
                            body:not(.no-hover).site_noel & {
                                color: $c_noel1;
                            }
                            body:not(.no-hover).site_vins & {
                                color: $c_vins1;
                            }
                            body:not(.no-hover).site_velo & {
                                color: $c_velo1;
                            }
                        }
                    }
                }
            }

            .form-search-mobile {
                pointer-events: auto;
                opacity: 1;
                bottom: 60px;
            }
        }

        & ~ #shadow-nav {
            opacity: 1;
        }

        & ~ #pwa-wrapper #artybtn {
            display: none;
        }
    }
}

#shadow-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 100000;
    display: block;
    background: rgba($c_dark, 0.95);
    @include addTransition(all, 0.8s);
    @include mobile {
        background: $c_dark;
    }
}

nav {
    position: fixed;
    top: $header-height;
    left: 100%;
    bottom: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    z-index: 100000;
    overflow: hidden;
    @include mobile {
        top: 0;
        left: -100%;
        height: calc(100% - 120px);
    }

    input[type="radio"] {
        display: none;
    }

    .title-bar {
        position: relative;
        z-index: 10000;
        text-align: right;
        margin: 0 60px;
        @include mobile {
            text-align: left;
            margin: 0;
            padding: 0 30px;
            background: $c_dark;
        }

        &:hover {
            label {
                span {
                    cursor: pointer;
                    color: $c_white;
                }
            }
        }

        span {
            @include ft($f_txt, 16px, 400, $c_white, 20px);
            position: relative;
            display: inline-block;
            letter-spacing: 2px;
            padding: 30px 0 25px;
            text-transform: uppercase;
            @include addTransition();

            &.is-back {
                padding-left: 30px;

                &:before {
                    content: none;
                    position: absolute;
                    top: 0;
                    left: -30px;
                    right: -30px;
                    bottom: 0;
                    width: calc(100% + 60px);
                    background: $c_dark;
                    z-index: -1;
                    @include mobile {
                        content: '';
                    }
                }

                @include mobile {
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            @include mobile {
                font-size: calculateRem(12px);
                line-height: calculateRem(13px);
            }

            &[class^="icon-"] {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 12px;
            }

        }

        label {
            display: none;
        }
    }

    > .first-level {
        @extend %center;
        box-sizing: border-box;
        padding: 60px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        opacity: 0;
        margin: 0 auto;
        text-align: right;
        @include addTransition();
        @include mobile {
            padding: 60px 30px;
        }

        > li {
            opacity: 0;
            position: relative;
            transform: translate3d(20px, 0, 0);
            transition: all 0.3s ease;
            transition-delay: 1s;

            &:nth-child(2) {
                transition-delay: 0.9s;
            }

            &:nth-child(3) {
                transition-delay: 0.8s;
            }

            &:nth-child(4) {
                transition-delay: 0.7s;
            }

            &:nth-child(5) {
                transition-delay: 0.6s;
            }

            &:nth-child(6) {
                transition-delay: 0.5s;
            }

            &:nth-child(7) {
                transition-delay: 0.4s;
            }

            &:nth-child(8) {
                transition-delay: 0.3s;
            }

            &:nth-child(9) {
                transition-delay: 0.2s;
            }

            &:nth-child(10) {
                transition-delay: 0.1s;
            }

            > label, > a {
                @include ft($f_txt, 26px, 700, $c_white, 34px);
                position: relative;
                display: inline-block;
                padding: 20px 0;
                @include addTransition();
                @include mobile {
                    text-align: left;
                    font-size: calculateRem(20px);
                    line-height: calculateRem(28px);
                    padding: 15px 0;
                    display: block;
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;

                        &:after {
                            width: 100%;
                        }
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    height: 12px;
                    width: 0;
                    background: $c_main;
                    transform-origin: right;
                    z-index: -1;
                    transition: all 300ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
                    /* #CHANGE COLOR THEME */
                    .site_noel & {
                        background: $c_noel2;
                    }
                    .site_vins & {
                        background: $c_vins2;
                    }
                    .site_velo & {
                        background: $c_velo2;
                    }
                }
            }
        }
    }
}

[id^="sous-menu-"], [id*="sous-menu-"] {
    @extend %center;
    overflow: auto;
    transform: translateX(100%);
    transform: translate3d(100%, 0, 0);
    transform-origin: right;
    z-index: 1000;
    margin: 0 auto;
    padding: 60px;
    @include addTransition();
    @include mobile {
        transform: translateX(-100%);
        transform: translate3d(-100%, 0, 0);
        padding: 60px 30px;
    }

    ul {
        &.columns-menu {
            @include min-tablet_landscape {
                max-width: 800px;
                margin: 0;
                margin-left: calc(100% - 800px);
                column-count: 2;
                column-gap: 50px;
            }
        }

        li {
            text-align: right;
            @include mobile {
                text-align: left;
            }

            label, a {
                position: relative;
                @include ft($f_txt, 26px, 700, $c_white, 34px);
                display: inline-block;
                padding: 20px 0;
                text-align: right;
                @include mobile {
                    text-align: left;
                    padding: 15px 0;
                    font-size: calculateRem(20px);
                    line-height: calculateRem(28px);
                    display: block;
                }

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        cursor: pointer;

                        &:after {
                            width: 100%;
                        }
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    height: 12px;
                    width: 0;
                    background: $c_main;
                    transform-origin: right;
                    z-index: -1;
                    transition: all 300ms cubic-bezier(1.000, 0.000, 0.000, 1.000);
                    /* #CHANGE COLOR THEME */
                    .site_noel & {
                        background: $c_noel2;
                    }
                    .site_vins & {
                        background: $c_vins2;
                    }
                    .site_velo & {
                        background: $c_velo2;
                    }

                    @media screen and (min-width: 992px) and (max-height: 650px) {
                        height: 8px;
                        bottom: 12px;
                    }
                }
            }
        }
    }
}

#menu-item-menu {
    &:checked {
        & ~ .title-bar {
            display: block;
            transform: none;
        }

        & ~ .first-level {
            transform: none;
        }

        & ~ #fast-search-algolia {
            left: 100%;
        }
    }
}

@for $i from 0 through 8 {

    #menu-back-#{$i} {
        &:checked {
            & ~ .title-bar {
                display: block;
                transform: none;
            }

            & ~ .first-level {
                transform: none;
            }

            & ~ #fast-search-algolia {
                left: 100%;
            }
        }
    }

    #menu-item-#{$i}:checked ~ #sous-menu-#{$i} {
        transform: none;
    }

    @for $j from 0 through 8 {
        #menu-item-#{$i}-#{$j}:checked ~ #sous-menu-#{$i}-#{$j},
        #menu-back-#{$i}-#{$j}:checked ~ #sous-menu-#{$i - 1} {
            transform: none;
        }
    }

    #menu-item-#{$i}:checked ~ .first-level {
        transform: translateX(-100%);
        transition-delay: 0s;
        @include min-tablet_landscape {
            transform: translateX(100%);
        }
    }

    @for $j from 0 through 8 {
        #menu-item-#{$i}-#{$j}:checked ~ .first-level,
        #menu-back-#{$i}-#{$j}:checked ~ .first-level {
            transform: translateX(-200%);
            transition-delay: 0s;
            @include min-tablet_landscape {
                transform: translateX(200%);
            }
        }
    }

    #menu-item-#{$i}:checked ~ .title-bar {
        > label[for="menu-back-#{$i}"] {
            display: block;
        }

        > span {
            display: none;
        }
    }

    @for $j from 0 through 8 {
        #menu-item-#{$i}-#{$j}:checked ~ .title-bar {
            > label[for="menu-back-#{$i}-#{$j}"] {
                display: block;
            }

            > span {
                display: none;
            }
        }

        #menu-back-#{$i}-#{$j}:checked ~ .title-bar {
            > label[for="menu-back-#{$i - 1}"] {
                display: block;
            }

            > span {
                display: none;
            }
        }
    }

}

#fast-search-algolia {
    position: absolute;
    right: 0;
    top: 0;
    left: 100%;
    z-index: 10000;
    overflow: hidden;
    transform: translate(0, 0);
    padding-top: 30px;
    transition: all 0.5s ease-in-out;
    @include mobile {
        padding-top: 0;
    }
}

.algolia-search {
    #menu-item-menu:checked {
        ~ [id^="sous-menu-" ], ~ [id*="sous-menu-"] {
            transform: translateX(100%) !important;
            @include tablet_landscape {
                transform: translateX(-100%) !important;
            }
        }
    }

    > * {
        transform: translateX(100%) !important;
        @include tablet_landscape {
            transform: translateX(-100%) !important;
        }
    }

    #menu-item-menu:checked ~ #fast-search-algolia {
        left: 0;
    }

    #menu-item-menu:checked ~ .first-level {
        transform: translateX(100%) !important;
        @include tablet_landscape {
            transform: translateX(-100%);
        }
    }

    #menu-item-menu:checked ~ .title-bar, .title-bar {
        transform: translateX(100%) !important;
        @include mobile {
            transform: translateX(-100%);
        }
    }

    .first-level {
        transform: translateX(100%);
        @include mobile {
            transform: translateX(-100%) !important;
        }
    }

    #fast-search-algolia {
        left: 0;
        transform: translate(0, 0) !important;
        transform: translate3d(0, 0, 0) !important;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: 100%;
    }
}

#fast-search-algolia {
    .wrapper {
        &:after{
            content: '';
            display: block;
            width: 100%;
            height: 100px;
        }
        > p {
            @include ft($f_hx, 16px, 700, $c_white, 24px);
            padding: 75px 0;
            text-align: center;
            margin: 0;
        }
    }
}