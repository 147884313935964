.listing-cards{
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
    @include clearfix();
    .card {
        float: left;
		margin: 10px;
        width: calc(33.33% - 20px);
        &:nth-child(3n+1){
            clear: left;
        }
        @include wide{
            width: calc(25% - 20px);
            &:nth-child(3n+1){
                clear: none;
            }
            &:nth-child(4n+1){
                clear: left;
            }
        }
        @include tablet_portrait{
            width: calc(50% - 20px);
            &:nth-child(3n+1){
                clear: none;
            }
            &:nth-child(odd){
                clear: left;
            }
        }
        @include mobile{
            width: calc(100% - 20px);
            float: none;
            clear: none;
            &:last-child{
                margin-bottom: 0;
            }
        }
	}
}