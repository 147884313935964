@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon/fonts/icomoon.ttf?rr8o8q') format('truetype'),
	url('../fonts/icomoon/fonts/icomoon.woff?rr8o8q') format('woff'),
	url('../fonts/icomoon/fonts/icomoon.svg?rr8o8q#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-ico-position:before {
	content: "\e90a";
}

.icon-attention:before {
	content: "\e964";
}

.icon-denivele:before {
	content: "\e965";
}

.icon-distance:before {
	content: "\e966";
}

.icon-meteo:before {
	content: "\e967";
}

.icon-ombrage:before {
	content: "\e968";
}

.icon-signalement:before {
	content: "\e969";
}

.icon-temps:before {
	content: "\e96a";
}

.icon-velo:before {
	content: "\e96b";
}

.icon-ico-web:before {
	content: "\e963";
}

.icon-ico-check-round:before {
	content: "\e960";
}

.icon-ico-notcheck-round:before {
	content: "\e961";
}

.icon-ico-photo:before {
	content: "\e962";
}

.icon-ico-footer-moins:before {
	content: "\e95e";
}

.icon-ico-footer-plus:before {
	content: "\e95f";
}

.icon-ico-conseil:before {
	content: "\e91a";
}

.icon-icon-animaux-acceptes:before {
	content: "\e93a";
}

.icon-icon-climatisation:before {
	content: "\e93b";
}

.icon-icon-cuisine-vegetarienne:before {
	content: "\e93c";
}

.icon-icon-cuisinne-alsacienne:before {
	content: "\e93d";
}

.icon-icon-denivele:before {
	content: "\e93e";
}

.icon-icon-duree-circuit:before {
	content: "\e93f";
}

.icon-icon-duree-visite:before {
	content: "\e940";
}

.icon-icon-longueur-circuit:before {
	content: "\e941";
}

.icon-icon-niveau-difficile:before {
	content: "\e942";
}

.icon-icon-niveau-facile:before {
	content: "\e943";
}

.icon-icon-niveau-intermediaire:before {
	content: "\e946";
}

.icon-icon-parking-velo:before {
	content: "\e948";
}

.icon-icon-parking:before {
	content: "\e94c";
}

.icon-icon-piscine:before {
	content: "\e94d";
}

.icon-icon-poisson:before {
	content: "\e94e";
}

.icon-icon-restaurant-gastronomique:before {
	content: "\e94f";
}

.icon-icon-restaurant:before {
	content: "\e950";
}

.icon-icon-sans-gluten:before {
	content: "\e951";
}

.icon-icon-spa:before {
	content: "\e952";
}

.icon-icon-tarte-flambee:before {
	content: "\e953";
}

.icon-icon-wifi:before {
	content: "\e954";
}

.icon-fleche:before {
	content: "\e95c";
}

.icon-chatbot:before {
	content: "\e95b";
}

.icon-ico-route-velo:before {
	content: "\e95a";
}

.icon-ico-vignobles-et-decouvertes:before {
	content: "\e959";
}

.icon-ico-deguster:before {
	content: "\e956";
}

.icon-ico-hebergements-coeur:before {
	content: "\e957";
}

.icon-ico-photographier:before {
	content: "\e958";
}

.icon-ico-round-chevron-left:before {
	content: "\e94b";
}

.icon-ico-noel-train:before {
	content: "\e945";
}

.icon-ico-noel-agenda:before {
	content: "\e947";
}

.icon-ico-noel-restaurant:before {
	content: "\e94a";
}

.icon-ico-duree-circuit:before {
	content: "\e928";
}

.icon-ico-longueur-circuit:before {
	content: "\e944";
}

.icon-ico-montagne:before {
	content: "\e939";
}

.icon-ico-date-event:before {
	content: "\e938";
}

.icon-ico-download:before {
	content: "\e937";
}

.icon-ico-calendar:before {
	content: "\e936";
}

.icon-ico-cheque-vacances:before {
	content: "\e935";
}

.icon-ico-animal:before {
	content: "\e930";
}

.icon-ico-credit-card:before {
	content: "\e931";
}

.icon-ico-info:before {
	content: "\e932";
}

.icon-ico-parking:before {
	content: "\e933";
}

.icon-ico-restaurant:before {
	content: "\e934";
}

.icon-ico-aeroport:before {
	content: "\e92d";
}

.icon-ico-autoroute:before {
	content: "\e92e";
}

.icon-ico-train:before {
	content: "\e92f";
}

.icon-ico-view-map:before {
	content: "\e92c";
}

.icon-ico-search:before {
	content: "\e92b";
}

.icon-ico-filtre:before {
	content: "\e92a";
}

.icon-ico-top:before {
	content: "\e929";
}

.icon-cs-epis:before {
	content: "\e920";
	color: #81a52a;
}

.icon-cs-key:before {
	content: "\e921";
	color: #f95e24;
}

.icon-ico-star:before {
	content: "\e922";
	color: #f0a800;
}

.icon-ico-difficulte:before {
	content: "\e923";
}

.icon-ico-distance:before {
	content: "\e924";
}

.icon-ico-duree:before {
	content: "\e925";
}

.icon-ico-location:before {
	content: "\e926";
}

.icon-ico-restauration:before {
	content: "\e927";
}

.icon-ico-transport:before {
	content: "\e949";
}

.icon-ico-credit:before {
	content: "\e91f";
}

.icon-ico-gallery-cross:before {
	content: "\e91c";
}

.icon-ico-gallery-left-chevron:before {
	content: "\e91d";
}

.icon-ico-gallery-right-chevron:before {
	content: "\e91e";
}

.icon-ico-social-share:before {
	content: "\e918";
}

.icon-ico-home:before {
	content: "\e916";
}

.icon-ico-plus:before {
	content: "\e915";
}

.icon-ico-brochure:before {
	content: "\e900";
}

.icon-ico-check:before {
	content: "\e901";
}

.icon-ico-comment-venir:before {
	content: "\e902";
}

.icon-ico-facebook:before {
	content: "\e903";
}

.icon-ico-heart:before {
	content: "\e904";
}

.icon-ico-instagram:before {
	content: "\e905";
}

.icon-ico-lecteur:before {
	content: "\e906";
}

.icon-ico-logo-alsace:before {
	content: "\e907";
}

.icon-ico-pass:before {
	content: "\e908";
}

.icon-ico-pinterest:before {
	content: "\e909";
}

.icon-ico-position:before {
	content: "\e90a";
}

.icon-ico-share:before {
	content: "\e90b";
}

.icon-ico-three-dots:before {
	content: "\e90c";
}

.icon-ico-twitter:before {
	content: "\e90d";
}

.icon-ico-youtube:before {
	content: "\e90e";
}

.icon-arrow-bold-up:before {
	content: "\e955";
}

.icon-chevron-down:before {
	content: "\e90f";
}

.icon-chevron-left:before {
	content: "\e910";
}

.icon-chevron-right:before {
	content: "\e911";
}

.icon-chevron-thin-left:before {
	content: "\e912";
}

.icon-chevron-thin-right:before {
	content: "\e913";
}

.icon-chevron-up:before {
	content: "\e914";
}

.icon-mail:before {
	content: "\e919";
}

.icon-map:before {
	content: "\e917";
}

.icon-linkedin:before {
	content: "\e91b";
}

.icon-vimeo:before {
	content: "\e95d";
}

.icon-print:before {
	content: "\e96c";
}

.icon-cross:before {
	content: "\e96d";
}

.icon-rotate:before {
	content: "\e96e";
}