.page-carte{
	position: absolute;
	top: $header-height;
	left: 0;
	right: 0;
	bottom: 0;
    @include mobile{
        top: 0;
    }
	.map{
        position: relative;
		height: calc(100vh - #{$header-height});
        @include mobile{
            height: calc(100vh - 60px);
        }
		.maps{
			@extend %center;
		}
	}

	.btn-filtre{
		position: absolute;
		top: 20px;
		right: 20px;
	}

	.btn-print{
		position: absolute;
		top: 20px;
		right: 190px;
		&:before {
			content: "\e96c";
		}

		@include tablet_landscape {
			display: none;
		}
	}

	.overlay-filtre{
		@include min-tablet_portrait{
			max-width: 400px;
		}
		.content{
			div{
				ul{
					li{
						width: 100%;
						float: none;
						cursor: pointer;
						&:nth-child(even){
							margin: 0;
						}
						label{
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.data-bottom{
		position: absolute;
		top: auto;
		bottom: 20px;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 1000000;
        display: none;
		@include mobile{
			display: block;
		}

		&.critere-display{
			.cards-mini{
				margin: 10px 27px;
			}
		}
		.listing-map-mobile{
			position: relative;
		}

		.cards-mini{
			margin: 0 20px;
            .card{
                @include cardHorinzontale();
                .title-card{
                    width: 100%;
                }
            }
		}

		.wrapper-critere{
			position: relative;
			padding: 0 27px;
			margin: 0;
			z-index: 10000;
			-webkit-overflow-scrolling: touch;
			li{
				&:last-child{
					margin-right: 27px;
				}
			}
		}

        .close{
            position: absolute;
            right: 20px;
            width: 30px;
            height: 30px;
            background: $c_white;
            border-radius: 50%;
            box-shadow: 0 5px 10px rgba($c_dark,0.2);
            z-index: 10;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            margin-top: -15px;
            margin-right: -10px;
            span{
                display: block;
            }
        }
	}
}



.algolia-place{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 380px;
    z-index: 1000;
    @include mobile{
        display: none;
    }
    &:before{
        content: "\e92b";
        @include font();
        font-size: 22px;
        color: $c_dark;
        position: absolute;
        top: 50%;
        left: 17px;
        z-index: 10;
        margin-top: -10px;
    }
    input{
        @include ft($f_txt, 14px, 400, $c_txt, 20px);
        @extend %appearance;
        box-sizing: border-box;
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 13px 55px;
        background: $c_white;
        border: 1px solid $c_border;
        height: 48px;
        text-overflow: ellipsis;
        @include addTransition();
        &::placeholder {
			text-transform: uppercase;
			@include ft($f_hx, 12px, 500, $c_txt, 20px);
			position: relative;
			letter-spacing: 1px;
        }
        &:hover, &:focus {
			body:not(.no-hover) & {
				border-color: $c_main;
			}
			body:not(.no-hover).site_noel & {
				border-color: $c_noel1;
			}
			body:not(.no-hover).site_vins & {
				border-color: $c_vins1;
			}
			body:not(.no-hover).site_velo & {
				border-color: $c_velo1;
			}
        }
        & ~ button{
            display: none;
        }
    }

	.algolia-place__results {
		position: absolute;
		top: 48px;
		left: 0;
		width: 100%;

		li {
			width: 100%;
			box-sizing: border-box;

			button {
				padding: 12px 20px;
				text-align: left;
				width: 100%;
				height: 100%;
				outline: 0;
				border: none;
				background: white;
				@include ft($f_txt, 14px, 400, $c_txt, 20px);
				@include addTransition();
				cursor: pointer;
			}

			&:last-child {
				border-left: 1px solid $c_border;
				border-right: 1px solid $c_border;
				border-bottom: 1px solid $c_border;
			}

			&:not(:last-child) {
				border-left: 1px solid $c_border;
				border-right: 1px solid $c_border;

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					height: 2px;
					width: 80%;
					transform: translateX(-50%);
					background: $c_grey;
                    @include addTransition();
                }
			}

			&:hover, &:focus {
                body:not(.no-hover) & {
                    &:not(:last-child){
                        &:before{
                            background: $c_main;
                        }
                    }
                    button {
                        cursor: pointer;
                        color: $c_white;
                        background: $c_main;
                    }
                }
                body:not(.no-hover).site_noel & {
                    background: $c_noel1;
                    &:not(:last-child){
                        &:before{
                            background: $c_noel1;
                        }
                    }
                    button{
                        background: $c_noel1;
                    }
                }
                body:not(.no-hover).site_vins & {
                    &:not(:last-child){
                        &:before{
                            background: $c_vins1;
                        }
                    }
                    button{
                        background: $c_vins1;
                    }
                }
                body:not(.no-hover).site_velo & {
                    &:not(:last-child){
                        &:before{
                            background: $c_velo1;
                        }
                    }
                    button{
                        background: $c_velo1;
                    }
                }
			}
		}
	}

    .geo-loc{
        @extend %appearance;
        background: none;
        position: absolute;
        z-index: 50;
        top: 50%;
        right: 20px;
        font-size: 20px;
        margin-top: -12px;
        color: $c_dark;
        padding: 2px 0;
        @include addTransition();
        &:hover, &:focus{
			body:not(.no-hover) & {
				cursor: pointer;
				color: $c_main;
			}
			body:not(.no-hover).site_noel & {
				color: $c_noel1;
			}
			body:not(.no-hover).site_vins & {
				color: $c_vins1;
			}
			body:not(.no-hover).site_velo & {
				color: $c_velo1;
			}
        }
        span{
            display: block;
        }
    }
}

.overlay-print {

	--overlay-print-horizontal-size: 1045px;
	--overlay-print-vertical-size: 715px;

	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: var(--overlay-print-horizontal-size);
	height: var(--overlay-print-vertical-size);
	max-height: 100vh;
	max-width: 100vw;
	z-index: 111111;
	pointer-events: none;

	&[data-print-overlay-show=false] {
		display: none;
	}

	&::before, &::after {
		content: "";
		position: fixed;
		height: calc(100vh - var(--overlay-print-vertical-size));
		width: 100%;
		left: 0;
		background: black;
		opacity: 0.2;
		pointer-events: fill;
	}

	&::before {
		bottom: var(--overlay-print-vertical-size);
	}

	&::after {
		top: var(--overlay-print-vertical-size);
	}

	&__left, &__right {
		position: absolute;
		top: calc(((100vh - var(--overlay-print-vertical-size)) / -2));
		height: 100vh;
		width: calc(100vw - (var(--overlay-print-horizontal-size) / 2));
		background: black;
		opacity: 0.2;
		pointer-events: fill;
	}

	&__right {
		left: 100%;
	}

	&__left {
		right: 100%;
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $c_grey;
		padding: 25px 30px;
		pointer-events: fill;

		.text {
			@include ft($f_hx, 20px, 700, $c_txt, 40px);
			text-transform: uppercase;
		}
		.close {
			color: $c_velo1;
			font-size: 16px;
			cursor: pointer;
		}
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		background-color: $c_grey;
		pointer-events: fill;
		padding: 15px 20px;

		.text {
			@include ft($f_hx, 12px, 300, $c_txt, 15px);
			font-style: italic;
			width: 250px;
		}

		.overlay-print__buttons {
			display: flex;
		}

		.btn {
			position: relative;
			z-index: 2;
			pointer-events: fill;
			cursor: pointer;

			color: #000;
			line-height: 20px;
			line-height: 1.25rem;
			position: relative;
			z-index: 10000;
			letter-spacing: 1px;
			box-sizing: border-box;
			border: 1px solid #BABABA;
			text-transform: uppercase;
			display: inline-block;
			text-align: left;
			padding: 3px 70px 4px 20px;
			background: #fff;
			min-width: 155px;
			transition: all .2s ease;

			&::before {
				@include font();
				color: $c_main;
				position: absolute;
				right: 15px;
				font-size: 14px;
				top: 50%;
				margin-top: -8px;
				@include addTransition();
			}

			&:hover, &:focus{
				body:not(.no-hover) & {
					background: $c_main;
					color: $c_white;
					background: $c_velo2;
					border-color: $c_velo2;

					&::before {
						color: white;
					}
				}
			}
		}

		.btn--rotate {
			&:before {
				content: "\e96e";
			}

		}

		.btn--print {
			margin-left: 10px;
			&:before {
				content: "\e96c";
			}
		}
	}

	&__move-map {
		flex: 1;
	}
}
