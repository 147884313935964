@font-face {
	font-family: "iconvault";
	src: url("../fonts/weather/iconvault_forecastfont.eot");
	src: url("../fonts/weather/iconvault_forecastfont.eot?#iefix") format("embedded-opentype"),
	url("../fonts/weather/iconvault_forecastfont.woff") format("woff"),
	url("../fonts/weather/iconvault_forecastfont.ttf") format("truetype"),
	url("../fonts/weather/iconvault_forecastfont.svg#iconvault") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

[class^="meteo-"],
[class*=" meteo-"] {
	font-family: 'iconvault';
	font-weight: normal;
	font-style: normal;
	text-decoration: inherit;
	-webkit-font-smoothing: antialiased;
	font-size: 26px;


	&:before, &:after {
		color: $c_main;
		font-size: 25px;
		font-weight: 400;
	}
}

@media only screen and (min-width: 479px) and (max-width: 678px) {
	[class^="meteo-"],
	[class*=" meteo-"] {
		font-family: 'iconvault';
		font-weight: normal;
		font-style: normal;
		text-decoration: inherit;
		-webkit-font-smoothing: antialiased;
	}
}

.data-icon {
	position: relative;
	display: inline-block;
	min-width: 60px;
	height: 60px;

	> * {
		display: block;
		position: absolute;
		left: 0;
		@include addTransition();

		&:after, &:before {
			font-weight: 500;
			color: $c_dark;
		}
	}
}

/* -----BaseCloud----- */

.meteo-basecloud {
	font-size: inherit;
}

.meteo-basecloud:before {
	font-family: 'iconvault';
	content: '\f105';
	position: relative;
}

/* -----windyraincloud----- */

.meteo-windyraincloud:before {
	font-family: 'iconvault';
	font-size: 6em;
	position: relative;
}

/* -----Windysnowcloud----- */

.meteo-windysnowcloud:before {
	font-family: 'iconvault';
	content: '\f109';
	position: relative;
}

/* -----Basethundercloud----- */

.meteo-basethundercloud:before {
	font-family: 'iconvault';
	content: '\f105';
	position: relative;

}

/* -----Thunder----- */

.meteo-thunder::before {
	content: "\f114";
	position: relative;
}

/* -----Sunny----- */

.meteo-sunny::after {
	content: "\f101";
	position: relative;
}


/* -----Drizzle----- */

.meteo-drizzle::before {
	content: "\f10a";
	position: relative;
}

/* -----Hail----- */

.meteo-hail::before {
	content: "\f10f";
	position: relative;
}

/* -----Showers----- */

.meteo-showers::before {
	content: "\f104";
	position: relative;
}

/* -----Rainy----- */

.meteo-rainy::before {
	content: "\f107";
	position: relative;
}

/* -----Snowy----- */

.meteo-snowy::before {
	content: "\f10b";
	position: relative;
}

/* -----Frosty----- */

.meteo-frosty::before {
	content: "\f102";
	position: relative;
}

/* -----Windy----- */

.meteo-windy::before {
	content: "\f115";
	position: relative;
}

/* -----WindyRain----- */

.meteo-windyrain::before {
	content: "\f10e";
	position: relative;
}

/* -----WindySnow----- */

.meteo-windysnow::before {
	content: "\f103";
	position: relative;
}

/* -----Sleet----- */

.meteo-sleet::before {
	content: "\f10c";
	position: relative;
}

/* -----Moon----- */

.meteo-moon::after {
	content: "\f10d";
	position: relative;
}

/* -----Night----- */

.meteo-night::after {
	content: "\f100";
	position: relative;
}


/* -----Sun----- */

.meteo-sun::after {
	content: "\f113";
	position: relative;
}

/* -----Cloud----- */

.meteo-cloud::after {
	content: "\f106";
	position: relative;
}


/* -----Sunrise----- */

.meteo-sunrise:before {
	content: '\f112';
	position: relative;
}

/* -----Sunset----- */

.meteo-sunset:before {
	content: '\f110';
	position: relative;
}

/* -----Mist----- */

.meteo-mist:before {
	content: '\f108';
	position: relative;
}