.bloc-zoom {
    figcaption, .credits {
        display: none;
    }

    > input {
        display: none;
    }

    $zoomInputs: 0, 1, 2, 3, 4, 5, 6;
    @each $zoomInput in $zoomInputs {
        input[value="#{$zoomInput}"]:checked ~ .th-slider-wrapper [for^="zoom-#{$zoomInput}"], input[value="#{$zoomInput}"]:checked {
            background: $c_white;
        }
        input[value="#{$zoomInput}"]:checked ~ .zoom-#{$zoomInput} {
            display: flex;
            @include mobile{
                display: block;
            }
        }

    }


    // IMAGE
    .content-top {
        position: relative;
        display: none;
        align-items: stretch;
        min-height: 580px;
        @include mobile{
            min-height: auto;
        }
        figure, .no-photo {
            @include aspect-ratio(800, 500);
            flex: none;
            width: calc(100% - 400px);
            margin: 40px 0;
            @include tablet_landscape{
                width: calc(100% - 340px);
            }
            @include mobile{
                width: 100%;
                margin: 0;
            }
        }

        figure {
            img {
                @extend %center;
            }
        }
    }

    // CAPTION AND TEXT
    .bloc-texte {
        flex: none;
        padding: 40px;
        width: 400px;
        @extend .background-alsace-2022;
        @extend %bloc-texte-white;
        @include tablet_landscape{
            width: 340px;
            margin-bottom: 80px;
        }
        @include mobile{
            width: 100%;
            padding: 30px;
            margin-bottom: 0;
        }

        body.site_noel & {
            background: $c_noel3;
        }
        body.site_vins & {
            background: $c_vins1;
        }
        body.site_velo & {
            background: $c_velo1;
        }

        .surtitre {
            @include ft($f_txt, 20px, '', '', 24px);
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .title-caption {
            @include ft($f_hx, 30px, 700, '', 30px);
            text-transform: uppercase;
            display: block;
        }
    }

    // SLIDER BOTTOM
    // ========================================================
    .th-slider-wrapper {
        position: relative;
        z-index: 100;
        width: calc(100% - 150px - 400px);
        margin-left: 75px;
        margin-top: -80px;
        @include desktop{
            width: calc(100% - 70px - 400px);
            margin-left: 40px;
        }
        @include tablet_landscape{
            margin-left: -40px;
            margin-right: -40px;
            width: calc(100% + 80px);
        }
        @include mobile{
            margin-top: 10px;
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
        .item {
            width: 190px;
            height: 110px;
            border: 10px solid $c_white;
            margin-right: 10px;
            display: inline-block;
            @include tablet_landscape{
                width: 160px;
                height: 80px;
            }
            @include mobile{
                border: none;
            }

            &:hover {
                body:not(.no-hover) & {
                    cursor: pointer;

                    figure {
                        img {
                            opacity: 0.5;
                        }
                    }
                }
            }

            figure, .no-photo {
                @include aspect-ratio(190, 110);
                @include tablet_landscape{
                    @include aspect-ratio(160, 80);
                }

                img {
                    @extend %center;
                    @include addTransition();
                }
            }
        }

        .nav-dots {
            position: relative;
            bottom: 0;
            margin-top: 15px;

            button {
                background: rgba($c_dark, 0.3);

                &:hover, &:focus {
                    body:not(.no-hover) & {
                        background-color: $c_dark;
                    }
                }

                &.active {
                    background: $c_dark;
                }
            }
        }
    }

}
