.page-contact{
	header{
        #breadcrumb{
            span, a{
                color: $c_txt;
            }
            a{
                &:hover, &:focus{
                    color: $c_main;
                    /* #CHANGE COLOR THEME */
                    .site_noel & {
                        color: $c_noel2;
                    }
                    .site_vins & {
                        color: $c_vins2;
                    }
                    .site_velo & {
                        color: $c_velo2;
                    }
                }
            }
        }
	}


    // Zone de contenu
	.wrapper{
        @include clearfix();
        padding-top: 40px;
        @include mobile{
            padding-top: 20px;
        }
		.bloc-form{
            float: left;
            width: calc(100% - 300px);
            @include tablet_portrait{
                width: calc(100% - 260px);
            }
            @media screen and (max-width: 800px){
                float: none;
                width: 100%;
            }
		}

		aside{
            position: relative;
			margin: 25px 0 0 20px;
            width: 280px;
            float: left;
            @extend .background-alsace-2022;
            @include tablet_portrait{
                width: 240px;
            }
            @media screen and (max-width: 800px){
                float: none;
                width: 100%;
                margin: 40px 0 0 0;
            }
            @include mobile{
                margin-bottom: 20px;
            }

			body.site_noel & {
				background: $c_noel3;
			}
			body.site_vins & {
				background: $c_vins1;
			}
			body.site_velo & {
				background: $c_velo1;
			}

			.txt{
				padding: 25px 30px 30px 30px;
				.title-aside{
					@include ft($f_hx, 30px, 700, $c_white, 30px);
                    text-transform: uppercase;
                    margin: 0 0 20px 0;
                    @include tablet_portrait{
                        font-size: calculateRem(20px);
                        line-height: calculateRem(20px);
                    }
                    @include mobile{
                        font-size: calculateRem(30px);
                        line-height: calculateRem(30px);
                    }
				}
				a{
					@include ft($f_txt, 14px, 700, $c_white, 20px);
					border: 1px solid $c_white;
                    letter-spacing: 1px;
					padding: 4px 12px 6px;
					display: inline-block;
					text-align: center;
					@include addTransition();
					&:hover, &:focus{
						body:not(.no-hover) & {
                            background: $c_white;
                            color: $c_main;
                        }

						body:not(.no-hover).site_noel & {
							color: $c_noel3;
						}
						body:not(.no-hover).site_vins & {
							color: $c_vins1;
						}
						body:not(.no-hover).site_velo & {
							color: $c_velo1;
						}
					}
				}
			}
			.maps{
				@include aspect-ratio(280,232);
                > * {
                    @extend %center;
                }
			}
		}
	}
}