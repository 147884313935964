.bloc-instagram{
    .th-slider{
        div{
            margin: 0 20px 0 0;
            width: 340px;
            display: inline-block;
            vertical-align: top;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}