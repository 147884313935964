/* ------------------
--- MIXINS ---
------------------ */

@mixin ft($family:"", $size:"", $weight:"", $color:"", $lineheight:"") // define font
{
    @if $family != "" {
        font-family: $family;
    }
    @if $size != "" {
        @include font-size($size);
    }
    @if $weight != "" {
        font-weight: $weight;
    }
    @if $color != "" {
        color: $color;
    }
    @if $lineheight != "" {
        @include line-height($lineheight);
    }
}

@mixin absolute() {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

@mixin font() {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


@mixin ellipsis($number:"",$lineheight:""){
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    @if $number != "" {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $number;
    }
    @if $lineheight != "" {
        line-height: $lineheight;
        max-height: $lineheight * $number;
    }
}


// Define default font size
@function calculateRem($size) {
    //$remSize: $size / 16px;
    //@return $remSize * 1rem;
    @return $size; //Pour une raison inconue c'est pété
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin line-height($size) {
    line-height: $size;
    line-height: calculateRem($size);
}


// Mixin Breakpoints
// ==========================================================================
@mixin wide {
    @media screen and (min-width: #{$breakpoint_wd}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: #{$max-width + $border-gutter + $border-gutter}) {
        @content;
    }
}

@mixin tablet_landscape {
    @media screen and (max-width: #{$breakpoint_paysage}) {
        @content;
    }
}

@mixin tablet_portrait {
    @media screen and (max-width: #{$breakpoint_portrait}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$breakpoint_mo}) {
        @content;
    }
}

@mixin mobile_small {
    @media screen and (max-width: #{$breakpoint_mo_small}) {
        @content;
    }
}

// Approche Mobile First
// ==========================================================================
@mixin min-mobile {
    @media screen and (min-width: #{$breakpoint_mo}) {
        @content;
    }
}

@mixin min-tablet_portrait {
    @media screen and (min-width: #{$breakpoint_portrait}) {
        @content;
    }
}

@mixin min-tablet_landscape {
    @media screen and (min-width: #{$breakpoint_paysage}) {
        @content;
    }
}

@mixin min-desktop {
    @media screen and (min-width: #{$breakpoint_desktop}) {
        @content;
    }
}

@mixin min-large-desktop {
    @media screen and (min-width: #{$breakpoint_wd}) {
        @content;
    }
}

// Mixin Transition effect
// ==========================================================================
@mixin addTransition($effect: all, $duration: 0.2s, $easeing:ease) {
    $anim: $effect $duration $easeing;
    transition: $anim;
}

@mixin addTransitionCurve($effect: all, $duration: 0.3s, $easeing:cubic-bezier(1.000, 0.000, 0.000, 1.000)) {
    $anim: $effect $duration $easeing;
    transition: $anim;
}

// Mixin Aspect-ratio pour les images
// ==========================================================================
@mixin aspect-ratio($width, $height, $add: 0px) {
    position: relative;
    overflow: hidden;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-bottom: $add;
        padding-top: ($height / $width) * 100%;
    }
    > .content, > .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// COLORS
@mixin addColored($className,$color){
	.#{$className} {

	}
}

@mixin MixinGradient($color1, $color2){
	background: $color1;
	background: -moz-linear-gradient(-45deg, $color1 0%, $color2 100%);
	background: -webkit-linear-gradient(-45deg, $color1 0%,$color2 100%);
	background: linear-gradient(135deg, $color1 0%,$color2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e31936', endColorstr='#e31964',GradientType=1 );
}

// CARD HORIZONTALE
// ==============================================================================
@mixin cardHorinzontale() {
    display: flex;
    align-items: center;
    width: 100%;
    height: 108px;
    background: $c_white;
    overflow: hidden;
    box-shadow: 0 0 10px rgba($c_dark,0.1);
    &:before {
        content: none;
    }

    figure, .no-photo{
        @include aspect-ratio(1,1);
        flex: none;
        width: 108px;
        height: 100%;
        @include mobile_small{
            width: 90px;
        }
    }

    figure {
        &:before{
            content: none;
        }
        img{
            @extend %center;
        }
    }

    .caption-txt, .bottom {
        position: relative;
        box-sizing: border-box;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border: none;
        flex: 1;
        white-space: normal;
        padding: 13px 15px;
        .title-card{
            font-size: calculateRem(12px);
            line-height: calculateRem(12px);
            font-weight: 500;
            color: $c_txt;
            margin-bottom: 10px;
            width: 100%;
        }

        .location, .city{
            white-space: nowrap;
            display: block;
            width: calc(100% - 50px);
            text-overflow: ellipsis;
            overflow: hidden;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
        }

        .city{
            @extend .location;
        }

        .difficulte {
            top: 10px;
            font-size: 11px;

        }
        .opening {
            position: absolute;
            right: 15px;
            top: 5px;
        }

        .duree {
            font-size: 12px;
            position: absolute;
            top: 11px;
            left: 15px;
            &:before {
                font-size: 14px;
                margin-right: 5px;
            }
        }
        .faitguest {
            p, .eval {
                display: none;
            }
        }
    }

    .price{
        padding: 5px 10px;
        max-width: 108px;
        box-sizing: border-box;
        white-space: normal;
        span{
            font-size: calculateRem(20px);
            line-height: calculateRem(20px);
            span{
                font-size: calculateRem(12px);
                line-height: calculateRem(14px);
            }
        }
    }

    .date-event{
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 0;
        .surtitre{
            font-size: calculateRem(18px);
            line-height: calculateRem(22px);
        }
        span{
            font-size: 13px;
            line-height: 16px;
            color: $c_white;
            &.year{
                font-size: 11px;
                line-height: 14px;
            }
        }
    }

    .faitguest{
        display: none;
    }
    
    .other-dates{
        display: none;
    }
}