.bloc-diaporama{
	position: relative;
	overflow: hidden;
    .content-wrapper{
        overflow: hidden;
    }

    .th-slider.scroll-mode{
        @include mobile{
            width: calc(100% - 40px);
            margin-left: 20px;
            padding: 0;
            scroll-padding: 0;
        }
    }
    .item{
        width: 100%;
        figure{
            height: 100%;
            @include aspect-ratio(1200,670);
            @include tablet_landscape{
                @include aspect-ratio(12,9);
            }
            @include mobile{
                @include aspect-ratio(2,3);
            }
            img{
                @extend %center;
            }
        }

        figcaption{
            display: none;
        }

        .caption{
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 70px;
            transform: none;
            margin: 0 80px;
            width: calc(100% - 160px);
            z-index: 100;
            max-width: 100%;
            @extend %bloc-texte-white;
            .surtitre{
                display: block;
                @include ft($f_hx,24px,300,$c_white,24px);
                margin-bottom: 16px;
                text-transform: uppercase;
            }
            @include desktop{
                margin: 0 40px;
                width: calc(100% - 80px);
            }
            @include mobile{
                bottom: 50px;
                margin: 0 20px;
                width: calc(100% - 40px);
                .surtitre{
                    font-size: calculateRem(18px);
                    line-height: calculateRem(18px);
                }
            }

            p a{
                &:hover{
                    body:not(.no-hover) &{
                        color: $c_main !important;
                        .site_noel &{
                            color: $c_noel2 !important;
                        }
                        .site_vins &{
                            color: $c_vins1 !important;
                        }
                        .site_velo &{
                            color: $c_velo1 !important;
                        }
                    }
                }

            }

            & ~ figure{
                &:after{
                    content: '';
                    @extend .gradient-bottom;
                }
            }
        }
    }


    // Navigation Top sur Slider Avance
    .wrapper-nav-links{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        &:before{
            content: '';
            @extend %center;
            z-index: -1;
            opacity: 0.5;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(-180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(-180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(-180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            @include tablet_portrait{
                left: 40px;
                right: 40px;
                width: calc(100% - 80px);
            }
            @include mobile{
                left: 20px;
                right: 20px;
                width: calc(100% - 40px);
            }
        }
    }
    .nav-links {
        text-align: center;
        white-space: nowrap;
        overflow-x: auto;
        display: flex;
        justify-content: center;
        @include desktop{
            margin: 0 40px;
        }
        @include mobile{
            margin: 0 20px;
        }
        &::-webkit-scrollbar {
            display: none;
        }

        @include tablet_portrait {
            display: flex;
            justify-content: flex-start;
            overflow: scroll;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            padding-left: 60px;
            padding-right: 60px;
            scroll-padding: 60px;
            width: 100%;
            margin: 0;
        }
        @include mobile{
            padding-left: 30px;
            padding-right: 30px;
            scroll-padding: 30px;
        }

        button {
            @include ft($f_hx, 30px, 700, $c_white, 30px);
            text-transform: uppercase;
            opacity: 0.50;
            padding: 30px 15px;
            cursor: pointer;
            background: none;
            border: none;
            @include addTransition();
            &.active {
                opacity: 1;
            }
            &:hover, &:focus{
                body:not(.no-hover) & {
                    opacity: 1;
                }
            }

            @include tablet_landscape{
                font-size: calculateRem(24px);
                line-height: calculateRem(24px);
                padding: 30px 10px;
            }
            @include tablet_portrait {
                scroll-snap-align: start;
            }
            @include mobile {
                font-size: calculateRem(16px);
                line-height: calculateRem(20px);
            }
        }
    }
}
