.bloc-relation.bloc-relation-tags{
	.card-container{
		margin-left: -10px;
		margin-right: -10px;
		.item{
			overflow: hidden;
			display: block;
			position: relative;
			float: left;
			width: calc(25% - 20px);
			margin: 10px;
			@include min-tablet_portrait{
				&:nth-child(4n+1){
					clear: left;
				}
			}
			@include tablet_portrait{
				width: calc(33.33333% - 20px);
				&:nth-child(3n+1){
					clear: left;
				}
			}
			@media screen and (max-width: 750px){
				width: calc(50% - 20px);
				&:nth-child(3n+1){
					clear: none;
				}
				&:nth-child(2n+1){
					 clear: left;
				 }
		 	}
			@include mobile{
				width: calc(100% - 20px);
			}


			figure{
				.credits{
					top: 10px;
					bottom:auto;
				}
				figcaption{
					display: none;
				}
				&:after {
					content: '';
					border-radius: 6px;
					@extend .gradient-bottom;
					@include min-desktop {
						border-radius: 10px;
					}

				}
			}
			.no-photo{
				@include aspect-ratio(365,512);
				width: 100%;
			}

			//txt
			>div:not(.no-photo){
				position: absolute;
				bottom: 20px;
				right: 20px;
				left: 20px;
				z-index: 20;

				h3{
					font-size: 40px;
					line-height: 40px;
					color: white;
					font-family: $f_spe;
					font-weight: 400;
					margin-bottom: 5px;
					@include tablet_portrait{
						font-size: 35px;
						line-height: 35px;
					}
				}
				p{
					color: white;
					font-size: 14px;
					line-height: 20px;
					@include ellipsis(2, 20px);
				}

				.btn{
					margin-top: 20px;
					width: 100%;
					border-color: white;
					color: white;
				}
			}

			&:hover{
				body:not(.no-hover) & {
					.btn{
						background-color: white;
						color: $c_txt;
						border-color: white;
					}
				}
			}
		}

		&:after{
			content: '';
			display: block;
			clear: both;
		}
	}
}