.bloc-conseil-leisel{
    display: flex;
    align-items: center;
    @include mobile{
        display: block;
    }
    figure{
        flex: none;
        background: none;
        margin-right: 15px;
        @include tablet_portrait{
            flex: 1;
        }
        img{   
            height: auto;
        }

        & + .txt{
            @include mobile{
                margin-top: -60px;
            }
        }
    }

    .txt{
        position: relative;
        max-width: 450px;
        @extend %bloc-margin-inside-top-bottom;
        h2{
            @include ft($f_hx, 40px, 400, $c_txt, 40px);
            position: relative;
            z-index: 10;
            text-transform: uppercase;
            margin: 0 0 -10px 0;
            @include mobile{
                font-size: calculateRem(30px);
                line-height: calculateRem(30px);
                margin-left: 30px;
            }
            &:before{
                content: "\e91a";
                @include font();
                position: absolute;
                top: -25px;
                left: -40px;
                font-size: 112px;
                color: $c_grey;
                z-index: -1;
                @include mobile{
                    left: -30px;
                }
            }
            span{
                @include ft($f_spe, 140px, 400, $c_main, 140px);
                text-transform: none;
                display: block;
                margin-top: -26px;
                margin-left: 88px;
                @include mobile{
                    font-size: calculateRem(100px);
                    line-height: calculateRem(100px);
                    margin-top: -20px;
                }

                body.site_noel & {
                    color: $c_noel1;
                }
                body.site_vins & {
                    color: $c_vins1;
                }
                body.site_velo & {
                    color: $c_velo1;
                }
            }
        }

        .btn{
            margin-top: 15px;
        }
    }
}