.card {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    transform: translate3d(0, 0, 0) scale(1);
    @include addTransition();

    &:hover, &:focus {
        body:not(.no-hover) & {
            transform: translate3d(0, 0, 0) scale(0.95);
        }
    }

    figure {
        background: none;
        @include aspect-ratio(380, 320);

        &:after {
            content: '';
            @extend .gradient-card;
            top: auto;
            height: 75%;
            opacity: 0.7;
        }

        img {
            @extend %center;
        }

        figcaption, .credits {
            display: none;
        }
    }

    .no-photo {
        @include aspect-ratio(380, 320);
        background: url("../images/lei-nophoto-right.png") no-repeat !important;
        background-position: top left !important;
        background-size: cover !important;
    }

    .title-card {
        @include ft($f_hx, 16px, 400, $c_white, 20px);
        text-transform: uppercase;
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
        @include addTransition();
        width: calc(100% - 100px);
    }


    .caption-txt {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
        text-align: center;
        z-index: 100;

        > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .maitre {
        @include ft($f_txt, 12px, 700, gray, 22px);
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
        line-height: 1;
    }

    .cave {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 26px;
        color: $c_white;
        z-index: 10;

        span {
            display: block;
        }
    }

    .coeur {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 100;
        display: none;
        @include min-tablet_portrait {
            display: block;
        }
    }

    .rate {
        margin-bottom: 5px;
        display: block;
        width: calc(100% - 100px);

        > * {
            font-size: 14px;
            margin: 0 1px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .surtitre {
        @include ft($f_spe, 34px, 400, $c_white, 34px);
        display: block;
        margin-bottom: 5px;
        @include mobile{
            font-size: calculateRem(28px);
            line-height: calculateRem(28px);
        }
    }

    p {
        @include ft('', 15px, 400, #C6C6C6, 17px);
    }

    .city {
        @include ft($f_txt, 13px, 700, $c_white, 20px);
    }

    .categorie-event {
        @include ft($f_txt, 11px, 400, $c_white, 16px);
        display: block;
        margin-bottom: 10px;
    }

    .difficulte {
        @include ft($f_txt, 12px, 700, $c_green, 22px);
        display: inline-block;
        text-transform: uppercase;

        &:before {
            content: "\e923";
            @include font();
            float: left;
            line-height: 19px;
            font-size: 16px;
            margin-right: 8px;
            color: $c_txt;
        }

        &.d1904403 {
            color: #be641c;
        }

        &.d1904405 {
            color: #b01515;
        }
    }

    .duree {
        @include ft($f_txt, 12px, 700, $c_filet, 22px);
        display: inline-block;
        text-transform: uppercase;

        &:before {
            content: "\e925";
            @include font();
            float: left;
            line-height: 21px;
            font-size: 16px;
            margin-right: 8px;
            color: $c_txt;
        }
    }

    .price {
        @include ft($f_hx, 12px, 400, $c_white, 16px);
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        padding: 15px 0 0 20px;
        z-index: 10;

        .value {
            display: block;
            font-size: calculateRem(32px);
            line-height: calculateRem(30px);
            font-weight: 400;
        }

        span {
            display: block;
            font-size: 30px;
            line-height: 30px;

            span {
                display: inline-block;
                font-size: 13px;
                line-height: 20px;
            }
        }

        & + figure {
            &:after {
                content: '';
                @extend %center;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.7+2,0+79 */
                background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 2%, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 2%, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(135deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 2%, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3000000', endColorstr='#00000000', GradientType=1); /* IE6-9 fallback on horizontal gradient */
            }
        }
    }

    .opening {
        @include ft($f_txt, 12px, 400, $c_green, 22px);
        position: absolute;
        right: 15px;
        bottom: 15px;
        text-align: right;
    }

    .basic-link {
        margin: 0;
    }

    .date-event {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        padding: 15px 20px;

        .surtitre {
            margin-bottom: -5px;
        }

        time {
            span {
                @include ft($f_hx, 18px, 400, $c_white, 20px);
                display: block;
                text-transform: uppercase;

                &.month {
                    font-weight: 700;
                }

                &.year {
                    font-size: calculateRem(14px);
                    line-height: calculateRem(18px);
                }
            }
        }
    }

    .other-dates {
        @include ft($f_txt, 10px, 700, $c_white, 14px);
        display: inline-block;
        text-transform: uppercase;
        margin-top: 7px;
        &:before {
            content: "\e936";
            @include font();
            float: left;
            font-size: 12px;
            line-height: 14px;
            margin-right: 7px;
            position: relative;
            top: -1px;
            @include min-tablet_portrait {
                font-size: 14px;
                line-height: 18px;
                margin-right: 8px;
            }
        }
    }

    .vins, .velo{
        position: absolute;
		display: block;
        z-index: 20;
        top: 20px;
        right: 20px;
		width: 50px;
		min-height: 129px;
		&:after,&:before{
			content:none;
		}

		img{
			display: block;
			width: 100%;
			height: auto;
		}
    }
    .velo{
        width: 50px;
        min-height: 66px;
    }
    .vins + .velo{
        right: 80px;
    }
}

/*
BLOC CARD SIT LEI
*/
.bloc-card-sit  {
    position: relative;

    figure, .no-photo {
        @include aspect-ratio(380, 194);
    }

    figure {
        &:after {
            content: none;
        }
    }
    .title-card {
        color: $c_txt;
    }

    .caption-txt {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: left;
        padding: 20px;
        border: 1px solid #C3C3C3;
        background: $c_white;
        border-top: none;
    }

    .faitguest {
        position: absolute;
        right: 15px;
        top: 10px;
        text-align: right;

        .note {
            @include ft($f_hx, 24px, 400, $c_txt, 24px);
            display: block;
            margin-bottom: 2px;

            span {
                font-size: calculateRem(16px);
                line-height: calculateRem(16px);
            }
        }

        p {
            @include ft($f_hx, 12px, 700, $c_txt, 16px);
            margin-bottom: 0;
        }

        .eval {
            @include ft($f_hx, 12px, 400, $c_txt, 14px);
            display: block;
        }
    }


    &.leaflet-popup{
        position: absolute;
        width:auto;
    }
}

/*
BLOC CARD EXPERIENCES
*/
.bloc-card-experiences {
    .caption-txt {
        .bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: -5px;
            @include clearfix();

            .note {
                padding: 0 8px;
                margin: 0;

                > * {
                    font-size: 10px;
                    line-height: 22px;
                    margin: 0;
                }

                .txt {
                    display: none;
                }
            }

            .city {
                position: relative;
                padding: 0 10px;
                order: 2;

                &:before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    bottom: 0;
                    left: 0;
                    background: $c_white;
                    width: 1px;
                    height: 16px;
                }
            }
        }
    }

    .title-card{
        margin-left: auto;
        margin-right: auto;
    }
}

/*
BLOC CARD EVENEMENT
*/
.bloc-card-event {
    &:after {
        content: '';
        @extend %center;
        background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
        z-index: 0;
    }

    > .bottom {
        position: absolute;
        left: 20px;
        right: 20px;
        width: calc(100% - 40px);
        top: auto;
        bottom: 0;
        z-index: 100;

        > div {
            border-top: 1px solid rgba($c_white, 0.5);

            span {
                @include ft($f_txt, 12px, 400, $c_white, 20px);
                display: inline-block;
                padding: 10px 0 12px 0;
                float: right;

                &.city {
                    float: left;
                    font-weight: 700;
                    width: calc(100% - 45px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:before {
                        content: "\e926";
                        @include font();
                        float: left;
                        line-height: 22px;
                        font-size: 16px;
                        margin-right: 8px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }
}

/*
BLOC CARD STANDARD
*/
.bloc-card-standard {
    > div:not(.no-photo) {
        position: absolute;
        left: 45px;
        right: 30px;
        bottom: 20px;
        text-align: left;
        z-index: 100;
        width: calc(100% - 75px);
        background: none;

        &:before {
            content: '';
            position: absolute;
            left: -45px;
            top: 0;
            bottom: 0;
            width: 20px;
            height: 100%;
            background: $c_main;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                background: $c_noel1;
            }
            .site_vins & {
                background: $c_vins1;
            }
            .site_velo & {
                background: $c_velo1;
            }
        }
    }
}

/*
BLOC CARD ITINÉRAIRE
*/
.bloc-card-itineraire {
    .caption-txt {
        bottom: 0;
        .title-card{
            width: 100%;
        }
    }

    > div {
        .infos {
            padding: 12px 0;
            border-top: 1px dashed rgba($c_white, 0.5);
            border-bottom: 1px dashed rgba($c_white, 0.5);
            font-size: 0;
            text-align: center;
            margin-top: 12px;

            > * {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: 33.3333%;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    bottom: 0;
                    height: 20px;
                    width: 1px;
                    margin-top: -10px;
                    background: rgba($c_white, 0.5);

                    .retina-display & {
                        width: 2px;
                    }
                }

                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }

            [class^="icon-"], [class*=" icon-"] {
                font-size: 24px;
                line-height: 24px;
                margin-bottom: 8px;
                font-weight: 400;
            }

            span {
                @include ft($f_hx, 12px, 700, $c_white, 16px);
                display: block;
                text-transform: uppercase;
                padding: 0 5px;
            }
        }

        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            text-align: left;

            .note {
                margin: 0;

                .txt {
                    display: none;
                }
            }

            .city {
                flex: 1;
                text-align: right;
            }
        }
    }
}

/*
BLOC CARD RECETTE
*/
.bloc-card-recette {
    .title-card{
        width: 100%;
    }
    .center {
        position: absolute;
        left: 20px;
        right: 80px;
        top: 50%;
        transform: translateY(-50%);
        transform: translate3d(0, -50%, 0);
        text-align: left;
        z-index: 100;
        width: calc(100% - 100px);
    }

    .infos {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
        text-align: center;
        width: 65px;
        padding: 25px 0;
        z-index: 10;
        border: 1px solid rgba($c_dark, 0.2);
        background: $c_white;

        > * {
            position: relative;
            display: block;
            width: 100%;
            height: 33.3333%;

            > * {
                position: absolute;
                left: 0;
                right: 0;
                top: 60%;
                transform: translateY(-50%);
                transform: translate3d(0, -50%, 0);
            }

            &:first-child {
                > * {
                    top: 0;
                    transform: none;
                }
            }

            &:last-child {
                > * {
                    top: auto;
                    bottom: 0;
                    transform: none;
                }
            }
        }

        [class^="icon-"], [class*=" icon-"] {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 5px;
            font-weight: 400;
        }

        span {
            @include ft($f_txt, 11px, 700, $c_txt, 16px);
            display: block;
            text-transform: uppercase;
            white-space: nowrap;
            padding: 0 4px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .bottom {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 15px;
        text-align: left;
        width: calc(100% - 40px);

        .note {
            justify-content: flex-start;

            .txt {
                display: none;
            }
        }
    }
}

/*
BLOC CARD TOP
*/
.bloc-card-top {
    &:after {
        content: "\e929";
        @include font();
        position: absolute;
        right: -1px;
        bottom: 30px;
        font-size: 120px;
        color: $c_white;
        z-index: 1000;
    }

    .caption-txt {
        text-align: left;
        right: 60px;
        width: calc(100% - 80px);
    }
}

/*
BLOC CARD LEI ITINERAIRE (Alsace à vélo)
*/
.bloc-card-lei-itineraire{
    box-sizing: border-box;
    border: 1px solid $c_border;
    box-shadow: none;
    background-color: white;
    &:after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0;
        background-color: $c_main;
        z-index: 10;
        @include addTransition();
        body.site_noel & {
            background-color: $c_noel1;
        }
        body.site_vins & {
            background-color: $c_vins1;
        }
        body.site_velo & {
            background-color: $c_velo1;
        }
    }

    .entete{
        display: flex;
        figure{
            position: relative;
            display: block;
            width: 140px;
            height: 140px;
            flex-shrink: 0;
        }
        .no-photo{
            display: none;
        }

        .titre{
            position: relative;
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 10px 20px;
            .title-card{
                width: auto;
            }
        }
    }
    .bot{
        display: flex;
        border-top: 1px solid $c_border;
        .iti-data{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            padding: 18px 5px;
            >span{
                display: inline-block;
                height: 16px;
                width: 16px;
                font-size: 16px;
                line-height: 1;
                margin-right: 10px;
                color: $c_main;
                body.site_noel & {
                    color: $c_noel1;
                }
                body.site_vin & {
                    color: $c_vins1;
                }
                body.site_velo & {
                    color: $c_velo1;
                }

                @include mobile{
                    margin-right: 5px;
                    height: 14px;
                    width: 14px;
                    font-size: 14px;
                }
            }
            >div{
                color: $c_txt;
                font-size: 14px;
                line-height: 16px;
                display: block;
                @include mobile{
                    font-size: 12px;
                    line-height: 14px;
                }
            }

            .difficulte{
                position: relative;
                &:before{
                    content:none;
                }
                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }
                &.facile:after{background-color: #00AB59;}
                &.moyen:after{background-color: #2DA5CE;}
                &.difficile:after{background-color: #FC1926;}
                &.t-difficile:after{background-color: #000000;}
            }

            &:not(:last-child):after{
                content:'';
                position: absolute;
                top: 15px;
                bottom: 15px;
                right: 0;
                width: 1px;
                background-color: $c_sub;
                body.site_noel & {
                    background-color: $c_noel2;
                }
                body.site_vin & {
                    background-color: $c_vins2;
                }
                body.site_velo & {
                    background-color: $c_velo2;
                }
            }
        }
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            box-shadow: 0 10px 60px rgba(0,0,0,.16);
            transform: translate3d(0, 0, 0);
            border-color: $c_main;
            &:after{
                height: 6px;
            }
        }

        body.site_noel:not(.no-hover) & {
            border-color: $c_noel1;
        }
        body.site_vins:not(.no-hover) & {
            border-color: $c_vins1;
        }
        body.site_velo:not(.no-hover) & {
            border-color: $c_velo1;
        }
    }
}