.page-listing-lei{
    position: relative;
    padding-top: $header-height;
    z-index: 10;
    @include mobile{
        padding-top: 20px;
        padding-bottom: $content-spacing/2;
    }

    .wrapper-content{
        display: flex;
        align-items: flex-start;
        header{
            padding: 70px 40px 105px;
            margin: 0 0 20px 0;
            text-align: center;
            @include mobile{
                padding: 80px 20px 60px;
            }
            h1{
                @include ft($f_spe, 100px, 400, $c_main, 70px);
                text-transform: none;
                margin-bottom: 30px;
                @include tablet_landscape{
                    font-size: calculateRem(80px);
                    line-height: calculateRem(60px);
                }
                @include mobile{
                    font-size: calculateRem(70px);
                    line-height: calculateRem(50px);
                    margin-bottom: 20px;
                }

                body.site_noel & {
                    color: $c_noel2;
                }
                body.site_vins & {
                    color: $c_vins2;
                }
                body.site_velo & {
                    color: $c_velo2;
                }
            }

            .bottom-direction-scroll{
                span{
                    color: $c_main;

                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins2;
                    }
                    body.site_velo & {
                        color: $c_velo2;
                    }
                }
            }

            #breadcrumb {
                @extend .breadcrumb-white;
            }

            & + .bloc-filtres{
                margin-top: 0;
                justify-content: flex-start;
                position: sticky;
                position: -webkit-sticky;
                top: $header-height+20px;
                @include mobile{
                    top: 20px;
                }
            }
        }
    }

    .bloc-map{
        width: 30%;
        height: calc(100vh - #{$header-height});
        background: $c_grey;
        position: sticky;
        position: -webkit-sticky;
        top: $header-height;
        @include tablet_portrait{
            display: none;
        }
        .maps{
            @extend %center;
        }
        .algolia-place{
            width: calc(100% - 40px);
        }
    }

    .wrapper-listing-lei{
        position: relative;
        width: 70%;
        padding: 0 40px 0 20px;
        margin-bottom: -10px;
        @include tablet_portrait{
            width: 100%;
            padding: 0 40px;
            margin-bottom: 0;
        }
        @include mobile{
            padding: 0 20px;
        }
    }

    .wrapper-content{
        min-height: 300px;
        .listing-cards{
            .card{
                @include wide{
                    width: calc(33.33% - 20px);
                    &:nth-child(4n+1){
                        clear: none;
                    }
                    &:nth-child(3n+1){
                        clear: left;
                    }
                }
                @include tablet_landscape{
                    width: calc(50% - 20px);
                    &:nth-child(3n+1){
                        clear: none;
                    }
                    &:nth-child(odd){
                        clear: left;
                    }
                }
                @include mobile{
                    width: calc(100% - 20px);
                }
            }
        }
    }

    &.listing-lei-iti{
        .head{
            display: block;
            margin: 0 40px 40px 40px;
            @include mobile{
                margin: 0 20px 20px 20px;
            }
        }

        .bloc-map{
            width: calc(100% - 520px);
        }
        .wrapper-listing-lei{
            width: 520px;
            @include tablet_portrait{
                width: 100%;
            }
            .listing-cards {
                margin-left: 0;
                margin-right: 0;
                @include tablet_portrait{
                    margin-left: -10px;
                    margin-right: -10px;
                }
                @media screen and (max-width: 720px){
                    margin-left: 0;
                    margin-right: 0;
                }
                .card{
                    width: 460px;
                    margin: 10px 0;
                    @include tablet_portrait{
                        margin: 10px;
                        width: calc(50% - 20px);
                    }
                    @media screen and (max-width: 720px){
                        margin: 10px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}