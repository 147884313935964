/* ------------------
--- CSS GLOBAL + GRILLE RESPONSIVE ---
------------------ */
html, body {
	height: 100%;
}

html {
	font-size: 100%;
	margin-top: 0 !important;
}

body {
    height: 100%;
    font-family: $f_txt;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c_txt;
    margin: 0;
    padding: 0;
    &.no-scroll {
        overflow: hidden;
    }

}

section, div, header, footer, aside {
	box-sizing: border-box;
}

main {
	position: relative;
}

.block {
	display: block;
}

.background-alsace-2022{
    background: url("../images/design/background-alsace-2022.png");
    background-size: contain;
}

// @extend
// ==========================================================================
%center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    width: 100%;
    height: 100%;
}

%aligncenter {
	display: flex;
	display: -ms-flexbox;
	align-items: center;
	justify-content: center;
}

%appearance {
    -webkit-appearance: none;
    resize: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    outline: none;
    &::-ms-clear{
        display: none;
    }
}

%bloc-texte-white{
    > * {
        color: $c_white;
    }
    a:not(.btn):not(.basic-link):not(.link-icon) {
        color: $c_white;
        text-decoration: underline;
        &:hover, &:focus {
            body:not(.no-hover) & {
                color: $c_txt;
            }
        }
    }
}

%bloc-margin-inside-top-bottom{
    > * {
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}


// Margins / Paddings
// ==========================================================================
.center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.parallax-window {
	min-height: 400px;
}

.wrapper {
	position: relative;
	z-index: 10000;
}

.no-gutter > [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.hide {
	display: none !important;
}
.non-loaded{
	display: none;
}

.caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

// Margins / Paddings
// ==========================================================================
.no-pad {
	padding: 0 !important;
}

.m-20 {
	margin: 20px 0;
}

.m-30 {
	margin: 30px 0;
}

.m-40 {
	margin: 40px 0;
}

.m-50 {
	margin: 50px 0;
}

.p-20 {
	padding: 20px 0;
}

.p-30 {
	padding: 30px 0;
}

.p-40 {
	padding: 40px 0;
}

.p-50 {
	padding: 50px 0;
}

// Alignements
// ==========================================================================
.aligncenter {
	text-align: center;
}

.alignleft {
	text-align: left;
}

.alignright {
	text-align: right;
}

// Viewports
// ==========================================================================
.v-mobile{
	display: none !important;
	@include mobile{
		display: block !important;
	}
}
.v-tablet-p{
	display: none !important;
	@include tablet_portrait{
		display: block !important;
	}
}
.v-tablet-l{
	display: none !important;
	@include tablet_landscape{
		display: block !important;
	}
}
.hide-mobile{
	display: block;
	@include mobile{
		display: none !important;
	}
}
.hide-tablet-p{
	display: block;
	@include tablet_portrait{
		display: none !important;
	}
}
.hide-tablet-l{
	display: block;
	@include tablet_landscape{
		display: none !important;
	}
}
.v-tab-p {
    @include min-tablet_landscape {
        display: none !important;
    }
}