.bloc-relation-manuelle{
	.th-slider{
		.card{
			position: relative;
			width: 380px;
			.titre{
				position: absolute;
				bottom:50px;
				right: 20px;
				left: 20px;
				z-index: 40;
				color: white;
				font-family: $f_spe;
				font-size: 40px;
				line-height: 40px;
				text-align: center;
			}
		}
	}
}