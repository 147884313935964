// Style Slick Slider
// ==========================================================================
.slick-carousel {
    height: 100%;

    &:not(.slick-initialized) {
        .item, .card {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;
        }
    }

    .item {
        position: relative;
        height: 100%;
    }
}

// Style Dots
// ==========================================================================
.slick-dots {
    position: absolute;
    left: 40px;
    bottom: 40px;
    display: flex;
    @include mobile{
        left: 20px;
        bottom: 25px;
    }
    li {
        margin: 0 5px 0 0;
        padding: 0;
        &:before {
            content: none;
        }

        &.slick-active {
            button {
                background: $c_white;
            }
        }

        button {
            width: 8px;
            height: 8px;
            background: rgba($c_white, 0.5);
            border-radius: 50%;
            cursor: pointer;
            display: block;
            border: none;
            padding: 0;
            font-size: 0;
            line-height: 0;
            outline: none;
            @include addTransition();

            &:hover, &:focus, &:active {
                background: $c_white;
            }
        }
    }
}

// Style Arrows
// ==========================================================================
.slick-prev, .slick-next {
    @extend %appearance;
    position: absolute;
    top: 50%;
    z-index: 100;
    margin-top: -20px;
    padding: 0;
    outline: none;
    overflow: visible;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 0;
    background: none;
    display: block;
    text-align: center;
    @include addTransition();
    @include mobile {
        width: 34px;
        height: 34px;
        line-height: 34px;
    }
    &:not(.slick-disabled) {
        &:hover, &:focus, &:active {
            cursor: pointer;
            &:before{
                color: $c_txt;
            }
        }

    }

    &:before {
        @include font();
        position: relative;
        font-size: 40px;
        color: $c_main;
        @include addTransition();
        @include mobile {
            font-size: 30px;
        }
    }

    &.slick-disabled {
        opacity: 0.3;
    }
}

.slick-prev {
    left: 40px;
    @include mobile {
        left: 20px;
    }

    &:before {
        content: "\e91d";
    }
}

.slick-next {
    &:before {
        content: "\e91e";
    }
}