#zoombox .zoombox_gallery img {
	display: inline-block;
}

.bloc-gallery {
    .wrapper-photos{
        display: flex;
        align-items: flex-start;
        @include mobile{
            flex-wrap: wrap;
        }
        a{
            display: block;
            &:hover, &:focus{
                body:not(.no-hover) & {
                    img{
                        opacity: 0.5;
                    }
                }
            }
        }
        figure{
            @include aspect-ratio(1,1);
            img{
                @extend %center;
                @include addTransition();
            }
            figcaption{
                display: none;
            }
        }

        > * {
            &:first-child{
                position: relative;
                z-index: 10;
                width: 38%;
                flex: none;
                @include tablet_portrait{
                    width: 50%;
                }
                @include mobile{
                    width: 100%;
                    order: 2;
                }
                figure{
                    @include aspect-ratio(458,520);
                    @include mobile{
                        @include aspect-ratio(1,1);
                    }
                }
            }
            &:last-child{
                position: relative;
                z-index: 20;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                width: 62%;
                margin-top: 100px;
                @include tablet_landscape{
                    margin-top: 60px;
                }
                @include tablet_portrait{
                    width: 50%;
                }
                @include mobile{
                    order: 1;
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: -40px;
                    flex-wrap: wrap;
                }

                // Milieu
                .wrapper{
                    position: relative;
                    z-index: 10;
                    width: 305px;
                    display: flex;
                    flex-wrap: wrap;
                    flex: none;
                    @include tablet_landscape{
                        width: 240px;
                    }
                    @include tablet_portrait{
                        width: 190px;
                    }
                    @include mobile{
                        display: flex;
                        flex-wrap: nowrap;
                        margin-left: -10px;
                        margin-right: -10px;
                        width: calc(100% + 20px);
                        align-items: flex-start;
                    }
                    a{
                        width: 100%;
                        border: 10px solid $c_white;
                        &:first-child{
                            margin-top: 40px;
                            margin-left: -140px;
                            @include mobile{
                                margin-left: 0;
                            }
                        }
                        & + a{
                            margin-top: -10px;
                            border-bottom: none;
                            @include mobile{
                                border-bottom: 10px solid $c_white;
                                border-left: none;
                            }
                        }
                    }
                }
                > a {
                    flex: 1;
                    margin-left: -160px;
                    @include mobile{
                        margin: 0;
                        flex: none;
                        margin-top: -60px;
                        width: calc(100% - 40px);
                        border: 10px solid white;
                        border-left: 0;
                        position: relative;
                    }
                }
                > * {
                    &:nth-child(1){

                    }
                    &:last-child{
                       figure{
                           @include aspect-ratio(575,520);
                       }
                    }
                }
            }
        }
    }

	.legend {
		@include ft($f_txt, 15px, 400, $c_filet, 20px);
		text-align: left;
		display: block;
		font-style: italic;
		padding-top: 10px;
		padding-right: 20px;
        @include mobile{
            font-size: calculateRem(13px);
            line-height: calculateRem(18px);
        }
	}

}