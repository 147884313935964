.bloc-image {
	position: relative;
	&.image-auto{
		img{
			height: auto;
			margin: auto;
		}
	}
	figure {
		background: none;
		&.fit-cover{
            height: 100%;
			img {
				max-height: calc(100vh - 80px);
				width: 100%;
				height: 100%;
			}
		} 
	}
    figcaption{
        margin-left: 20px;
    }
}
