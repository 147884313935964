.bloc-image-caption{
	position: relative;
	figure{
        @include aspect-ratio(1120,560);
        @include mobile{
            @include aspect-ratio(375,560);
            height: 100%;
        }
		img{
			@extend %center;
		}
	}
	.caption{
		top: auto;
		bottom: calc(60px + #{$content-spacing});
		transform: none;
        z-index: 100;
        @extend %bloc-texte-white;
        .surtitre{
            display: block;
            @include ft($f_hx,24px,300,$c_white,24px);
            margin-bottom: 16px;
            text-transform: uppercase;
        }
        @include desktop{
            margin: 0 80px;
        }
        @include tablet_landscape{
            margin: 0 40px;
        }
        @include mobile{
            margin: 0 20px;
            bottom: 60px;
            .surtitre{
                font-size: calculateRem(18px);
                line-height: calculateRem(18px);
            }
        }
		& + figure{
            &:after{
                content: '';
                @extend .gradient-bottom;
                top: auto;
                height: 75%;
            }
        }
	}

}