.bloc-filtres {
    position: relative;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $c_grey;
    padding: 10px 20px;
    body.site_velo & {
        background-color: $c_velo1;
    }
    @include mobile {
        padding: 5px 10px;
        flex-wrap: wrap;
    }

    &:before{
        content: '';
        position: absolute;
        bottom: 100%;
        height: 20px;
        width: 100%;
        left: 0;
        right: 0;
        background-color: white;
        z-index: 10000;
    }

    > * {
        margin: 10px 10px 10px 0;
        @include mobile {
            margin: 5px 5px 5px 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .btn-map, .btn-filtre, .btn-print {
        @include tablet_portrait {
            padding: 3px 20px 5px 20px;
            text-align: center;
            min-width: 155px;
            text-transform: uppercase;
        }
        @include mobile {
            min-width: 10px;
            flex: 1;
        }
    }

    ul {
        display: flex;
        align-items: center;
        @include tablet_portrait {
            display: none;
        }

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            @include mobile {
                margin-right: 5px;
            }

            &.open {
                > ul {
                    display: block;
                }
            }

            > a {
                @include ft($f_hx, 10px, 500, $c_txt, 28px);
                text-transform: uppercase;
                letter-spacing: 1px;
                height: 28px;
                border: 1px solid $c_border;
                display: block;
                padding: 0 20px;
                background: $c_white;
                @include addTransition();

                &:hover, &:focus {
                    background: $c_main;
                    border-color: $c_main;
                    color: $c_white;
                    /* #CHANGE COLOR THEME */
                    .site_noel & {
                        background: $c_noel1;
                        border-color: $c_noel1;
                    }

                    .site_vins & {
                        background: $c_vins1;
                        border-color: $c_vins1;
                    }
                    .site_velo & {
                        background: $c_velo1;
                        border-color: $c_velo1;
                    }
                }
            }

            // Dropdown filtre - Page Listing LEI
            ul {
                position: absolute;
                top: calc(100% + 12px);
                left: 0;
                right: 0;
                width: 260px;
                background: $c_white;
                border: 1px solid $c_border;
                display: none;
                z-index: 1000;
                padding: 15px 20px;

                &:before {
                    content: '';
                    float: left;
                    position: absolute;
                    top: -8px;
                    left: 30px;
                    pointer-events: none;
                    width: 14px;
                    height: 14px;
                    background: $c_white;
                    transform: rotate(45deg);
                    border-left: 1px solid $c_border;
                    border-top: 1px solid $c_border;
                    opacity: 0.99;
                    color: #fff;
                    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
                }

                li {
                    position: relative;
                    border-bottom: 1px solid rgba($c_dark, 0.2);
                    width: 100%;
                    display: block;
                    float: none;
                    margin: 0;

                    &:nth-child(even) {
                        margin: 0;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }

                    label {
                        width: 100%;
                        a {
                            @include ft($f_hx, 12px, 500, $c_txt, 20px);
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            white-space: normal;
                            padding: 9px 0;
                            width: calc(100% - 70px);
                        }
                    }
                }
            }
        }
    }
}

// Style - Listing des critières
// ==========================================================================
.wrapper-critere {
    position: relative;
    z-index: 5000;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    margin-top: 15px;

    li {
        margin: 5px 5px 5px 0;

        &.list-results {
            @include ft($f_hx, 12px, 400, $c_txt, 26px);
            text-transform: uppercase;
            letter-spacing: 1px;
            padding-right: 10px;
        }

        a {
            @include ft($f_hx, 12px, 400, $c_txt, 24px);
            text-transform: uppercase;
            height: 26px;
            border: 1px solid $c_border;
            display: inline-block;
            padding: 0 10px;
            background: $c_white;
            @include addTransition();

            &:after {
                content: "\e91c";
                @include font();
                float: right;
                line-height: 26px;
                margin-left: 20px;
                font-size: 8px;
                @include addTransition();
            }

            &:hover, &:focus {
                border-color: $c_main;

                &:after {
                    color: $c_main;
                }

                /* #CHANGE COLOR THEME */
                .site_noel & {
                    border-color: $c_noel1;

                    &:after {
                        color: $c_noel1;
                    }
                }

                .site_vins & {
                    border-color: $c_vins1;

                    &:after {
                        color: $c_vins1;
                    }
                }

                .site_velo & {
                    border-color: $c_velo1;

                    &:after {
                        color: $c_velo1;
                    }
                }
            }
        }
    }
}

// Style - Label Switch
// ==========================================================================
.switch {
    width: calc(50% - 20px);
    float: left;

    &:nth-child(even) {
        margin-left: 40px;
        @include mobile {
            margin-left: 0;
        }
    }

    @include mobile {
        width: 100%;
        float: none;
    }

    &:hover {
        cursor: pointer;

        > label {
            a {
                color: $c_main !important;
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    color: $c_noel2 !important;
                }

                .site_vins & {
                    color: $c_vins2 !important;
                }

                .site_velo & {
                    color: $c_velo2 !important;
                }
            }
        }
    }

    figure, .color {
        background: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transform: translate3d(0, -50%, 0);

        & + input {
            left: 30px;
            width: calc(100% - 30px);
        }

        & ~ label {
            position: relative;
            left: 30px;
            width: calc(100% - 30px) !important;
        }
    }
    .color{
        width: 20px;
        height: 20px;
        border-radius: 50px;
    }

    input {
        @extend %center;
        opacity: 0;
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        border: none;
        margin: 0;
        outline: none;
        border-radius: 0;
        z-index: 1000;

        &:hover {
            cursor: pointer;
        }
    }

    input:checked + label {
        &:before {
            background: $c_main;
            transform: translate3d(0, -50%, 0) scale(0);
            transform: translate(0, -50%) scale(0);
        }

        &:after {
            background: $c_white;
            transform: translate3d(0, -50%, 0);
            transform: translate(0, -50%);
        }

        a, span {
            &:after {
                background: $c_main;
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    background: $c_noel2;
                }

                .site_vins & {
                    background: $c_vins2;
                }

                .site_velo & {
                    background: $c_velo2;
                }
            }
        }
    }

    label {
        @include ft($f_txt, 16px, 400, $c_txt, 55px);
        position: relative;
        width: calc(100% - 70px);
        display: block;
        z-index: 100;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include addTransition();
        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;
            }
        }

        &:hover, &:active {
            color: $c_main;
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }

            .site_vins & {
                color: $c_vins2;
            }

            .site_velo & {
                color: $c_velo2;
            }
        }

        &:before,
        &:after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 5px;
            z-index: 100;
            transition: all 350ms cubic-bezier(0, .95, .38, .98),
            background 150ms ease;
        }

        &:before {
            background: $c_grey;
            transform: translate3d(0, -50%, 0) scale(0);
            transform: translate(0, -50%) scale(0);
        }

        &:after {
            background: #ADADAD;
            transform: translate3d(-25px, -50%, 0);
            transform: translate(-25px, -50%);
        }

        a {
            @include ft($f_txt, 16px, 400, $c_txt !important, 55px);
            display: block;
            text-align: left;
            @include addTransition();

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 50px;
                height: 25px;
                background: $c_light;
                border-radius: 25px;
                z-index: -1;
                transition: all 350ms cubic-bezier(0, .95, .38, .98),
                background 150ms ease;
            }
        }

        span {
            display: block;

            &:after {
                content: '';
                position: absolute;
                top: 15px;
                right: 0;
                width: 50px;
                height: 25px;
                background: $c_light;
                border-radius: 25px;
                z-index: -1;
                transition: all 350ms cubic-bezier(0, .95, .38, .98),
                background 150ms ease;
            }
        }
    }
}


// Style - date
// ==========================================================================
li.date {
    display: flex;
    align-items: center;
    width: 100%;

    input {
        @include ft($f_txt, 12px, 400, $c_white, 20px);
        @extend %appearance;
        height: 20px;
        padding: 9px 20px 7px 10px;
        text-transform: uppercase;
        border-radius: 40px;
        background: $c_main;
        padding-right: 0;
        width: 120px;
        //box-sizing: content-box;
        display: inline-block;
        text-align: left;
        margin: 0 5px;
        /* #CHANGE COLOR THEME */
        .site_noel & {
            background: $c_noel2;
        }

        .site_vins & {
            background: $c_vins2;
        }

        .site_velo & {
            background: $c_velo2;
        }

        &::placeholder {
            color: $c_white !important;
        }
    }

    /* Hide Calendar Icon In Chrome */
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

    .icon {
        color: $c_white;
        margin: 0;
        position: relative;
        top: 50%;
        margin-left: -30px;
        margin-right: 20px;
        pointer-events: none;
        font-size: 12px;
    }

    label {
        @include ft($f_txt, 16px, 400, $c_txt, 55px);
    }
}

