.bloc-experiences {
    .text-intro{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include mobile{
            display: block;
        }
        .title {
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 35px;
            @include mobile{
                margin-bottom: 15px;
            }
        }

        .btn{
            @include mobile{
                margin-bottom: 15px;
            }
        }
    }

    .wrapper-experiences {
        position: relative;
        margin: 0;
        padding: 0;

        & + .wrapper-experiences {
            margin-top: 25px;
        }

        .wrapper-title {
            position: relative;
            margin-bottom: 15px;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: calc(50% + 2px);
                transform: translateY(-50%);
                transform: translate3d(0,-50%,0);
                border-top: 1px dashed rgba($c_dark,0.3);
                @include mobile{
                    top: 15px;
                    transform: none;
                }
            }
        }

        .title-section {
            @include ft($f_spe, 40px, 400, $c_main, 40px);
            background: $c_white;
            display: inline-block;
            vertical-align: middle;
            padding-right: 20px;
            margin-bottom: 0;
            position: relative;
            z-index: 100;
            @include mobile{
                font-size: calculateRem(30px);
                line-height: calculateRem(30px);
                &:after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    clear: both;
                }
            }
            /* #CHANGE COLOR THEME */
            .site_noel & {
                color: $c_noel2;
            }

            .site_vins & {
                color: $c_vins2;
            }
            .site_velo & {
                color: $c_velo2;
            }
        }

        .basic-link {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -10px;
            z-index: 10;
            padding-left: 10px;
            background: $c_white;
            @include mobile{
                position: relative;
                top: 0;
                margin: 0;
                margin-top: 5px;
                padding: 0;
                display: block;
            }
        }
    }

    .th-slider-wrapper{
        position: relative;

        .nav-buttons{
            margin-top: -38px;
            button{
                &:before{
                    font-size: 36px;
                    color: $c_main;
                    body.site_noel & {
                        color: $c_noel2;
                    }
                    body.site_vins & {
                        color: $c_vins1;
                    }
                    body.site_velo & {
                        color: $c_velo1;
                    }
                }

                &:hover{
                    body:not(.no-hover) & {
                        &:before{
                            color: $c_sub;
                        }
                    }
                }

                &:hover{
                    body:not(.no-hover).site_noel & {
                        &:before{
                            color: $c_noel1;
                        }
                    }
                }
                &:hover{
                    body:not(.no-hover).site_vins & {
                        &:before{
                            color: $c_vins2;
                        }
                    }
                }
                &:hover{
                    body:not(.no-hover).site_velo & {
                        &:before{
                            color: $c_velo2;
                        }
                    }
                }

                &[disabled]{
                    display: none !important;
                }
            }
        }
    }
}