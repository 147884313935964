.th-slider-wrapper {
    overflow: hidden;
}

.th-slider {
    position: relative;
    width: 100%;
    display: flex;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    > * {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        display: block;
        flex-shrink: 0;

        &:first-child {
            margin-left: 0 !important;
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }

    .card {
        width: 320px;
        margin-right: 20px;
        @media screen and (max-width: 360px){
            width: 300px;
        }
        @include mobile_small{
            width: 260px;
        }
    }

}

.content-wrapper:not(.bloc-small) {
    .scroll-mode {
        @include desktop {
            padding-left: 80px;
            padding-right: 80px;
            scroll-padding: 80px;
        }
        @include tablet_landscape {
            padding-left: 40px;
            padding-right: 40px;
            scroll-padding: 40px;
        }
        @include mobile {
            padding-left: 20px;
            padding-right: 20px;
            scroll-padding: 20px;
        }

        & > *:last-child {
            padding-right: 0;
        }
    }
}

.scroll-mode-parent {
    @include tablet_landscape {
        width: 100%;
        margin: 0;
    }

    .scroll-mode {
        overflow: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;

        @include tablet_landscape {
            padding-left: 40px;
            padding-right: 0px;
            scroll-padding: 40px;
        }
        @include mobile {
            padding-left: 20px;
            padding-right: 0px;
            scroll-padding: 20px;
        }


        &:after {
            @include tablet_landscape {
                content: '';
                min-width: 40px;
            }
            @include mobile {
                content: '';
                min-width: 20px;
            }
        }


        > * {
            scroll-snap-align: start;

            &:last-child {
                margin-right: 0;
            }
        }

        > *:last-child {
            scroll-snap-align: end;
        }
    }

}


.nav-buttons{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    margin-top: -40px;
    @include desktop{
        width: calc(100% - 80px);
    }
    @include tablet_landscape{
        margin-top: -30px;
    }
    @include mobile{
        width: calc(100% - 40px);
        display: none;
    }
    button{
        @extend %appearance;
        background: none;
        font-size: 0;
        padding: 20px;
        @include tablet_landscape{
            padding: 10px;
        }
        &:not(:disabled){
            &:hover, &:focus{
                body:not(.no-hover) & {
                    cursor: pointer;
                    &:before{
                        color: $c_main;
                    }
                }
                body:not(.no-hover).site_noel & {
                    &:before{
                        color: $c_noel1;
                    }
                }
                body:not(.no-hover).site_vins & {
                    &:before{
                        color: $c_vins1;
                    }
                }
                body:not(.no-hover).site_velo & {
                    &:before{
                        color: $c_velo1;
                    }
                }
            }
        }
        &:before{
           @include font();
            font-size: 40px;
            color: $c_white;
            @include addTransition();
            @include tablet_landscape{
                font-size: 30px;
            }
        }

        &:first-child{
            &:before{
                content: "\e91d";
            }
        }
        &:last-child{
            position: absolute;
            right: 0;
            &:before{
                content: "\e91e";
            }
        }
        &:disabled{
            opacity: 0.5;
        }
    }
}


.nav-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(20px + #{$content-spacing});
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile{
        bottom: calc(20px + #{$content-spacing/2});
    }

    button {
        width: 8px;
        height: 8px;
        background: rgba($c_white, 0.5);
        border-radius: 50%;
        cursor: pointer;
        display: block;
        border: none;
        padding: 0;
        font-size: 0;
        line-height: 0;
        outline: none;
        margin: 0 3px;
        @include addTransition();

        &:hover, &:focus {
            body:not(.no-hover) & {
                cursor: pointer;
                background-color: $c_white;
            }
        }

        &.active {
            background: $c_white;
        }
    }
}


.th-slider-timebar {
    height: 8px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($c_main, 0.6);
    /* #CHANGE COLOR THEME */
    .site_noel & {
        background: rgba($c_noel1, 0.6);
    }
    .site_vins & {
        background: rgba($c_vins2, 0.6);
    }
    .site_velo & {
        background: rgba($c_velo2, 0.6);
    }

    @include tablet_portrait {
        height: 4px;
    }
}

.th-slider-timebar-bar {
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: red;
    transform-origin: top left;
    /* #CHANGE COLOR THEME */
    .site_noel & {
        background: $c_noel1;
    }
    .site_vins & {
        background: $c_vins2;
    }
    .site_velo & {
        background: $c_velo2;
    }

    @include tablet_portrait {
        height: 4px;
    }
}