.bloc-onglet {
	position: relative;
	margin: auto;
    input{
        display: none;
    }
	.boutons {
		position: relative;
		text-align: left;
		font-size: 0;
        margin-bottom: $content-spacing;
        @include mobile{
            margin-bottom: $content-spacing/2;
            margin-left: -20px;
            margin-right: -20px;
        }
		> div {
            display: flex;
            align-items: stretch;
			width: 100%;
            height: 80px;
            white-space: normal;
            overflow: hidden;
            @include mobile{
                -webkit-overflow-scrolling: touch;
                white-space: nowrap;
                overflow: auto;
                height: 60px;
                padding-left: 20px;
                padding-right: 20px;
                scroll-padding: 20px;
                &:after {
                    @include mobile {
                        content: '';
                        min-width: 20px;
                    }
                }
            }
		}
		label {
            @include ft($f_hx, 14px, 400, $c_txt, 18px);
            flex: 1;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: relative;
			padding: 10px 20px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            scroll-snap-align: start;
            background: $c_grey;
            @include addTransition();
			&:hover {
				body:not(.no-hover) & {
					cursor: pointer;
					color: $c_white;
					background: $c_main;
				}
				body:not(.no-hover).site_noel & {
					background: $c_noel1;
				}
				body:not(.no-hover).site_vins & {
					background: $c_vins1;
				}
				body:not(.no-hover).site_velo & {
					background: $c_velo1;
				}
			}
            &:last-child{
                &:after{
                    content: none;
                }
            }

			&:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 5px;
                background: $c_main;
                display: block;
                opacity: 0;
                z-index: 100;
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    background: $c_noel2;
                }
                .site_vins & {
                    background: $c_vins2;
                }
                .site_velo & {
                    background: $c_velo2;
                }
			}
			&:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                height: 100%;
                z-index: 1000;
                background: $c_white;
			}
		}
	}

	.wrapper-content {
		position: relative;
		.contenu {
			position: relative;
			margin: auto;
			display: none;
			[class^="bloc-lei"], [class*="bloc-lei"] {
				position: relative;
				padding: 20px 0;
                border-top: 1px dashed rgba($c_dark,0.3);
                &:last-child{
                    padding-bottom: 0;
                }
			}

			.bloc-lei-label{
				padding-bottom: 0;
			}

			.title {
				@include ft($f_hx, 20px, 700, $c_dark, 25px);
                text-transform: uppercase;
				display: block;
				margin-bottom: 20px;
			}

			iframe {
				width: 100%;
				margin-bottom: 20px;
			}

			> .bloc-texte {
				margin-bottom: 20px;
				p{
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                }
				img.liesel{
					float: left;
					margin-right: 10px;
					margin-bottom: 10px;
				}
				p.accroche{
					font-size: calculateRem(20px);
					line-height: calculateRem(24px);
					font-style: italic;
					&::after{
						content: '';
						display: block;
						clear: both;
					}
				}

				a{
					@include ft($f_txt,$s_p,400,$c_main,$s_p+6);
					text-decoration: underline;
					/* #CHANGE COLOR THEME */
					.site_noel & {
						color: $c_noel2;
					}
					.site_vins & {
						color: $c_vins2;
					}
					.site_velo & {
						color: $c_velo2;
					}
					@include min-tablet_landscape{
						font-size: 18px;
						line-height: 24px;
					}
					&:hover{
						color: darken($c_main,20%);
						/* #CHANGE COLOR THEME */
						.site_noel & {
							color: darken($c_noel2, 20%);
						}
						.site_vins & {
							color: darken($c_vins2, 20%);
						}
						.site_velo & {
							color: darken($c_velo2, 20%);
						}
					}
				}
				.accroche {
					p{
                        font-size: calculateRem(20px);
                        line-height: calculateRem(24px);
                    }
				}
                figure{
                    margin-bottom: 40px;
                    @include mobile{
                        margin-bottom: 20px;
                    }
                    img{
                        margin: 0;
                    }
                }
			}

		}

		.bloc-texte {
			&:empty {
				display: none;
			}
		}

		.services {
			position: relative;
			z-index: 10;
			padding: 15px 0 0 0;
			margin-bottom: 30px;
            @include tablet_landscape{
                margin-bottom: 20px;
            }
			ul {
				font-size: 0;
				li {
					width: 45px;
					height: 45px;
					display: inline-block;
					vertical-align: middle;
					border: 1px solid $c_border;
					margin: 5px 10px 5px 0;
					&.disabled {
						background: $c_grey;
						span[class^="icon-"], span[class*="icon"] {
							opacity: 0.2;
						}
					}
					.tooltiptext {
						@include ft($f_txt, 12px, 400, $c_white, 14px);
						width: calc(100% + 40px);
						margin: 0;
						top: auto;
						bottom: calc(100% + 5px);
						left: 0;
						border-radius: 4px;
						padding: 10px 13px;
						background: #4c4c4c;
						box-shadow: 0 0 10px rgba($c_dark, 0.3);
						&:after {
							top: auto;
							bottom: -16px;
							left: 15px;
							margin: 0;
							border-color: #4c4c4c transparent transparent transparent;
						}
					}
					span {
						font-size: 21px;
						display: block;
						color: $c_txt;
						text-align: center;
						line-height: 45px;
					}
				}
			}
		}

		.bloc-lei-avantages {
			position: relative;
			@include clearfix();
			> div {
                margin: 0 0 10px 0;
                width: calc(50% - 20px);
                float: left;
                @include mobile{
                    width: 100%;
                    float: none;
                }
                &:nth-child(even) {
                    margin-left: 40px;
                    @include mobile{
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
				&:last-child {
					margin-bottom: 0;
				}
				&:nth-child(2) {
					ul {
						li {
							color: rgba($c_txt,0.5);
                            &:before{
                                content: "\e961";
                                color: rgba($c_dark,0.5);
                            }
						}
					}
					p {
                        color: rgba($c_txt,0.5);
					}
				}
				ul {
                    position: relative;
                    padding: 0;
                    margin: 0;
					li {
						@include ft($f_txt, 16px, 400, $c_txt, 20px);
                        position: relative;
						margin-bottom: 15px;
                        padding-left: 40px;
                        @include mobile{
                            padding-left: 30px;
                        }
                        &:before{
                            content: "\e960";
                            @include font();
                            position: absolute;
                            top: 1px;
                            left: 0;
                            font-size: 20px;
                            color: $c_main;
                        }
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				p {
					@include ft($f_txt, 16px, 400, $c_txt, 20px);
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.disclamer-horaires {
			padding-bottom: 15px;
		}
		.bloc-info-covid {
			padding: 0 0 25px 0;
			margin-top: -10px;
		}

		.bloc-lei-info {
			ul {
				li {
					@include ft($f_txt, 16px, 400, $c_txt, 20px);
                    padding: 12px 20px;
					&:nth-child(odd) {
						background: $c_grey;
					}
					.sub {
						list-style-type: disc;
						padding-left: 37px;
						margin-top: 5px;
						li {
							padding: 0;
							margin: 0;
							background: none;
						}
					}
				}
			}
		}

		.bloc-lei-klm {
			margin-bottom: 20px;
			> div {
				width: 100%;
				height: 385px;
				background: $c_grey;
			}
		}

		.bloc-lei-price {
			ul {
				overflow: hidden;
                margin-bottom: 20px;
				li {
					@include ft($f_hx, 16px, 400, $c_white, 20px);
					background: $c_main;
					display: block;
					padding: 20px;
                    margin-bottom: 1px;
                    @include clearfix();
                    &:last-child{
                        margin-bottom: 0;
                    }
					/* #CHANGE COLOR THEME */
					.site_noel & {
						background: $c_noel1;
					}
					.site_vins & {
						background: $c_vins1;
					}
					.site_velo & {
						background: $c_velo1;
					}
					span {
						float: left;
						max-width: calc(100% - 50px);
					}
					.price {
						float: right;
						font-weight: 700;
					}
				}
			}
		}

		.bloc-lei-label {
			figure {
				display: inline-block;
				vertical-align: top;
				background: none;
				margin: 0 20px 20px 0;
				&.no-space {
					margin: 0 0 20px 0;
					& + :not(.no-space) {
						margin-left: 20px;
					}
				}
				img {
					width: auto;
					height: 100px;
                    @include mobile{
                        height: 50px;
                    }
				}
			}
		}

		.bloc-lei-download {
			ul {
				margin-bottom: 0;
				@include clearfix();
				li {
                    width: calc(50% - 10px);
                    float: left;
                    margin-bottom: 15px;
                    padding: 0;
                    @include mobile{
                        width: 100%;
                        float: none;
                    }
                    &:nth-child(even) {
                        margin-left: 20px;
                        @include mobile{
                            margin-left: 0;
                        }
                    }
					&:before {
						content: none;
					}
                    @include mobile{
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
					a {
						display: flex;
                        align-items: center;
						padding: 0 0 8px 0;
						border-bottom: 1px solid rgba($c_dark, 0.2);
						@include addTransition();
						&:hover, &:focus {
							body:not(.no-hover) & {
								.title {
									color: $c_main;
								}
							}
						}
						.title {
							@include ft($f_txt, 16px, 400, $c_txt, 20px);
                            text-transform: none;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							display: inline-block;
							margin: 0;
                            flex:1;
                            padding-right: 20px;
							@include addTransition();
						}
						.icon-ico-download {
							float: right;
							color: $c_main;
							line-height: 24px;
							margin-left: 15px;
                            flex: none;
							/* #CHANGE COLOR THEME */
							.site_noel & {
								color: $c_noel1;
							}
							.site_vins & {
								color: $c_vins1;
							}
							.site_velo & {
								color: $c_velo1;
							}
						}
					}
				}
			}

			> div {
				margin-top: 30px;
                margin-top: 40px;
                margin-bottom: -20px;
				ul {
					li {
						margin-bottom: 20px;
						border-radius: 23px;
						overflow: hidden;
						a {
                            display: flex;
                            align-items: center;
							padding: 20px;
							border: none;
							color: $c_white;
							background: $c_main;
							@include addTransition();
							/* #CHANGE COLOR THEME */
							.site_noel & {
								background: $c_noel2;
							}
							.site_vins & {
								background: $c_vins2;
							}
							.site_velo & {
								background: $c_velo2;
							}
							&:hover, &:focus {
								background: darken($c_main, 15%);
								.title {
									color: $c_white;
								}
								/* #CHANGE COLOR THEME */
								body.site_noel:not(.no-hover) & {
									background: darken($c_noel2, 10%);
									.title{
										color: $c_white;
									}
								}
								body.site_vins:not(.no-hover) & {
									background: darken($c_vins2, 10%);
									.title{
										color: $c_white;
									}
								}
								body.site_velo:not(.no-hover) & {
									background: darken($c_velo2, 10%);
									.title{
										color: $c_white;
									}
								}
							}
							&:after {
								content: '';
								position: absolute;
								top: 20px;
								right: 80px;
								bottom: 0;
								height: calc(100% - 40px);
								width: 1px;
								background: rgba($c_dark, 0.2);
							}
							.title {
								@include ft($f_txt, 14px, 400, $c_white, 18px);
								white-space: normal;
                                flex: 1;
                                padding-right: 20px;
								/* #CHANGE COLOR THEME */
								.site_noel &, .site_vins &, .site_velo & {
									color: $c_white;
								}
							}
							.icon-ico-download {
								color: white;
								font-size: 20px;
								position: absolute;
								top: 50%;
								right: 0;
								margin: 0 30px;
								transform: translateY(-50%);
                                flex: none;
							}
						}
					}
				}
			}
		}

		.date {
			margin-bottom: 25px;
			& + iframe {
				height: 260px;
				@include min-tablet_portrait {
					height: 580px;
				}
			}
			span {
				@include ft($f_txt, 20px, 700, $c_txt, 25px);
				display: block;
				time {
					font-weight: 400;
				}
			}
		}
		.special-date {
			p {
				@include ft($f_txt, 14px, 400, $c_txt, 20px);
				margin-bottom: 35px;
				&:last-child {
					margin-bottom: 15px;
				}
			}
		}
	}

	/* Fait apparaitre le contenu de l'onglet sélectionné */
	#tab-1:checked ~ .wrapper-content .tab-1,
	#tab-2:checked ~ .wrapper-content .tab-2,
	#tab-3:checked ~ .wrapper-content .tab-3,
	#tab-4:checked ~ .wrapper-content .tab-4 {
		display: block;
		transition: all ease 0.5s;
	}

	#tab-1:checked ~ .boutons div label[for="tab-1"],
	#tab-2:checked ~ .boutons div label[for="tab-2"],
	#tab-3:checked ~ .boutons div label[for="tab-3"],
	#tab-4:checked ~ .boutons div label[for="tab-4"] {
		color: #fff;
		background: $c_main;
		/* #CHANGE COLOR THEME */
		.site_noel & {
			color: $c_white;
			background: $c_noel2;
		}
		.site_vins & {
			color: $c_white;
			background: $c_vins2;
		}
		.site_velo & {
			color: $c_white;
			background: $c_velo2;
		}
	}

	@include min-tablet_portrait {
		#tab-1:checked ~ .boutons div label[for="tab-1"],
		#tab-2:checked ~ .boutons div label[for="tab-2"],
		#tab-3:checked ~ .boutons div label[for="tab-3"],
		#tab-4:checked ~ .boutons div label[for="tab-4"] {
			background: $c_white;
			color: $c_txt;
			/* #CHANGE COLOR THEME */
			.site_noel & {
				background: $c_white;
				color: $c_txt;
			}
			.site_vins & {
				background: $c_white;
				color: $c_txt;
			}
			.site_velo & {
				background: $c_white;
				color: $c_txt;
			}
		}

		#tab-1:checked ~ .boutons div label[for="tab-1"]:before,
		#tab-2:checked ~ .boutons div label[for="tab-2"]:before,
		#tab-3:checked ~ .boutons div label[for="tab-3"]:before,
		#tab-4:checked ~ .boutons div label[for="tab-4"]:before {
			opacity: 1;
		}
	}

}