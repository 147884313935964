// Fil d'ariane
// ==========================================================================
#breadcrumb {
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 20px 40px;
    z-index: 1000;
    @include mobile{
        display: none;
    }
    > span > span > a {
        &:before {
            content: "\e916";
            @include font();
            font-size: 15px;
            line-height: 20px;
            float: left;
            margin-right: 10px;
        }
    }
    span {
        @include ft($f_txt, 13px, 400, $c_white, 20px);
        white-space: nowrap;
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    a {
        @include ft($f_txt, 13px, 400, $c_white, 20px);
        font-size: 0;
        display: inline-block;
        vertical-align: middle;
        @include addTransition();
        &:after {
            content: "\e95c";
            @include font();
            font-size: 18px;
            line-height: 20px;
            color: $c_main;
            position: relative;
            top: 1px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 4px 0 7px;
            body.site_noel & {
                color: $c_noel2;
            }
            body.site_vins & {
                color: $c_vins2;
            }
            body.site_velo & {
                color: $c_velo2;
            }
        }
        &:hover, &:focus {
            body:not(.no-hover) & {
                span{
                    color: $c_main;
                    text-decoration: underline;
                }
            }
            body.site_noel:not(.no-hover) & {
                span{
                    color: $c_noel2;
                }
            }
            body.site_vins:not(.no-hover) & {
                span{
                    color: $c_vins2;
                }
            }
            body.site_velo:not(.no-hover) & {
                span{
                    color: $c_velo2;
                }
            }
        }
    }
}

.breadcrumb-white{
    span, a {
        color: $c_txt;
    }
    a {
        &:hover, &:focus {
            body:not(.no-hover) & {
                color: $c_main;
            }
            /* #CHANGE COLOR THEME */
            body.site_noel:not(.no-hover) & {
                color: $c_noel2;
            }
            body.site_vins:not(.no-hover) & {
                color: $c_vins2;
            }
            body.site_velo:not(.no-hover) & {
                color: $c_velo2;
            }
        }
    }
}