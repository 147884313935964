
// =============================================
// Share button
// =============================================

// Share Button Wrapper
.social-share{
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 50000;
    opacity: 1;
    @include addTransition();

    display: none; // Par ordre de Thomas, on n'affiche plus le social share
    @include mobile{
        right: 20px;
        bottom: 80px;
    }
}

// Toggle Button
.toggle {
    position: relative;
    height: 65px;
    display: block;
    z-index: 1000;
    background: $c_main;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    @include addTransition();
    /* #CHANGE COLOR THEME */
    .site_noel & {
        background: $c_noel3;
    }
    .site_vins & {
        background: $c_vins1;
    }
    .site_velo & {
        background: $c_velo1;
    }

    // Share Icon
    &:after {
        content: "\e918";
        @include font();
        font-size: 35px;
        padding: 0;
        text-align: center;
        position: relative;
        width: 65px;
        height: 65px;
        line-height: 65px;
        left: -2px;
        color: $c_white;
    }

    &:hover, &:focus {
        body:not(.no-hover) & {
            cursor: pointer;
            background: darken($c_main, 15%);
        }
        /* #CHANGE COLOR THEME */
        body.site_noel:not(.no-hover) & {
            background: darken($c_noel2, 15%);
        }
        body.site_vins:not(.no-hover) & {
            background: darken($c_vins1, 15%);
        }
        body.site_velo:not(.no-hover) & {
            background: darken($c_velo1, 15%);
        }
    }

    // Hidden input to toggle state
    &-input {
        display: none;
    }

}

// =============================================
// Network List
// =============================================

.network-list {
    // List Item
    position: absolute;
    bottom: 30px;
    li {
        position: absolute;
        top: 0;
        left: 12px;
        display: block;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);
    }

    // Link
    a {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: $c_txt;
        overflow: hidden;
        text-indent: 120%;
        font-size: 0;
        text-decoration: none;
        @include addTransition();
        &:hover, &:focus {
            body:not(.no-hover) & {
                background: $c_main;
            }
            /* #CHANGE COLOR THEME */
            body.site_noel:not(.no-hover) & {
                background: $c_noel2;
            }
            body.site_vins:not(.no-hover) & {
                background: $c_vins1;
            }
            body.site_velo:not(.no-hover) & {
                background: $c_velo1;
            }
        }
    }

    // Network Icon
    a:before {
        @include font();
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        text-indent: 0;
        // box-shadow: inset 0 0 0 1px $toggle;
    }

    a:after {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: -1;
        border-radius: 50%;
    }

    // Twitter
    .twitter {
        a:before {
            content: "\e90d";
            color: $c_white;
        }
    }

    // Facebook
    .facebook {
        a:before {
            content: "\e903";
            color: $c_white;
        }
    }

    // Linkedin
    .linkedin {
        a:before {
            content: "\e91b";
            color: $c_white;
        }
    }

    // Googleplus
    .googleplus {
        a:before {
            content: "\e91a";
            color: $c_white;
        }
    }

    // Mail
    .mail {
        a:before {
            content: "\e919";
            color: $c_white;
        }
    }
}

// =============================================
// Open Network List

input:checked ~ .network-list {
    li {
        opacity: 1;
    }
    li:nth-child(1) {
        top: -90px;
    }
    li:nth-child(2) {
        top: -140px;
    }
    li:nth-child(3) {
        top: -190px;
    }
    li:nth-child(4) {
        top: -240px;
    }
    li:nth-child(5) {
        top: -290px;
    }
    li:nth-child(6) {
        top: -340px;
    }
}