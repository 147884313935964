.page-itineraire {
    .map {
        height: 0;
        min-height: 0;
        @include min-tablet_landscape {
            position: relative;
            z-index: 100;
            width: 100%;
            height: auto;
            min-height: 500px;
            display: block;
            transform: none;
        }

        .maps {
            @include min-tablet_landscape {
                height: calc(100vh - 175px) !important;
                max-height: 100% !important;
                &:after {
                    content: none;
                }
            }
        }

        .icon-ico-gallery-cross {
            @include min-tablet_landscape {
                display: none;
            }
        }

        .data-bottom {
            .listing-map {
                bottom: 100px;
            }

            .wrapper-critere {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 30px;
                z-index: 100;
            }
        }
    }
}

// Wrapper Contenu - Page itinéraire
// ==========================================================================
.wrapper-content-itineraire {
    position: relative;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;

    .left-content {
        order: 1;
        width: calc(100% - 600px);
        padding-right: 25px;
        @include desktop{
            width: calc(100% - 500px);
        }
        @include tablet_landscape{
            width: calc(100% - 400px);
        }
        @include tablet_portrait {
            width: 100%;
            order: 2;
            padding-right: 0;
        }
    }

    .right-content {
        position: sticky;
        position: -webkit-sticky;
        top: $header-height+20;
        order: 2;
        width: 600px;
        height: calc(100vh - #{$header-height});
        margin-top: -260px;
        @include desktop{
            width: 500px;
        }
        @include tablet_landscape{
            width: 400px;
        }
        @include tablet_portrait {
            position: relative;
            top: 0;
            width: 100%;
            height: auto;
            margin-top: 0;
            margin-bottom: 40px;
            order: 1;
        }
        @include mobile {
            margin-bottom: 20px;
        }
    }
}

// Barre Rouge - Top Fixe Meta Données
// ==========================================================================
.top-itineraire {
    position: relative;
    @extend .background-alsace-2022;

    body.site_noel & {
        background: $c_noel3;
    }
    body.site_vins & {
        background: $c_vins1;
    }
    body.site_velo & {
        background: $c_velo1;
    }
    .infos{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 30px 10px;
        @include tablet_portrait{
            display: block;
            padding: 25px 10px;
        }
        @include mobile{
            padding: 20px;
        }
    }

    .datas {
        @include ft($f_txt, 12px, 400, $c_white, 16px);
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        @include tablet_landscape{
            padding: 0 5px;
        }
        @include tablet_portrait{
            padding: 5px 20px;
        }
        @include mobile{
            margin-bottom: 10px;
            padding: 0;
        }
        &:last-child{
            @include mobile{
                margin-bottom: 0;
            }
        }
    }

    [class^="icon-"], [class*=" icon-"] {
        width: 45px;
        height: 45px;
        background: $c_white;
        border-radius: 50%;
        font-size: 24px;
        line-height: 45px;
        display: block;
        color: $c_main;
        text-align: center;
        margin-right: 10px;
        flex: none;
        @include tablet_landscape{
            width: 40px;
            height: 40px;
            font-size: 20px;
            line-height: 40px;
        }

        body.site_noel & {
            color: $c_noel1;
        }
        body.site_vins & {
            color: $c_vins1;
        }
        body.site_velo & {
            color: $c_velo1;
        }
    }

    .title-data {
        @include ft($f_hx, 12px, 700, $c_white, 12px);
        display: block;
        text-transform: uppercase;
        margin-bottom: 4px;
    }
}


// BLOC ETAPE INTERMEDIAIRE
.bloc-etape-intermediaire{
    .item{
        display: flex;
        align-items: flex-start;
        @include mobile{
            display: block;
        }
        & + .item{
            margin-top: 20px;
        }
    }
    .image{
        width: 220px;
        flex: none;
        @include tablet_landscape{
            width: 180px;
        }
        @include tablet_portrait{
            width: 220px;
        }
        figure{
            background: none;
            img{
                margin: 0;
            }
        }
        & + .bloc-texte{
            padding-left: 20px;
            @include mobile{
                padding-left: 0;
                margin-top: 15px;
            }
            .basic-link{
                margin: 0;
            }
        }
    }
}