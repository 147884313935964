#wrapper {
    position: absolute;
    top: $header-height;
    left: 0;
    bottom: 80px;
    z-index: 1;
    display: block;
    width: 100%;
    background: $c_white;
    height: calc(100vh - #{$header-height} - 80px);
    overflow: hidden;
    @include tablet_portrait {
        height: 100%;
        overflow: initial;
    }

    & + footer {
        display: none;
    }
}

#scroller {
    position: relative;
    transform: none;
    @include min-tablet_landscape {
        position: relative;
        z-index: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        height: 100%;
        transform: translateZ(0);
        user-select: none;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.5s ease;
    }
    @include mobile {
        padding-bottom: 60px;
    }

    > div:not(.parallax-text) {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        /* Longueur à calculer en JS */
        width: 4000px;
        @include tablet_portrait {
            width: 100%;
        }

        > * {
            position: relative;
            display: inline-block;
            vertical-align: top;
            height: 100%;
        }

        header {
            top: 50%;
            transform: translateY(-50%);
            transform: translate3d(0, -50%, 0);
            margin: 0 80px;
            background: none;
            width: 500px;
            height: auto;
            &:before{
                content: none;
            }
            @include tablet_landscape {
                width: 450px;
                margin: 0 40px;
            }
            @include tablet_portrait {
                position: relative;
                transform: none;
                width: calc(100% - 80px);
                padding: 40px 0;
            }
            @include mobile {
                width: calc(100% - 40px);
                margin: 0 20px;
            }

            .caption {
                position: relative;
                bottom: 0;
                transform: none;
                margin: 0;
                width: 100%;

                > * {
                    max-width: 100%;
                }
            }

            .surtitre {
                font-size: calculateRem(50px);
                line-height: calculateRem(50px);
                color: $c_main;
                margin-bottom: 0;
                @include tablet_landscape {
                    font-size: calculateRem(38px);
                    line-height: calculateRem(35px);
                }

                body.site_noel & {
                    color: $c_noel1;
                }
                body.site_vins & {
                    color: $c_vins1;
                }
                body.site_velo & {
                    color: $c_velo1;
                }
            }

            h1 {
                font-size: calculateRem(60px);
                line-height: calculateRem(60px);
                color: $c_txt;
                @include tablet_portrait {
                    font-size: calculateRem($s_h1);
                    line-height: calculateRem($s_h1);
                }
                @include mobile {
                    font-size: calculateRem(30px);
                    line-height: calculateRem(30px);
                }
                @media screen and (max-height: 800px) {
                    font-size: calculateRem(35px);
                    line-height: calculateRem(35px);
                }
            }

            p {
                color: $c_txt;
            }
        }

        .wrapper-top {
            padding: 0;
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            @include tablet_portrait {
                width: calc(100% - 80px);
                margin: 0 40px;
            }
            @include mobile {
                width: calc(100% - 40px);
                margin: 0 20px;
            }

            .item {
                float: left;
                @include tablet_portrait {
                    float: none;
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 40px;
                }
                @include mobile {
                    display: block;
                }

                &:nth-child(even) {
                    figure {
                        order: 2;
                        @include mobile {
                            margin-left: 20%;
                        }

                        figcaption {
                            @include mobile {
                                text-align: right;
                            }
                        }

                        .number {
                            @include tablet_portrait {
                                right: 0;
                                margin-right: -20px;
                            }
                            @include mobile {
                                right: auto;
                                margin-right: auto;
                            }
                        }
                    }

                    .bloc-texte {
                        @include tablet_portrait {
                            text-align: right;
                            padding: 20px 20px 20px;
                        }
                        @include mobile {
                            padding: 20px 0 0 0;
                        }
                    }
                }

                &:last-child {
                    margin-right: 140px;
                    @include tablet_portrait {
                        margin-bottom: 40px;
                    }
                }

                @include min-tablet_portrait {
                    font-size: 0;
                    display: flex;
                    float: none;
                    width: 100%;
                    margin-bottom: 50px;
                }
                @include min-tablet_landscape {
                    display: inline-block;
                    vertical-align: middle;
                    width: auto;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    transform: translate3d(0, -50%, 0);
                    margin: 0 30px 0 0;
                }

                figure {
                    background: none;
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: normal;
                    @include tablet_portrait {
                        width: 40%;
                    }
                    @include mobile {
                        width: 80%;
                    }

                    figcaption {
                        max-width: 300px;
                        background: none;
                    }

                    .number {
                        @include ft($f_hx, 300px, 700, $c_main, 280px);
                        position: absolute;
                        margin-top: -268px;
                        left: auto;
                        margin-left: -20px;
                        @include tablet_portrait {
                            font-size: 200px;
                            line-height: 180px;
                            margin-top: -175px;
                        }

                        body.site_noel & {
                            color: $c_noel2;
                        }
                        body.site_vins & {
                            color: $c_vins2;
                        }
                        body.site_velo & {
                            color: $c_velo2;
                        }
                    }

                    img {
                        margin: 0;
                        width: 100%;
                        height: auto;
                        box-shadow: 70px 70px 70px 0 rgba($c_dark, 0.15);
                        @include tablet_landscape {
                            box-shadow: 0 0 20px 0 rgba($c_dark, 0.15);
                        }
                    }
                }

                .bloc-texte {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 0 0 40px;
                    max-width: 390px;
                    @include tablet_landscape {
                        width: 60%;
                        display: inline-block;
                        vertical-align: top;
                        white-space: normal;
                        padding: 20px 0 20px 20px;
                    }
                    @include tablet_portrait {
                        max-width: 100%;
                    }
                    @include mobile {
                        width: 100%;
                        padding: 20px 0 0 0;
                    }
                }
            }

            .month {
                @include ft($f_txt, 20px, 500, $c_txt, 24px);
                text-transform: uppercase;
                margin-bottom: 15px;
                display: block;
                @include mobile {
                    font-size: calculateRem(16px);
                    line-height: calculateRem(20px);
                    margin-bottom: 5px;
                }
            }

            h2 {
                @include ft($f_spe, 55px, 400, $c_main, 40px);
                text-transform: none;
                margin: 0 0 25px 0;
                @include mobile {
                    font-size: calculateRem(40px);
                    line-height: calculateRem(40px);
                    margin-bottom: 10px;
                }

                body.site_noel & {
                    color: $c_noel1;
                }
                body.site_vins & {
                    color: $c_vins1;
                }
                body.site_velo & {
                    color: $c_velo1;
                }
            }
        }
    }
}

/*
NAVIGUATION BOTTOM
*/
.nav-fixed {
    position: fixed;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 60px;
    overflow: hidden;
    background: $c_grey;
    @include tablet_portrait {
        display: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        background: rgb(241, 241, 241);
        background: -moz-linear-gradient(90deg, rgba(241, 241, 241, 0) 0%, rgba(241, 241, 241, 1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(241, 241, 241, 0) 0%, rgba(241, 241, 241, 1) 100%);
        background: linear-gradient(90deg, rgba(241, 241, 241, 0) 0%, rgba(241, 241, 241, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f1f1", endColorstr="#f1f1f1", GradientType=1);
        width: 100px;
        height: 100%;
    }

    > div {
        position: relative;
    }

    .scrollable {
        overflow: hidden;
    }

    ul {
        white-space: nowrap;
        counter-reset: number;

        li {
            display: inline-block;
            vertical-align: middle;

            &:nth-child(2) {
                margin-left: 0;
            }

            &:not(.floating) {
                &:before {
                    @include ft($f_hx, 50px, 700, rgba($c_dark, 0.15), 50px);
                    counter-increment: number;
                    content: counter(number);
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                    opacity: 0;
                    z-index: 1000;
                    @include addTransition();
                }
            }

            &.floating {
                height: 100%;
                position: absolute;
                top: 0;
                width: 0;
                left: 0;
                background: $c_main;
                z-index: 25;
                @include addTransition();
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    background: $c_noel2;
                }
                .site_vins & {
                    background: $c_vins2;
                }
                .site_velo & {
                    background: $c_velo2;
                }
            }

            &.current {
                &:before {
                    opacity: 1;
                } 
                a {
                    color: $c_white;
                }
                &.hover {
                    body:not(.no-hover) & {
                        &:before {
                            opacity: 1;
                        }
                        a {
                            color: $c_white;
                        }
                    }
                }
            }

            &.hover {
                body:not(.no-hover) & {
                    &:before {
                        opacity: 0;
                    }

                    a {
                        color: $c_white;
                    }

                    &:not(.current) {
                        &:before {
                            opacity: 0;
                        }
                        a {
                            color: $c_txt;
                        }
                    }
                }
            }

            a {
                @include ft($f_hx, 13px, 400, $c_txt, 20px);
                position: relative;
                padding: 20px 40px;
                display: block;
                z-index: 10000;
                text-align: center;
                @include addTransition();

                span {
                    font-weight: 700;
                }

                &:hover, &.active {
                    body:not(.no-hover) & {
                        color: $c_white;
                    }
                }
            }
        }
    }

    .selecteurs {
        &.disableCurrent {
            ul {
                li.current {
                    &:before {
                        opacity: 0;
                    }

                    a {
                        color: $c_txt;
                    }
                }
            }
        }
    }

    .nav {
        position: absolute;
        right: -20px;
        top: 0;
        bottom: 0;
        width: 80px;
        height: 100%;
        z-index: 100000;

        > span {
            position: absolute;
            top: 0;
            padding: 0 10px;

            &:before {
                @include font();
                line-height: 60px;
                font-size: 24px;
                @include addTransition();
            }

            &:hover, &:active {
                body:not(.no-hover) & {
                    cursor: pointer;

                    &:before {
                        color: $c_main;
                    }
                }

                /* #CHANGE COLOR THEME */
                body.site_noel:not(.no-hover) & {
                    &:before {
                        color: $c_noel2;
                    }
                }
                body.site_vins:not(.no-hover) & {
                    &:before {
                        color: $c_vins2;
                    }
                }
                body.site_velo:not(.no-hover) & {
                    &:before {
                        color: $c_velo2;
                    }
                }
            }
        }

        .prev {
            left: 0;
            &:before {
                content: "\e91d";
            }
        }

        .next {
            right: 0;
            &:before {
                content: "\e91e";
            }
        }
    }
}


.parallax-text {
    position: absolute;
    bottom: -80px;
    left: 400px;
    z-index: -1;
    display: none;
    overflow: hidden;

    .ie & {
        display: none;
    }

    .no-hover & {
        left: 40px;
    }

    strong {
        @include ft($f_spe, 400px, 400, $c_grey, 440px);
        @media screen and (max-height: 760px) {
            font-size: calculateRem(200px);
            line-height: calculateRem(200px);
        }
    }
}