// Style General - Overlay - All site
// ==========================================================================
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: none;
    background: $c_white;
    z-index: 20000010;
    max-width: 640px;
    transform: translateX(-100%);
    transform: translate3d(-100%, 0, 0);
    transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    @include mobile {
        width: 100vw;
        max-width: 100%;
    }

    .overlay-load & {
        opacity: 1;
        visibility: visible;
    }

    &.open {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
    }

    &.no-gradient {
        .content {
            &:after {
                content: none;
            }
        }
    }

    .top {
        position: relative;
        margin: 20px 20px 0 20px;
        height: 75px;
        background: $c_light;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mobile{
            height: 60px;
        }
    }

    .title-overlay {
        @include ft($f_hx, 20px, 700, $c_txt, 24px);
        text-transform: uppercase;
        padding: 0 30px;
        @include mobile{
            font-size: calculateRem(16px);
            line-height: calculateRem(20px);
            padding: 0 20px;
        }
    }

    .close-overlay {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 17px;
        padding: 29px 30px;
        display: block;
        z-index: 10000;
        color: $c_main;
        @include addTransition();
        @include mobile{
            font-size: 16px;
            padding: 22px 20px;
        }
        /* #CHANGE COLOR THEME */
        .site_noel & {
            color: $c_noel1;
        }
        .site_vins & {
            color: $c_vins1;
        }
        .site_velo & {
            color: $c_velo1;
        }

        &:hover {
            body:not(.no-hover) & {
                cursor: pointer;
                color: $c_txt;
            }
        }
    }

    .content {
        height: calc(100% - 95px);
        padding: 0 50px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        @include mobile{
            height: calc(100% - 80px);
            padding: 0 20px;
        }
    }

    .overlay-bottom {
        position: absolute;
        bottom: 0;
        margin: 0 50px 50px 50px;
        width: calc(100% - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100000;
        @include mobile{
            margin: 0 20px 20px 20px;
            width: calc(100% - 40px);
        }
        .btn{
            width: 100%;
            & + .btn{
                margin-left: 20px;
            }
        }
    }
}

// Overlay - Page LEI - Infos et Réservations
// ==========================================================================
.overlay.overlay-lei-contact {
    .content {
        > div {
            height: 100%;
        }
    }
}

// Overlay - Map
// ==========================================================================
.overlay-map {
    width: 100vw;
    max-width: 100%;

    .close-overlay {
        position: absolute;
        top: 20px;
        right: 20px;
        margin: 0;
        background: $c_grey;
        padding: 20px;
        font-size: 20px;
        box-shadow: 0 5px 10px rgba($c_dark,0.2);
        @include mobile{
            font-size: 16px;
            padding: 15px;
        }
    }

    .maps {
        width: 100% !important;
        max-height: 100%;
        height: 100% !important;

        &:after {
            content: none;
        }
    }

    .data-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: $c_white;
        z-index: 1000;
        padding-top: 15px;
        transform: translate3d(0, 0, 0);
        @include min-tablet_landscape {
            display: none;
        }

        .wrapper-critere {
            padding-bottom: 0;
            -webkit-overflow-scrolling: touch;
            li {
                &:first-child {
                    margin-left: 20px;
                }

                &:last-child {
                    margin-right: 20px;
                }
            }
        }

        .paginate-mobile {
            @include ft($f_txt, 12px, 400, $c_txt, 25px);
            width: 100%;
            text-align: center;
            padding: 5px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a, strong, span {
                @include ft($f_hx, 14px, 500, $c_txt, 44px);
                text-transform: uppercase;
                letter-spacing: 1px;
                position: relative;
            }

            > a {
                font-size: 0;
                color: transparent;

                &:after {
                    content: '\e91d';
                    @include font();
                    font-size: 16px;
                    color: $c_main;

                    .site_noel & {
                        color: $c_noel1;
                    }
                    .site_vins & {
                        color: $c_vins1;
                    }
                    .site_velo & {
                        color: $c_velo1;
                    }
                }

                &.btn-next {
                    &:after {
                        content: '\e91e';
                    }
                }

                &.disabled {
                    pointer-events: none;

                    &:after {
                        color: black;
                        opacity: .45;
                    }
                }
            }

            .pagination-number {
                span:first-of-type {
                    display: none;
                }

                .list {
                    a {
                        margin: 0 5px;
                        text-align: center;
                        display: inline-block;
                        vertical-align: middle;
                        @include addTransition();

                        &.active {
                            color: $c_main;
                            font-weight: 700;

                            body.site_noel & {
                                color: $c_noel1;
                            }
                            body.site_vins & {
                                color: $c_vins1;
                            }
                            body.site_velo & {
                                color: $c_velo1;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Overlay - Filtres
// ==========================================================================
.overlay-filtre {
    &.overlay-lei-contact{
        .content{
            overflow: hidden;
        }
    }
    .content {
        &:before {
            content: '';
            @extend .gradient-transparent;
            z-index: 100000;
        }

        &:after {
            content: '';
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 200px;
        }

        .bloc-form {
            padding: 40px 0 0 0;
            margin: 0;
            @include mobile{
                padding: 20px 0 0 0;
            }
        }

        .algolia-place {
            position: relative;
            top: auto;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            margin-top: 25px;
            border-radius: 0;
            box-shadow: none;
            @include min-tablet_landscape {
                display: none;
            }

            input {
                box-shadow: none;
            }
        }

        > div {
            margin-top: 35px;
            > span {
                @include ft($f_hx, 15px, 700, $c_txt, 20px);
                text-transform: uppercase;
                display: block;
            }

            > .frm_forms {
                height: 100%;

                > form {
                    height: 100%;

                    > div {
                        height: 100%;

                        fieldset {
                            height: 100%;
                        }
                    }
                }
            }

            ul {
                margin-top: 10px;
                @include clearfix();

                li {
                    border-bottom: 1px solid rgba($c_dark, 0.2);
                    label{
                        width: 100%;
                    }

                    &.date {
                        @include mobile {
                            flex-wrap: wrap;
                            padding-bottom: 25px;

                            > div {
                                width: 100%;
                                display: flex;
                                align-items: center;

                                label {
                                    width: auto;
                                }

                                input {
                                    display: block;
                                    flex-grow: 1;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }

                .date {
                    & ~ li:nth-child(even) {
                        margin-left: 0;
                    }
                    & ~ li:nth-child(odd) {
                        margin-left: 40px;
                        @include mobile{
                            margin-left: 0;
                        }
                    }
                }
            }
        }


        &.no-margin {
            margin-top: 0 !important;
            margin-bottom: 0;
        }
        .steps-wrapper {
            overflow: hidden;
            position: absolute;
            -webkit-overflow-scrolling: touch;
            bottom: 130px;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;

            &[data-maxstep="3"] {
                .info-nav:before {
                    content: '1/2';
                }
                .steps {
                    width: 300%;
                    & > div {
                        width: 33.333333%;
                    }
                }
                &[data-step="2"] {
                    .info-nav:before {
                        content: '2/2';
                    }
                    .steps {
                        transform: translate(-33.333333%, 0);
                        transform: translate3d(-33.333333%, 0, 0);
                    }
                }
                &[data-step="3"] {
                    .info-nav:before {
                        content: '';
                    }
                    .steps {
                        transform: translate(-66.666666%, 0);
                        transform: translate3d(-66.666666%, 0, 0);
                    }
                }
            }
            &[data-maxstep="4"] {
                .info-nav:before {
                    content: '1/3';
                }
                .steps {
                    width: 400%;
                    & > div {
                        width: 25%;
                    }
                }
                &[data-step="2"] {
                    .info-nav:before {
                        content: '2/3';
                    }
                    .steps {
                        transform: translate(-25%, 0);
                        transform: translate3d(-25%, 0, 0);
                    }
                }
                &[data-step="3"] {
                    .info-nav:before {
                        content: '3/3';
                    }
                    .steps {
                        transform: translate(-50%, 0);
                        transform: translate3d(-50%, 0, 0);
                    }
                }
                &[data-step="4"] {
                    .info-nav:before {
                        content: '';
                    }
                    .steps {
                        transform: translate(-75%, 0);
                        transform: translate3d(-75%, 0, 0);
                    }
                }
            }
            .steps {
                position: relative;
                transition: all 0.3s ease;
                height: 100%;
                overflow: hidden;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 130px;
                    height: 50px;
                    z-index: 10;
                    pointer-events: none;
                    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    @include min-tablet_portrait{
                        content: none
                    }
                }
                > div {
                    float: left;
                    //height: 60vh;
                    height: calc(100% - 130px);
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    padding-bottom: 50px;

                    &.step-4 {
                        text-align: center;
                        p {
                            @include ft($f_txt, 16px, 700, $c_txt, 24px);
                            margin: 30px auto;
                            border-bottom: 1px solid $c_main;
                            padding: 15px 0;
                            display: inline-block;
                        }
                    }
                }
            }

            &[data-step="1"] .step-nav {
                .nav-prev {
                    display: none;
                }
            }
            &[data-step="2"] .step-nav {
                .nav-prev {
                    display: block;
                    & + .nav-next {
                        width: calc(100% - 56px);
                    }

                    & + .frm_submit {
                        width: calc(100% - 56px);
                    }
                }
            }
            &[data-step="3"] .step-nav {
                .nav-prev {
                    display: block;
                    & + .nav-next {
                        width: calc(100% - 56px);
                    }

                    & + .frm_submit {
                        width: calc(100% - 56px);
                    }
                }
            }
            &[data-step="4"] .step-nav {
                .nav-prev {
                    display: none;
                    & + .nav-next {
                        width: calc(100% - 56px);
                    }

                    & + .frm_submit {
                        width: calc(100% - 56px);
                    }
                }
            }

            &[data-step="1"][data-maxstep="3"] .step-nav {
                .frm_submit {
                    display: none
                }
            }
            &[data-step="3"][data-maxstep="3"] .step-nav {
                .frm_submit {
                    display: none
                }
                .nav-next {
                    display: none;
                }
                .nav-prev {
                    display: none;
                }
            }
            &[data-step="1"][data-maxstep="4"] .step-nav {
                .frm_submit {
                    display: none
                }
                .nav-next {
                    width: 100%;
                }
            }
            &[data-step="2"][data-maxstep="4"] .step-nav {
                .frm_submit {
                    display: none
                }
            }
            &[data-step="4"][data-maxstep="4"] .step-nav {
                .frm_submit {
                    display: none
                }
                .nav-next {
                    display: none;
                }
                .nav-prev {
                    display: none;
                }
            }

            &[data-step="2"][data-maxstep="3"] .step-nav {
                .nav-next {
                    display: none;
                }
                .nav-prev {
                    display: block;
                }
                .frm_submit {
                    display: block;
                }
            }
            &[data-step="3"][data-maxstep="4"] .step-nav {
                .nav-next {
                    display: none;
                }
                .nav-prev {
                    display: block;
                }
                .frm_submit {
                    display: block
                }
            }
            &[data-maxstep="1"] .step-nav {
                .nav-next {
                    display: none;
                }
                .nav-prev {
                    display: none;
                    .frm_submit {
                        display: block
                    }
                }
            }
        }

        .step-nav {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            z-index: 100000;
            .info-nav {
                @include ft($f_txt, 14px, 400, #848484, 20px);
                display: block;
                text-align: center;
                margin: 20px 0;
            }

            .nav-prev {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 44px;
                height: 44px;
                border-radius: 50%;
                background: $c_main;
                margin-right: 10px;
                float: left;
                /* #CHANGE COLOR THEME */
                .site_noel & {
                    background: $c_noel1;
                }
                .site_vins & {
                    background: $c_vins1;
                }
                .site_velo & {
                    background: $c_velo1;
                }
                &:hover, &:focus, &:active {
                    body:not(.no-hover) & {
                        background: $c_dark;
                    }
                }
                span {
                    color: $c_white;
                    font-size: 25px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .nav-next {
                @extend .btn;
                position: relative;
                float: right;
                width: 100% !important;
                & + .frm_submit {
                    margin-left: 56px;
                    width: calc(100% - 56px) !important;
                }
            }

            .frm_submit {
                button {
                    display: block;
                    width: 100% !important;
                }
            }
        }

        .btn{
            background-color: transparent;
            cursor: pointer;
            position: absolute;
            bottom: 50px;
            top:auto;
            left: 0;
            right: 0;
            z-index: 100000;
            color: $c_txt;
            border-color: $c_main;
            @include mobile{
                left: 0;
                right: 0;
            }
            body.site_noel & {
                border-color: $c_noel2;
            }
            body.site_vins & {
                border-color: $c_vins1;
            }
            body.site_velo & {
                border-color: $c_velo1;
            }
            &:hover{
                body:not(.no-hover) & {
                    color: white;
                    border-color: $c_main;
                    background-color: $c_main;
                }
                body:not(.no-hover).site_noel & {
                    background: $c_noel2;
                    border-color: $c_noel2;
                }
                body:not(.no-hover).site_vins & {
                    background: $c_vins1;
                    border-color: $c_vins1;
                }
                body:not(.no-hover).site_velo & {
                    background: $c_velo1;
                    border-color: $c_velo1;
                }

            }
        }
    }

    .overlay-bottom{
        margin-bottom: 8px;
        @include mobile{
            margin-bottom: 16px;
        }

        .basic-link{
            &:after{
                content: '\e91c';
                font-size: 10px;
            }
            &:hover{
                body:not(.no-hover) & {
                    &:after{
                        transform:translate3d(0,0,0);
                        right:0;
                    }
                }
            }
        }
    }
}

// Overlay - ZENCHEF
// ==========================================================================
.overlay-zenchef {
    #zenchef-iframe {
        padding: 0;
        width: 100%;
        height: calc(100vh - 80px);
        overflow: hidden;
    }
}

// Overlay - ARTY
// ==========================================================================
.overlay-arty {
    max-width: 435px;

    .close-overlay {
        top: 0;
        right: 0;
        padding: 22px;
        font-size: 16px;
        margin-right: 15px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

// Overlay - Shadow
// ==========================================================================
.shadow-overlay {
    display: none;
    @include min-tablet_portrait {
        position: fixed;
        top: 0;
        left: 100%;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba($c_dark, 0.5);
        opacity: 0;
        z-index: 10000000;
        display: block;
        overflow: hidden;
        transition: opacity 400ms ease-in-out;
        &.open {
            opacity: 1;
            left: 0;
        }
    }
}

// Message interstitiel
// ==========================================================================
.overlay-popin{
    overflow: auto;
    background-color: transparent;
    max-width:none;
    left: 0;
    right: 0;

    &.open ~.shadow-overlay{
        position: fixed;
        top: 0;
        left: 100%;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba($c_dark, 0.5);
        opacity: 0;
        z-index: 10000000;
        display: block;
        overflow: hidden;
        transition: opacity 400ms ease-in-out;
        &.open {
            opacity: 1;
            left: 0;
        }
    }

    .wrapper{
        position: relative;
        display: block;
        background-color: white;
        margin: 140px auto;
        padding: 20px;
        width: 100%;
        height:auto;
        pointer-events: auto;
        &:after{
            content: '';
            display: block;
            clear: both;
        }


        .entete{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items:center;
            font-size: 20px;
            line-height: 20px;
            padding:27px 30px;
            background-color: $c_grey;
            font-weight: 700;
            text-transform: uppercase;
            @include mobile{
                font-size: 16px;
                line-height: 16px;
            }
            .btn-close{
                position: relative;
                background-color: transparent;
                color: $c_sub;
                padding: 0;
                width:auto;
                height:auto;
                font-size: 20px;
                line-height: 1;
                margin-left: 20px;
                border:none;
                font-weight: 400;
                @include mobile{
                    font-size: 16px;
                }
                @include addTransition();
                body.site_vins & {
                    color: $c_vins2;
                }
                body.site_velo & {
                    color: $c_velo2;
                }
                body.site_noel & {
                    color: $c_noel2;
                }
                
                &:hover{
                    body:not(.no-hover) & {
                        color: $c_main;
                    }
                    body.site_vins:not(.no-hover) & {
                        color: $c_vins1;
                    }
                    body.site_velo:not(.no-hover) & {
                        color: $c_velo1;
                    }
                    body.site_noel:not(.no-hover) & {
                        color: $c_noel1;
                    }
                }
            }
        }
        figure{
            margin-top: 20px;
        }
        .contenu{
            margin-top: 20px;

            .title{
                font-family:$f_hx;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 10px;
                text-transform: uppercase;
                margin-top: 10px;
            }
            form{
                margin-top: 10px;
            }
        }

        .bloc-form{
            .frm_opt_container{
                label{
                    display: block !important;
                }
            }
        }



        &.vertical{
            max-width:520px;
            @include tablet_landscape{
                margin:80px auto;
            }
            @include mobile{
                margin: 20px;
                width:calc(100% - 40px);
            }
        }
        &.horizontal{
            max-width: 960px;
            @media screen and (max-width: 1040px){
                margin:80px 40px;
                width:calc(100% - 80px);
            }
            @include mobile{
                margin: 20px;
                width:calc(100% - 40px);
            }

            .entete{
                float: right;
                width:calc(100% - 340px - 20px); // 340 -> taille de l'image // 20px espacement avec l'image

                @media screen and (max-width: 800px){
                    float: none;
                    width: 100%;
                }
            }
            figure{
                width: 340px;
                float: left;
                margin-top: 0;
                @media screen and (max-width: 800px){
                    float: none;
                    width: 100%;
                    margin-top: 20px;
                }
            }
            .contenu{
                float: right;
                width:calc(100% - 340px - 20px); // 340 -> taille de l'image // 20px espacement avec l'image
                @media screen and (max-width: 800px){
                    float: none;
                    width: 100%;
                }
            }


            &.no-pic{
                .entete, .contenu{
                    width: 100%;
                    float: none;
                }
            }
        }
    }
}


// Meteo
// ==========================================================================
.overlay-meteo{
    max-width: 100%;
    padding: 20px;
    overflow: auto;
    transform: translateY(-100%);
    transform: translate3d(0, -100%, 0);
    display: block;
    @include addTransition();
    .wrapper{
        height: auto;
        .entete{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items:center;
            font-size: 20px;
            line-height: 20px;
            padding:27px 30px;
            background-color: $c_grey;
            font-weight: 700;
            text-transform: uppercase;
            @include mobile{
                font-size: 16px;
                line-height: 16px;
            }
            .btn-close{
                position: relative;
                background-color: transparent;
                color: $c_sub;
                padding: 0;
                width:auto;
                height:auto;
                font-size: 20px;
                line-height: 1;
                margin-left: 20px;
                border:none;
                font-weight: 400;
                @include mobile{
                    font-size: 16px;
                }
                @include addTransition();
                body.site_vins & {
                    color: $c_vins2;
                }
                body.site_velo & {
                    color: $c_velo2;
                }
                body.site_noel & {
                    color: $c_noel2;
                }

                &:hover{
                    body:not(.no-hover) & {
                        color: $c_main;
                    }
                    body.site_vins:not(.no-hover) & {
                        color: $c_vins1;
                    }
                    body.site_velo:not(.no-hover) & {
                        color: $c_velo1;
                    }
                    body.site_noel:not(.no-hover) & {
                        color: $c_noel1;
                    }
                }
            }
        }
    }
    &.open{
        transform: translate3d(0, 0, 0);
    }


    .fake-table{
        margin-top: 25px;
        &__row{
            display: flex;
            align-items: center;
            margin: 0 50px;
            padding: 50px 0;
            @include tablet_portrait{
                flex-wrap: wrap;
            }
            @include mobile{
                margin: 0;
                padding: 25px 0;
            }

            &:not(.fake-table__head){
                border-top: 1px solid rgba(0,0,0,.2);
            }

            .title{
                width: 300px;
                flex-shrink: 0;
                @include ft($f_hx, 25px, 700, $c_txt, 25px);
                text-transform: uppercase;
                @include tablet_portrait{
                    width: 100%;
                    margin: 0 0 40px 0;
                    text-align: center;
                    &.empty{
                        display: none;
                    }
                }
                @include mobile{
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 25px;
                }
            }

            .column{
                width: 33%;
                text-align: center;

                [class*="icon-"],
                [class*="meteo-"],
                [class*="meteo-"]:after,
                [class*="meteo-"]:before{
                    color: $c_main;
                    font-size: 55px;
                    @include mobile{
                        font-size: 40px;
                    }
                    body.site_noel & {
                        color: $c_noel1;
                    }
                    body.site_vins & {
                        color: $c_vins1;
                    }
                    body.site_velo & {
                        color: $c_velo1;
                    }
                }
                .temp{
                    display: block;
                    margin-top: 20px;
                    @include ft($f_hx, 38px, 700, $c_txt, 38px);

                    @include mobile{
                        font-size: 25px;
                        line-height: 25px;
                        margin-top: 13px;
                    }
                }
                .wind{
                    display: block;
                    margin-top: 5px;
                    @include ft($f_txt, 16px, 400, $c_txt, 16px);

                    @include mobile{
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }

        &__head{
            background-color: white;
            position: sticky;
            top: -20px;
            z-index: 100;
            .column{
                @include ft($f_hx, 15px, 700, $c_txt, 15px);
                text-transform: uppercase;

                @include mobile{
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }

    }
}